import apiBase from './apiBase';

export type Message = {
  id: number;
  title: string;
  send_at: string;
  image_url: string;
  is_read: boolean;
  body: string;
};

type getMessagesRes = {
  data: Message[];
};

export const getMessages = () => apiBase.get<getMessagesRes>('/me/messages');

export const getMessage = (id: string) => apiBase.get<Message>(`/me/message/${id}`);
