import React, {FC, useContext, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import {Margin} from '../../common/Margin';
import {Link} from '../../common/Link';
import {getInvoices, InvoicesData} from '../../../lib/api/invoice';
import {AccountContext} from '../../../lib/contexts/account';

const WrapperTable = styled.div`
  font-size: 12px;
  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
    tr {
      th {
        padding: 0.5rem;
        background: #f1f1f1;
        font-weight: bold;
        text-align: left;
        border: 1px solid #979797;
      }
      td {
        padding: 0.5rem;
        border: 1px solid #979797;
        a {
          color: #4a90e2;
        }
      }
    }
  }
`;

type Props = {
  status: number;
};

export const SalesInvoiceList: FC<Props> = ({status}) => {
  const {account} = useContext(AccountContext);

  const [invoiceData, setInvoiceData] = useState<InvoicesData[]>([]);

  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);

  useEffect(() => {
    if (salonId) {
      getInvoices(salonId, status)
        .then(({data}) => {
          setInvoiceData(data.data);
        })
        .catch((e) => {
          alert('エラーが発生しました');
        });
    }
  }, [salonId, status]);

  return (
    <Margin margin="32px 0 8px 0">
      <WrapperTable>
        <table>
          <thead>
            <tr>
              <th>請求日</th>
              <th>名目</th>
            </tr>
          </thead>
          <tbody>
            {invoiceData.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.date}</td>
                  <td>
                    <Link to={`/sales/invoice/detail/${item.id}`}>{item.name}</Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </WrapperTable>
    </Margin>
  );
};
