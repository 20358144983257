import * as React from 'react';
import styled from 'styled-components';
import dayjs, {cellWidth} from '../../../../lib/utils/dayjs';
import {Color} from '../../../../style/variable';

type PropsType = {
  days: dayjs.Dayjs[];
  stepMinutes: number;
};

export const TimeLine: React.FC<PropsType> = ({days, stepMinutes}) => {
  const inHourCount = Number(60 / stepMinutes);
  const times = days
    .filter((_, v) => v % inHourCount === 0)
    .map((it) => (
      <TimeCellStyle key={`time-${it.format('HH:mm')}`} colspan={inHourCount}>
        {it.format('HH:mm')}
      </TimeCellStyle>
    ));

  return <div style={{display: 'flex'}}>{times}</div>;
};

const TimeCellStyle = styled.div<{colspan: number}>`
  border: 1px solid ${Color.GRAY_BORDER};
  border-left: none;
  border-bottom: none;
  min-width: calc(${cellWidth} * ${({colspan}) => colspan});
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${Color.FONT};

  &:last-child {
    border-right: 1px solid ${Color.GRAY_BORDER};
  }
`;
