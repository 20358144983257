import * as React from 'react';
import styled from 'styled-components';
import {ReservationType} from '../../../../lib/api/reservation';
import dayjs, {isBetween, headerHeight, cellWidth} from '../../../../lib/utils/dayjs';
import {Color} from '../../../../style/variable';

type PropsType = {
  days: dayjs.Dayjs[];
  reservations: ReservationType[];
};

const ReservationCountLine: React.FC<PropsType> = ({days, reservations}) => {
  return (
    <div style={{display: 'flex'}}>
      {days.map((it) => (
        <ReservationCountCell
          key={`reservation-count-${it.format('HH:mm')}`}
          reservationCount={reservations.filter((r) => isBetween(it, r.visit_salon_time, r.finish_time)).length}
        />
      ))}
    </div>
  );
};

type ReservationCountCellProps = {
  reservationCount: number;
};
const ReservationCountCell = (props: ReservationCountCellProps) => {
  const {reservationCount} = props;

  return <ReservationCountCellStyle>{reservationCount}</ReservationCountCellStyle>;
};

const ReservationCountCellStyle = styled.div`
  border: 1px solid ${Color.GRAY_BORDER};
  border-left: none;
  min-width: ${cellWidth};
  height: ${headerHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: ${Color.FONT};

  &:last-child {
    border-right: 1px solid ${Color.GRAY_BORDER};
  }
`;

export {ReservationCountLine};
