import {createContext} from 'react';
import {LoginParams, UpdateProfileParams, AccountType} from '../api/auth';

export const AccountContext = createContext<{
  account: AccountType | null;
  isMountAccount: boolean;
  login: (params: LoginParams) => Promise<void>;
  logout: () => void;
  mountAccount: () => Promise<void> | void;
  updateAccount: (params: UpdateProfileParams) => Promise<void>;
}>({
  account: null as any,
  isMountAccount: null as any,
  login: null as any,
  logout: null as any,
  mountAccount: null as any,
  updateAccount: null as any,
});
