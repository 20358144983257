import React, {useContext, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import MainContainer from '../../common/MainContainer';
import HeaderWithSearch from './internal/HeaderWithSearch';
import List from './internal/List';
import {ReactComponent as Plus} from '../../../image/plus_circle.svg';
import SearchList from './internal/SearchList';
import Footer from '../../common/Footer';
import {useCustomers} from '../../../lib/hooks/customer';
import {ShortCustomer} from '../../../lib/api/customer';
import {Spinner} from '../../common/Spinner';
import {AccountContext} from '../../../lib/contexts/account';
import useMediaQuery, {BreakPoint, WidthCriteria} from '../../../lib/hooks/useMedisQuery';
import {sortCharArray} from '../../../lib/utils/sort';

const HoverButton = styled.button<{isPC: boolean}>`
  border-radius: 36px;
  position: fixed;
  bottom: ${({isPC}) => (isPC ? 'calc(calc(100vw - 80vh) / 2 + 10vh)' : '68px')};
  right: ${({isPC}) => (isPC ? 'calc(calc(100vw - 560px) / 2 + 16px)' : '16px')};
  background: rgba(0, 0, 0, 0);
  border: none;
  height: 72px;
`;

const ShadowBox = styled.div<{isTyping: boolean}>`
  ${({isTyping}) =>
    isTyping &&
    `
    width: 100vw;
    background: rgba(0,0,0,0.12);
    position: absolute;`}
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export type CustomerTableDataType = {
  label: string;
  customers: {id: string; name: string}[];
  regex: RegExp;
};

const customerTableData = [
  {
    label: 'ア',
    regex: /[あ-おア-オ]/,
  },
  {
    label: 'カ',
    regex: /[か-こが-ごカ-コガ-ゴ]/,
  },
  {
    label: 'サ',
    regex: /[さ-そざ-ぞサ-ソザ-ゾ]/,
  },
  {
    label: 'タ',
    regex: /[た-とだ-どタ-トダ-ド]/,
  },
  {
    label: 'ナ',
    regex: /[な-のナ-ノ]/,
  },
  {
    label: 'ハ',
    regex: /[は-ほば-ぼぱ-ぽハ-ホバ-ボパ-ポ]/,
  },
  {
    label: 'マ',
    regex: /[ま-もマ-モ]/,
  },
  {
    label: 'ヤ',
    regex: /[や-よヤ-ヨ]/,
  },
  {
    label: 'ラ',
    regex: /[ら-ろラ-ロ]/,
  },
  {
    label: 'ワ',
    regex: /[わ-んワ-ン]/,
  },
  {
    label: '他',
    regex: /.*/,
  },
];

const toCustomerList = (customers: ShortCustomer[]): CustomerTableDataType[] => {
  const returnData = [...customerTableData].map((it) => ({...it, customers: []} as CustomerTableDataType));
  customers.forEach((customer) => {
    const name = `${customer.kana_first_name || ''} ${customer.kana_last_name}`;
    const target = customer.kana_first_name ? customer.kana_first_name.slice(0, 1) : '';
    returnData.some((it) => {
      if (target.match(it.regex)) {
        it.customers.push({id: `${customer.id}`, name});
        return true;
      }
      return false;
    });
  });
  return returnData;
};

const filteredCustomer = (customerList: ShortCustomer[], searchWord: string) => {
  return customerList
    .filter((it) =>
      `${it.kana_first_name} ${it.kana_last_name} ${it.kanji_first_name} ${it.kanji_last_name}`.includes(searchWord),
    )
    .map((it) => ({id: `${it.id}`, name: `${it.kanji_first_name || ''} ${it.kanji_last_name}`}));
};

export const Customer = () => {
  const history = useHistory();
  const [searchInput, setSearchInput] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const isPC = useMediaQuery(BreakPoint.mobile, WidthCriteria.min);
  const {account} = useContext(AccountContext);
  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);
  const customers = useCustomers(salonId);

  if (customers.length === 0) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  const customerList: CustomerTableDataType[] = toCustomerList(customers);

  const listElm =
    searchInput === '' ? (
      <ShadowBox isTyping={isTyping}>
        <List list={customerList} />
      </ShadowBox>
    ) : (
      <SearchList customers={filteredCustomer(customers, searchInput)} />
    );

  return (
    <div>
      <HeaderWithSearch
        searchInput={searchInput}
        setSearchInput={setSearchInput}
        isTyping={isTyping}
        setIsTyping={setIsTyping}
      />

      <MainContainer>{listElm}</MainContainer>
      <HoverButton onClick={() => history.push('/customer/add')} isPC={isPC}>
        <Plus />
      </HoverButton>
      <Footer isColored="CUSTOMER" />
    </div>
  );
};
