import React, {FC, useCallback} from 'react';
import styled from 'styled-components';

const MainWrapper = styled.div`
  width: 100%;
  position: relative;
  .input-text-custom {
    width: 100%;
    height: 2rem;
    border: none;
    border-bottom: 1px solid #e0e0e0;
  }
  .carret-down {
    position: absolute;
    right: 0.4rem;
    top: 1rem;
  }
`;

const Label = styled.div`
  font-size: 12px;
`;

export const InputTextCustom: FC<{
  value: string;
  label: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({value, label, onChange}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e);
    },
    [onChange],
  );

  return (
    <MainWrapper>
      <Label>{label}</Label>
      <input
        type="number"
        className="input-text-custom"
        value={value}
        placeholder="入力してください"
        onChange={handleChange}
      />
    </MainWrapper>
  );
};
