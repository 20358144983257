import React, {FC} from 'react';
import styled from 'styled-components';
import {CouponItemTag} from './CouponItemTag';
import {ReactComponent as YenIcon} from '../../../image/yen.svg';
import {ReactComponent as TimeIcon} from '../../../image/time.svg';
import {Color} from '../../../style/variable';
import {Coupon} from '../../../lib/api/coupon';

const MainWrapper = styled.div`
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
`;

const WrapperTags = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  .CouponItemTag {
    margin-right: 0.5rem;
  }
`;

const WrapperImg = styled.img`
  width: 80px;
  height: 110px;
  margin-right: 1rem;
  background: #c4c4c4;
  flex: none;
`;

const WrapperFlex = styled.div`
  display: flex;
`;

const WrapperRight = styled.div``;

const WrapperTitle = styled.div`
  font-size: 14px;
  margin-bottom: 0.5rem;
`;

const WrapperPriceTime = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 12px;
  color: ${Color.ORANGE};
  svg {
    width: 12px;
    height: 12px;
    margin-right: 0.5rem;
  }
`;

const WrapperDescription = styled.div`
  font-size: 12px;
  color: ${Color.GRAY_LIGHT};
`;

export const CouponItem: FC<{
  coupon: Coupon;
}> = ({coupon}) => {
  return (
    <MainWrapper>
      <WrapperTags>
        {coupon.menus.map((item) => {
          return <CouponItemTag key={item.id} label={item.name} />;
        })}
      </WrapperTags>

      <WrapperTitle>{coupon.name}</WrapperTitle>
      <WrapperFlex>
        <WrapperImg src={coupon.image_url} alt="クーポンサムネイル" height="85px" width="113px" />
        <WrapperRight>
          <WrapperPriceTime>
            <YenIcon />
            {coupon.price.toLocaleString()}
          </WrapperPriceTime>
          <WrapperPriceTime>
            <TimeIcon />
            {`${coupon.minute}分`}
          </WrapperPriceTime>
          <WrapperDescription>{coupon.description}</WrapperDescription>
        </WrapperRight>
      </WrapperFlex>
    </MainWrapper>
  );
};
