import React, {FC} from 'react';
import styled from 'styled-components';
import {Color} from '../../../style/variable';

const MainWrapper = styled.div`
  padding: 0.2rem;
  background: ${Color.ORANGE_LIGHT};
  border-radius: 4px;
  font-size: 10px;
  color: ${Color.ORANGE};
`;

export const CouponItemTag: FC<{
  label: string;
}> = ({label}) => {
  return <MainWrapper className="CouponItemTag">{label}</MainWrapper>;
};
