import styled from 'styled-components';
import {Color} from '../../../../style/variable';

const InventoryTableBase = styled.div`
  padding: 0 16px;
  font-size: 12px;

  table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }

  th {
    font-weight: normal;
    text-align: center;
    background-color: ${Color.GRAY_PALE};
  }

  td,
  th {
    border: 1px solid ${Color.GRAY_BORDER};
    padding: 4px 8px;
  }
`;

const ColoredTableRow = styled.tr`
  color: ${(props: {color: string}) => props.color};
`;

const TableInput = styled.input`
  width: 100%;
  padding: 4px;
  outline: none;
  border: none;
  background-color: #f6f6f6;
  text-align: right;
  border-radius: 4px;
`;

export {InventoryTableBase, ColoredTableRow, TableInput};
