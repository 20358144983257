import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{vertical: Props['vertical']; horizontal: Props['horizontal']}>`
  display: flex;
  flex: column;
  align-items: ${({vertical}) => (vertical ? 'center' : 'normal')};
  justify-content: ${({horizontal}) => (horizontal ? 'center' : 'flex-start')};
`;

type Props = {
  vertical?: boolean;
  horizontal?: boolean;
};

export const CenteringWrapper: React.FC<Props> = ({vertical, horizontal, children}) => (
  <Wrapper vertical={vertical} horizontal={horizontal}>
    {children}
  </Wrapper>
);
