import apiBase from './apiBase';

export type InvoiceRecord = {
  id: number;
  title: string;
  amount: number;
  unit_price: number;
  payment_amount: number;
};

export type Invoice = {
  id: string;
  name: string;
  status: number;
  status_label: string;
  apply_date: string;
  total_price: number;
  tax: number;
  payment_amount: number;
  data: InvoiceRecord[];
  remand_reason: string;
};

export type InvoicesData = {
  id: number;
  status: number;
  date: string;
  name: string;
};

type GetInvoicesRes = {
  status: string;
  status_label: string;
  data: InvoicesData[];
};

export const getInvoices = (salonId: number, status: number) =>
  apiBase.get<GetInvoicesRes>(`/salon/${salonId}/invoices?status=${status}`);

export const getInvoiceDetail = (salonId: number, invoiceId: string) =>
  apiBase.get<Invoice>(`/salon/${salonId}/invoice/${invoiceId}`);

export const putRemand = (salonId: number, invoiceId: string, reason: string) =>
  apiBase.put<Invoice>(`/salon/${salonId}/invoice/${invoiceId}/remand`, {remand_reason: reason});

export const putAccept = (salonId: number, invoiceId: string) =>
  apiBase.put<Invoice>(`/salon/${salonId}/invoice/${invoiceId}/accept`);
