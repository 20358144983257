import React from 'react';
import styled from 'styled-components';
import {Text} from '../Text';
import {Color, FontSize, FontWeight} from '../../../style/variable';

const BaseButton = styled('button')`
  width: 343px;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(249, 87, 56, 0.87);
  border-radius: 35px;
`;

type Props = {
  type: 'button' | 'submit' | 'reset' | undefined;
  handleClick: () => void;
};

export const AuthButton: React.FC<Props> = ({type, handleClick, children}) => (
  <BaseButton onClick={handleClick} type={type}>
    <Text color={Color.ORANGE} size={FontSize.MEDIUM} weight={FontWeight.BOLD}>
      {children}
    </Text>
  </BaseButton>
);
