import {useState, useEffect} from 'react';
import {
  getReservationOfSalon,
  ReservationType,
  getStylistOfSalon,
  GetStylistOfSalonType,
  SalonType,
  getReservationInfo,
  ReservationInfoType,
  SalonOfCategory,
  getReservationOfCategories,
  getReservationOfMenus,
  SalonOfMenu,
  SalonOfCoupon,
  getReservationOfCoupons,
} from '../api/reservation';

export const useStylistOfSalon = (salonId?: string | number) => {
  const [stylists, setStylists] = useState<GetStylistOfSalonType[]>([]);

  useEffect(() => {
    if (salonId === undefined) {
      setStylists([]);
    } else {
      getStylistOfSalon(`${salonId}`).then(({data}) => {
        setStylists(data.data);
      });
    }
  }, [salonId]);

  return stylists;
};

export const useReservationOfSalon = (date: string, salonId?: string | number) => {
  const [reservations, setReservations] = useState<ReservationType[]>([]);
  const [salon, setSalon] = useState<SalonType | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (salonId === undefined) {
      setReservations([]);
    } else {
      setLoading(true);
      getReservationOfSalon(`${salonId}`, date).then(({data}) => {
        setLoading(false);
        setReservations(data.data);
        setSalon(data.business);
      });
    }
  }, [salonId, date]);

  return {loading, reservations, salon};
};

export const useReservation = (reservationId: string) => {
  const [reservation, setReservation] = useState<ReservationInfoType | undefined>(undefined);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getReservationInfo(reservationId).then(({data}) => {
      setLoading(false);
      setReservation(data);
    });
  }, [reservationId]);

  return {loading, reservation};
};

export const useReservationOfCategories = (salonId?: number) => {
  const [categories, setCategories] = useState<SalonOfCategory[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (salonId === undefined) {
      setCategories([]);
    } else {
      setLoading(true);
      getReservationOfCategories(`${salonId}`).then(({data}) => {
        setLoading(false);
        setCategories(data);
      });
    }
  }, [salonId]);

  return {loading, categories};
};

export const useReservationOfMenus = (salonId?: number) => {
  const [menus, setMenus] = useState<SalonOfMenu[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (salonId === undefined) {
      setMenus([]);
    } else {
      setLoading(true);
      getReservationOfMenus(`${salonId}`).then(({data}) => {
        setLoading(false);
        setMenus(data.data);
      });
    }
  }, [salonId]);

  return {loading, menus};
};

export const useReservationOfCoupons = (salonId?: number) => {
  const [coupons, setCoupons] = useState<SalonOfCoupon[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (salonId === undefined) {
      setCoupons([]);
    } else {
      setLoading(true);
      getReservationOfCoupons(`${salonId}`).then(({data}) => {
        setLoading(false);
        setCoupons(data.data);
      });
    }
  }, [salonId]);

  return {loading, coupons};
};
