import * as React from 'react';
import styled from 'styled-components';
import {Color} from '../../../../style/variable';
import {TitleCell} from './Date';

const TimeLine = () => {
  return (
    <TimeLineWrapper>
      <TitleCell>日時</TitleCell>
      <TimeCell time="11:00" />
      <TimeCell time="11:30" />
      <TimeCell time="12:00" />
      <TimeCell time="12:30" />
      <TimeCell time="13:00" />
      <TimeCell time="13:30" />
      <TimeCell time="14:00" />
      <TimeCell time="14:30" />
      <TimeCell time="15:00" />
      <TimeCell time="15:30" />
      <TimeCell time="16:00" />
      <TimeCell time="16:30" />
      <TimeCell time="17:00" />
      <TimeCell time="17:30" />
      <TimeCell time="18:00" />
      <TimeCell time="18:30" />
    </TimeLineWrapper>
  );
};

const TimeLineWrapper = styled.div`
  min-width: 60px;
  width: 60px;
`;

type TimeCellProps = {
  time: string; // ここの型微妙。APIから渡されるdataの型をみて適切なものに変更すべき
};
const TimeCell = (props: TimeCellProps) => {
  const {time} = props;
  return <TimeCellWrapper>{time}</TimeCellWrapper>;
};

const TimeCellWrapper = styled.div`
  width: 100%;
  height: 36px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Color.GRAY_BORDER};
  border-right: none;
  border-bottom: none;

  &:last-child {
    border-bottom: 1px solid ${Color.GRAY_BORDER};
  }
`;

export {TimeLine};
