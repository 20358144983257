import * as React from 'react';
import styled from 'styled-components';
import {Color} from '../../../../style/variable';
import {Label} from './Label';

type Option = {
  key: string;
  value: any;
};

type SelectFieldProps = {
  label?: string;
  placeholder?: string;
  options: Option[];
  width?: string;
  name: string;
  register: any;
};
const SelectField = (props: SelectFieldProps) => {
  const {label, placeholder, options, width, name, register} = props;

  return (
    <div style={{width}}>
      {label ? (
        <div>
          <Label>{label}</Label>
        </div>
      ) : (
        <></>
      )}

      <div>
        <Select name={name} ref={register} placeholder={placeholder}>
          {options.map((option) => (
            <option key={`${option.key}-${option.value}`} value={option.value}>
              {option.key}
            </option>
          ))}
        </Select>
      </div>
    </div>
  );
};

const Select = styled.select`
  outline: none;
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-bottom: 1px solid ${Color.GRAY_BORDER};
  font-size: 16px;
  line-height: 20px;
  padding: 8px 0;
  width: 100%;
  background: #fff;
  -webkit-appearance: none;
  -moz-appearance: none;
`;

export {SelectField};
