import styled from 'styled-components';
import {Color} from '../../../../style/variable';

const Label = styled.label`
  color: ${Color.GRAY_DARK};
  font-size: 12px;
  line-height: 14px;
`;

export {Label};
