import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick'; // eslint-disable-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick.css'; // eslint-disable-line import/no-extraneous-dependencies
import 'slick-carousel/slick/slick-theme.css'; // eslint-disable-line import/no-extraneous-dependencies

const Wrapper = styled.div`
  margin-bottom: 24px;
`;
const ImageStyle = styled.img`
  max-width: 355px;
  height: 281px;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
`;
type Props = {
  images: string[];
};

export const Images: React.FC<Props> = ({images}) => {
  return (
    <Wrapper>
      <Slider dots>
        {images.map((it) => (
          <div key={it}>
            <ImageStyle src={it} />
          </div>
        ))}
      </Slider>
    </Wrapper>
  );
};
