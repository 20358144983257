import React from 'react';
import styled from 'styled-components';
import {Color} from '../../../style/variable';

const Wrapper = styled.hr`
  background-color: ${Color.GRAY};
  height: 1px;
  border: 0;
`;

export const Divider: React.FC = () => <Wrapper />;
