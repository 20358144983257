import * as React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {Color} from '../../../../style/variable';
import {TimeLine} from './TimeLine';
import {ReservationCountLine} from './ReservationCountLine';
import {GetStylistOfSalonType, ReservationType} from '../../../../lib/api/reservation';
import {ReservationTableBody, StylistTableType} from './ReservationTableBody';
import {cellHeight, headerHeight} from '../../../../lib/utils/dayjs';

type PropsType = {
  days: dayjs.Dayjs[];
  stylists: GetStylistOfSalonType[];
  reservations: ReservationType[];
};
export const DailyReservation: React.FC<PropsType> = ({days, stylists, reservations}) => {
  const bodyStylists: StylistTableType[] = stylists.map((stylist) => {
    return {
      id: stylist.id,
      name: stylist.user_name,
      schedules: reservations
        .filter((it) => it.stylist_id === stylist.id)
        .map((it) => ({
          id: it.id,
          customerName: it.user_name,
          startTime: it.visit_salon_time,
          endTime: it.finish_time,
        })),
    };
  });
  return (
    <div style={{display: 'flex'}}>
      <ShiftItem stylists={stylists} />
      <ShiftContentStyle>
        <TimeLine days={days} stepMinutes={30} />
        <ReservationCountLine days={days} reservations={reservations} />
        <ReservationTableBody days={days} stylists={bodyStylists} />
      </ShiftContentStyle>
    </div>
  );
};

type ShiftItemProps = {
  stylists: GetStylistOfSalonType[];
};
const ShiftItem: React.FC<ShiftItemProps> = ({stylists}) => {
  return (
    <ShiftItemStyle>
      <div className="cell vacant-cell" />
      <div className="cell reservation-cell">予約数</div>
      {stylists.map((it) => (
        <div key={it.id} className="cell stylist-cell">
          <p>{it.user_name}</p>
          <p className="stylist-receptionable_number">{`受付可能数：${it.receptionable_number}`}</p>
        </div>
      ))}
    </ShiftItemStyle>
  );
};

const ShiftContentStyle = styled.div`
  overflow-x: scroll;
`;

const ShiftItemStyle = styled.div`
  font-size: 12px;

  .cell {
    width: 106px;
    border: 1px solid ${Color.GRAY_BORDER};
    border-right: 2px solid ${Color.RED_BORDER};
    background-color: ${Color.BEIGE};
    border-bottom: none;
    padding-left: 16px;
    padding-top: 8px;
  }

  .vacant-cell {
    height: ${headerHeight};
  }

  .reservation-cell {
    height: ${headerHeight};
  }

  .stylist-cell {
    height: ${cellHeight};
  }

  .stylist-receptionable_number {
    font-size: 10px;
    color: rgba(0, 0, 0, 0.54);
    margin-top: 4px;
  }
`;

const StylistArea = styled.div``;
