import * as React from 'react';
import styled from 'styled-components';
import {Color} from '../../../../style/variable';
import {Label} from './Label';

type Option = {
  key: string;
  value: any;
};

type SelectFieldProps = {
  label?: string;
  name: string;
  register: any;
  width?: string;
};
const DateField = (props: SelectFieldProps) => {
  const {label, width, name, register} = props;

  return (
    <div style={{width}}>
      {label ? (
        <div>
          <Label>{label}</Label>
        </div>
      ) : (
        <></>
      )}

      <div>
        <Input type="date" name={name} ref={register} />
      </div>
    </div>
  );
};

const Input = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid ${Color.GRAY_BORDER};
  font-size: 16px;
  line-height: 20px;
  padding: 8px 0;
  width: 100%;
  position: relative;
  background: #fff;

  ::-webkit-clear-button {
    -webkit-appearance: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0%;
  }
`;

export {DateField};
