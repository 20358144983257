import React, {useContext, useEffect} from 'react';
import styled from 'styled-components';
import Routes from './Router';
import GlobalStyle from '../style/global-style';
import {AccountContext} from '../lib/contexts/account';
import {PCLayout} from './common/PCLayout';
import useMediaQuery, {BreakPoint, WidthCriteria} from '../lib/hooks/useMedisQuery';

const Container = styled.div<{isPC: boolean}>`
  max-width: 560px;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.15);
  overflow: scroll;

  ${({isPC}) =>
    isPC &&
    `
      max-height: 80vh;
      min-height: 80vh;
  `}
`;

const App: React.FC = () => {
  const {mountAccount} = useContext(AccountContext);
  const isPC = useMediaQuery(BreakPoint.mobile, WidthCriteria.min);

  useEffect(() => {
    mountAccount();
  }, []);

  return (
    <>
      <GlobalStyle />
      <PCLayout isPC={isPC}>
        <Container isPC={isPC}>
          <Routes />
        </Container>
      </PCLayout>
    </>
  );
};

export default App;
