import apiBase from './apiBase';

export type Shift = {
  id: number;
  stylist_id: number;
  salon_id: number;
  work_date: string;
  start_time: string;
  end_time: string;
  type: number;
  pattern: ShiftPattern | null;
  is_private: number;
  memo: string;
  holiday_message: string;
  holiday_contact_method: string;
  type_label: string;
};

export type ShiftType = {
  type: number;
  label: string;
};

export type ShiftPattern = {
  id: number;
  seq: number;
  pattern: string;
  start_hour: number;
  start_minute: number;
  end_hour: number;
  end_minute: number;
};

type GetShiftsOfMonthRes = {
  month: string;
  data: Array<{
    work_date: string;
    shifts: Shift[];
  }>;
};

type GetShiftsOfDayRes = {
  work_date: string;
  data: Shift[];
};

type GetShiftTypesGetRes = {
  data: ShiftType[];
};

type GetShiftPatternsGetRes = {
  data: ShiftPattern[];
};

type PostAddShiftReq = {
  work_date: string;
  start_time: string;
  end_time: string;
  type: number;
  is_private: boolean;
  memo: string;
  pattern?: string;
  holiday_message?: string;
  holiday_contact_method?: string;
};

type PostAddShiftRes = {
  salon_id: number;
  stylist_id: number;
  work_date: string;
  start_time: string;
  end_time: string;
  type: number;
  pattern: ShiftPattern | null;
  is_private: boolean;
  memo: string;
  holiday_message: string;
  holiday_contact_method: string;
  id: number;
};

export const getShiftsOfMonth = (salonId: number, month: string) =>
  apiBase.get<GetShiftsOfMonthRes>(`/salon/${salonId}/shifts/month?month=${month}`);

export const getShiftsOfDay = (salonId: number, date: string) =>
  apiBase.get<GetShiftsOfDayRes>(`/salon/${salonId}/shifts?work_date=${date}`);

export const getShiftTypes = () => apiBase.get<GetShiftTypesGetRes>('/shift/types');

export const getShiftPatterns = (salonId: number) =>
  apiBase.get<GetShiftPatternsGetRes>(`/salon/${salonId}/shift/patterns`);

export const postAddShift = (salonId: number, params: PostAddShiftReq) =>
  apiBase.post<PostAddShiftRes>(`/salon/${salonId}/shift`, params);
