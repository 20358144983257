import React, {useState} from 'react';
import styled from 'styled-components';
import Header from '../../../common/Header';
import {ReactComponent as ArrowLeft} from '../../../../image/arrow_left.svg';
import MainContainer from '../../../common/MainContainer';
import {FontSize} from '../../../../style/variable';
import MaterialSearch from './MaterialSearch';
import {CheckBox} from '../../CheckBox';
import {ViewStyle} from '../../../pages/AddMedicalRecord/AddMedicalRecord';
import {MaterialType} from '../../../../lib/api/karte';
import {useMaterialCategories, useMaterials} from '../../../../lib/hooks/kartes';

const RightContentItem = styled.button`
  background: #fff;
  border: none;
  color: rgba(249, 87, 56, 0.87);
  font-size: ${FontSize.CONTROL_MEDIUM};
`;

const MaterialWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const MaterialContent = styled.div`
  display: flex;
  padding: 16px 0px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
`;

const MaterialLabel = styled.div`
  font-size: ${FontSize.SMALL};
  line-height: 17px;
  color: #000000;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0px;
  z-index: 100;
  min-height: 78px;
  width: 100%;
  background-color: white;
`;

const FooterLabel = styled.div`
  height: 78px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(249, 51, 70, 0.87);
  border-top: 1px solid #c4c4c4;
  font-weight: bold;
`;

const SelectedTitle = styled.div`
  border-top: 1px solid #c4c4c4;
  font-size: ${FontSize.X_SMALL};
  border-top: 1px solid #c4c4c4;
  padding: 16px 16px 0px 16px;
`;

const SelectedItems = styled.div`
  font-size: ${FontSize.X_SMALL};
  margin: 24px 16px;
`;

type Props = {
  setViewType: React.Dispatch<React.SetStateAction<ViewStyle>>;
  setMaterials: (material: MaterialType[]) => void;
  materials: MaterialType[];
};

const MaterialForm: React.FC<Props> = ({setViewType, setMaterials, materials}) => {
  const [selectedMaterials, setSelectedMaterials] = useState<MaterialType[]>(materials || []);
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);
  const allMaterials = useMaterials();
  const categories = useMaterialCategories();

  const RightContent: React.FC = () => (
    <RightContentItem onClick={() => setSelectedMaterials([])}>クリア</RightContentItem>
  );
  const LeftContent: React.FC = () => <ArrowLeft onClick={() => setViewType('Form')} />;

  const onClickCheckBox = (material: MaterialType) => () => {
    if (selectedMaterials.some((it) => it.id === material.id)) {
      setSelectedMaterials(selectedMaterials.filter((v) => v.id !== material.id));
    } else {
      setSelectedMaterials([...selectedMaterials, material]);
    }
  };
  const filteredItems = allMaterials.filter(
    (it) => selectedCategoryIds.length === 0 || selectedCategoryIds.includes(it.material_category_id),
  );

  const handleSubmit = () => {
    setMaterials(selectedMaterials);
    setViewType('Form');
  };

  return (
    <div>
      <Header title="使用材料" RightContent={RightContent} LeftContent={LeftContent} />
      <MainContainer>
        <MaterialWrapper>
          <MaterialSearch categories={categories} setSelectedCategoryIds={setSelectedCategoryIds} />
          {filteredItems.map((it) => (
            <MaterialContent key={it.id} onClick={onClickCheckBox(it)}>
              <MaterialLabel>{it.name}</MaterialLabel>
              <CheckBox checked={selectedMaterials.some((material) => material.id === it.id)} />
            </MaterialContent>
          ))}
        </MaterialWrapper>
      </MainContainer>
      <Footer>
        {selectedMaterials.length > 0 && (
          <>
            <SelectedTitle>選択済み</SelectedTitle>
            <SelectedItems>
              {selectedMaterials
                .map((material) => allMaterials.filter((it) => it.id === material.id).map((it) => it.name))
                .join('、')}
            </SelectedItems>
          </>
        )}
        <FooterLabel onClick={handleSubmit}>決定</FooterLabel>
      </Footer>
    </div>
  );
};

export default MaterialForm;
