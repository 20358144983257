import {useState, useEffect} from 'react';
import {
  CustomerType,
  getCustomer,
  getCustomers,
  getIntroductions,
  IntroductionType,
  ShortCustomer,
} from '../api/customer';

export const useCustomers = (salonId?: number) => {
  const [customers, setCustomers] = useState<ShortCustomer[]>([]);

  useEffect(() => {
    if (salonId !== undefined) {
      getCustomers(`${salonId}`).then(({data}) => {
        setCustomers(data.data);
      });
    }
  }, []);

  return customers;
};

export const useCustomer = (salonId?: string, userId?: string) => {
  const [customer, setCustomer] = useState<CustomerType | null>(null);

  useEffect(() => {
    if (salonId === undefined || userId === undefined) {
      setCustomer(null);
    } else {
      getCustomer(salonId, userId)
        .then(({data}) => {
          setCustomer(data);
        })
        .catch((err) => {
          setCustomer(null);
        });
    }
  }, []);

  return customer;
};

export const useIntroductions = (salonId?: string, userId?: string) => {
  const [introduction, setIntroduction] = useState<IntroductionType | null>(null);

  useEffect(() => {
    if (salonId === undefined || userId === undefined) {
      setIntroduction(null);
    } else {
      getIntroductions(salonId, userId)
        .then(({data}) => {
          setIntroduction(data);
        })
        .catch((err) => {
          setIntroduction(null);
        });
    }
  }, []);

  return introduction;
};
