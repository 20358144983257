import React from 'react';
import styled from 'styled-components';
import {encodeBase64} from '../../../../lib/base64';
import {DeletableImage} from '../../DeletableImage';
import {AddImage} from '../../AddImage';
import {ImageType} from '../../../../lib/api/karte';

const ImageArea = styled.div`
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  & > *:not(:last-child) {
    margin-right: 16px;
  }
  overflow-x: scroll;
  width: 100%;
`;
type Props = {
  images: ImageType[];
  setImages: (images: ImageType[]) => void;
};

export const AddImages: React.FC<Props> = ({images, setImages}) => {
  const addImage = (file: File) => {
    encodeBase64(file).then((encoded) => {
      setImages([...images, {base64: encoded}]);
    });
  };

  const deleteImage = (index: number) => () => {
    setImages(images.filter((_, i) => i !== index));
  };

  // eslint-disable-next-line react/no-array-index-key
  const imagesElm = images.map((it, i) => (
    <DeletableImage key={it.id} src={it.url || it.base64} onDelete={deleteImage(i)} />
  ));

  return (
    <ImageArea>
      <AddImage onChange={addImage} />
      {imagesElm}
    </ImageArea>
  );
};
