import React, {FC, useCallback} from 'react';
import styled from 'styled-components';

const MainWrapper = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .input-text-custom {
    width: 90%;
    height: 2.5rem;
    border: none;
  }
`;

const Label = styled.div`
  margin-bottom: 0.5rem;
  font-size: 12px;
`;

const Unit = styled.div`
  position: absolute;
  right: 1rem;
  top: 2.1rem;
`;

export const InputTextCustom: FC<{
  label: string;
  value: string | number | undefined;
  placeholder: string;
  onChange: (value: string) => void;
  unit?: string;
  type?: string;
}> = ({label, value, placeholder, onChange, unit, type}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );

  return (
    <MainWrapper>
      <Label>{label}</Label>
      <input
        type={type || 'text'}
        className="input-text-custom"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
      />
      {unit && <Unit>{unit}</Unit>}
    </MainWrapper>
  );
};
