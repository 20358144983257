import React from 'react';
import styled from 'styled-components';
import useForm from 'react-hook-form';
import {useHistory, useLocation} from 'react-router';
import {Input, Layout, Title} from './internal';
import {passwordReset, PasswordResetParams} from '../../../lib/api/auth';

const Text = styled.p`
  width: 295px;
  font-size: 14px;
  line-height: 17px;
  color: #242424;
  margin-bottom: 40px;
`;

export const PasswordResetting: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const {register, handleSubmit} = useForm();

  const onSubmit = handleSubmit((value, e) => {
    e.preventDefault();
    const {search} = location;
    const query = new URLSearchParams(search);

    const resetParams: PasswordResetParams = {
      token: '',
      email: '',
      password: value.password,
      password_confirmation: value.password_confirmation,
    };

    const token = query.get('token');
    const email = query.get('email');

    // @todo 本来は「不正なURLです的な画面を出してもよさそう」
    if (token === null) {
      // console.error("tokenがありません");
      history.push('/login');
    } else {
      resetParams.token = token;
    }

    if (email === null) {
      // console.error("emailがありません");
      history.push('/login');
    } else {
      resetParams.email = email;
    }

    passwordReset(resetParams)
      .then(() => {
        history.push('/pasword_setting_completed');
      })
      .catch(() => {
        // console.error("パスワードリセット送信失敗");
        alert('パスワードリセットに失敗しました');
      });
  });

  return (
    <Layout>
      <Title>パスワード設定</Title>
      <form onSubmit={onSubmit}>
        <Input text="新しいパスワード" register={register} name="password" placeholder="" type="password" />
        <Input
          text="新しいパスワード（確認用）"
          register={register}
          name="password_confirmation"
          placeholder=""
          type="password"
        />
        <button type="submit">変更内容を保存</button>
      </form>
    </Layout>
  );
};
