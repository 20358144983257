import React from 'react';
import {RouteComponentProps, useHistory} from 'react-router-dom';
import styled, {css} from 'styled-components';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {BackButton} from '../../parts/BackButton';
import {Space, FontSize} from '../../../style/variable';
import {Images} from './internal/Images';
import {useKarte} from '../../../lib/hooks/kartes';
import {Spinner} from '../../common/Spinner';

const Wrapper = styled.div`
  padding: 16px 0px;
`;
const RightContentItem = styled.button`
  background: #fff;
  border: none;
  color: rgba(249, 87, 56, 0.87);
  font-size: ${FontSize.CONTROL_MEDIUM};
`;
const Label = styled.p`
  font-size: ${FontSize.MEDIUM};
`;
const Text = css`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 16px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;
const TextArea = css`
  display: flex;
  align-items: baseline;
  padding: 16px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  flex-flow: column;
`;
const DateArea = styled.div`
  ${Text}
`;
const MaterialsArea = styled.div`
  ${Text}
  & p:first-child {
    min-width: 40%;
  }
`;
const RecommendItemsArea = styled.div`
  ${Text}
  & p:first-child {
    min-width: 40%;
  }
`;
const ExplanationArea = styled.div`
  ${TextArea}
`;

const ReferenceImagesArea = styled.div`
  padding: 16px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  & p:first-child {
    margin-bottom: 16px;
  }
`;

const MemoArea = styled.div`
  ${TextArea}
`;

const TextItem = styled.p`
  color: rgba(0, 0, 0, 0.56);
`;

const TextAreaItem = styled.p`
  font-size: ${FontSize.X_SMALL};
  color: #242424;
  margin-top: 8px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

type Props = RouteComponentProps<{id: string}>;
export const MedicalRecordDetail: React.FC<Props> = ({match}) => {
  const karteId = match.params.id;
  const history = useHistory();
  const karte = useKarte(karteId);

  if (karte === null) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  const LeftContent: React.FC = () => <BackButton />;
  const RightContent: React.FC = () => (
    <RightContentItem onClick={() => history.push(`/customer/medical_record/edit/${karteId}`)}>編集</RightContentItem>
  );

  return (
    <Wrapper>
      <Header title={karte.date} LeftContent={LeftContent} RightContent={RightContent} />
      <MainContainer marginHorizontal={Space * 2}>
        <Images images={karte.images.map((it) => it.url || it.base64)} />
        <DateArea>
          <Label>日付</Label>
          <TextItem>{karte.date}</TextItem>
        </DateArea>
        <MaterialsArea>
          <Label>使用材料</Label>
          <TextItem>{karte.materials.map((it) => it.data!.name).join('、')}</TextItem>
        </MaterialsArea>
        <RecommendItemsArea>
          <Label>オススメ商品</Label>
          <TextItem>{karte.recommend ? karte.recommend.name : ''}</TextItem>
        </RecommendItemsArea>
        <ExplanationArea>
          <Label>商品説明</Label>
          <TextAreaItem>{karte.recommend_product_description}</TextAreaItem>
        </ExplanationArea>
        <ReferenceImagesArea>
          <Label>参考画像</Label>
          <Images images={karte.ref_images.map((it) => it.url || it.base64)} />
        </ReferenceImagesArea>
        <MemoArea>
          <Label>全体のメモ</Label>
          <TextAreaItem>{karte.memo}</TextAreaItem>
        </MemoArea>
      </MainContainer>
      <Footer isColored="MY_PAGE" />
    </Wrapper>
  );
};
