import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Cross} from '../../../image/cross_mark.svg';

const DeleteImgButton = styled.div`
  background: rgba(0, 0, 0, 0);
  border: none;
  position: absolute;
  top: -8px;
  right: -8px;
  height: 16px;
`;

const Img = styled.img`
  height: 58px;
  width: 71px;
  border-radius: 4px;
`;

const ImgArea = styled.div`
  height: 58px;
  width: 71px;
  border-radius: 4px;
  border: none;
  position: relative;
`;

type Props = {
  src: string;
  onDelete: () => void;
};

export const DeletableImage: React.FC<Props> = ({src, onDelete}) => {
  return (
    <ImgArea>
      <DeleteImgButton onClick={onDelete}>
        <Cross />
      </DeleteImgButton>
      <Img src={src} />
    </ImgArea>
  );
};
