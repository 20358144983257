import React from 'react';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router';
import Header from '../../common/Header';
import MainContainer from '../../common/MainContainer';
import {BackButton} from '../../parts/BackButton';
import {Margin} from '../../common/Margin';
import {postBlog, PostBlogParams, PreviewBlogParams} from '../../../lib/api/blog';

const Title = styled.p`
  font-size: 16px;
  line-height: 29px;
  color: #212121;
  margin-bottom: 8px;
`;

const SmallText = styled.p`
  font-size: 12px;
  color: #696969;
  line-height: 22px;
  margin-bottom: 4px;
`;

const MainText = styled.p`
  color: #212121;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 24px;
  width: 100%;
  white-space: pre-line;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img`
  width: 343px;
  height: 257px;
  margin-bottom: 24px;
  object-fit: cover;
`;

const ProductWrapper = styled.div`
  height: 78px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 48px;
  display: flex;
  align-items: center;
`;

const ProductText = styled.div`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #212121;
`;

const Price = styled.div`
  text-align: right;
  margin-top: 8px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #212121;
`;

const ProductImg = styled.img`
  background: rgba(249, 87, 56, 0.12);
  width: 76px;
  height: 76px;
  object-fit: cover;
`;

const ProductContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const PostButton = styled.button`
  width: 100%;
  background: rgba(249, 87, 56, 0.87);
  border-radius: 4px;
  height: 52px;
  color: #fff;
  margin-top: 48px;
  border: none;
`;

export const BlogPreview = () => {
  const LeftContent: React.FC = () => <BackButton path="/blog/create" />;
  const history = useHistory();
  const location = useLocation();
  const blog = location.state as PreviewBlogParams;

  console.log(blog);

  const onSubmit = () => {
    const postBlogParams: PostBlogParams = {
      title: blog.title,
      product_id: blog.product ? blog.product.id : undefined,
      descriptions: blog.descriptions
        ? blog.descriptions.map((item) => ({
            base64: item.image,
            body: item.body,
          }))
        : undefined,
    };

    postBlog(postBlogParams).then(() => {
      history.push('/blog');
    });
  };

  return (
    <div>
      <Header title="プレビュー" LeftContent={LeftContent} />
      <MainContainer>
        {blog ? (
          <>
            <Margin margin="24px 16px 48px">
              <Title>{blog.title}</Title>
              <Margin margin="0 0 20px 0">
                <SmallText>
                  投稿者：
                  {blog.author}
                </SmallText>
                <SmallText>{blog.created_at}</SmallText>
              </Margin>

              {blog.descriptions
                ? blog.descriptions.map((description: any) => (
                  <DescriptionWrapper key={description.body}>
                    <MainText>{description.body}</MainText>
                    {description.image ? <Img src={description.image} /> : null}
                  </DescriptionWrapper>
                  ))
                : null}

              {blog.product ? (
                <ProductWrapper>
                  {blog.product ? <ProductImg src={blog.product.image_url} /> : null}
                  <ProductContent>
                    <ProductText>{blog.product.name}</ProductText>
                    <Price>
                      {blog.product.sticker_price}
                      円
                    </Price>
                  </ProductContent>
                </ProductWrapper>
              ) : null}

              <PostButton onClick={onSubmit}>投稿</PostButton>
            </Margin>
          </>
        ) : null}
      </MainContainer>
    </div>
  );
};
