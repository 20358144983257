import React from 'react';
import styled from 'styled-components';

type Variant = 'outlined' | 'contained';

const Icon = styled.svg`
  fill: none;
  stroke-width: 2px;
`;
const StyledCheckbox = styled.div<{checked: boolean; variant: Variant}>`
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${({variant, checked}) => (checked && variant === 'contained' ? '#FA6F54' : '#ffffff')};
  border-radius: 3px;
  transition: all 150ms;
  border: ${({variant, checked}) => (!checked || variant === 'outlined' ? '1px solid #757575' : 'none')};
  box-sizing: border-box;
  border-radius: 2px;
  ${Icon} {
    visibility: ${({checked}) => (checked ? 'visible' : 'hidden')};
    stroke: ${({variant}) => (variant === 'outlined' ? '#fa6f54' : '#ffffff')};
  }
`;
const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
`;

type Props = {
  checked: boolean;
  variant?: Variant;
  onClick?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
};
export const CheckBox: React.FC<Props> = ({checked, variant = 'contained', onClick}) => {
  return (
    <CheckboxContainer onClick={onClick}>
      <StyledCheckbox checked={checked} onChange={() => {}} variant={variant}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
};
