import React, {useMemo, useState, useContext, useEffect} from 'react';
import styled from 'styled-components';
import {RouteComponentProps, useHistory} from 'react-router';
import useForm from 'react-hook-form';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import {FontSize} from '../../../style/variable';
import {CustomerForm} from '../../parts/Form/CustomerForm';
import {updateCustomer, UpdateCustomerType} from '../../../lib/api/customer';

import {AccountContext} from '../../../lib/contexts/account';
import {useCustomer} from '../../../lib/hooks/customer';
import {Spinner} from '../../common/Spinner';

const RightContentItem = styled.button`
  background: #fff;
  border: none;
  color: rgba(249, 87, 56, 0.87);
  font-size: ${FontSize.CONTROL_MEDIUM};
`;

const LeftContentItem = styled.p`
  color: #3c80f5;
`;
type Props = RouteComponentProps<{id: string}>;
export const CustomerUpdate: React.FC<Props> = ({match}) => {
  const history = useHistory();
  const userId = match.params.id;
  const {account} = useContext(AccountContext);
  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);

  const customer = useCustomer(`${salonId}`, userId);
  const data: UpdateCustomerType | undefined = customer ? {...customer} : undefined;
  const [birthday, setBirthday] = useState(data ? data.birth_day : '');
  const {register, handleSubmit, reset} = useForm<UpdateCustomerType>({
    defaultValues:
      data ||
      ({
        kanji_first_name: '',
        kanji_last_name: '',
        kana_first_name: '',
        kana_last_name: '',
        phone_number: '',
        birth_day: '',
        memo: '',
      } as UpdateCustomerType),
  });

  useEffect(() => {
    if (data) {
      reset(data);
      setBirthday(data.birth_day);
    }
  }, [customer]);

  if (customer === null) {
    return <Spinner />;
  }

  const onSubmit = handleSubmit((value) => {
    const NextValue = {...value, birth_day: birthday};
    updateCustomer(`${salonId}`, userId, NextValue).then(() => {
      history.push(`/customer/detail/${userId}`);
    });
  });

  const RightContent: React.FC = () => <RightContentItem type="submit">完了</RightContentItem>;

  const LeftContent: React.FC = () => <LeftContentItem onClick={() => history.goBack()}>キャンセル</LeftContentItem>;

  return (
    <form onSubmit={onSubmit}>
      <Header title="編集" RightContent={RightContent} LeftContent={LeftContent} />
      <MainContainer>
        <CustomerForm register={register} birthday={birthday} setBirthday={setBirthday} />
      </MainContainer>
    </form>
  );
};
