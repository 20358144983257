import React, {useContext} from 'react';
import styled from 'styled-components';
import * as H from 'history';
import useForm from 'react-hook-form';
import Header from '../../common/Header';
import MainContainer from '../../common/MainContainer';
import {Padding} from '../../common/Padding';
import {FontSize, Color} from '../../../style/variable';
import {ReactComponent as Camera} from '../../../image/camera.svg';
import {ReactComponent as Person} from '../../../image/person.svg';
import {encodeBase64} from '../../../lib/base64';
import {AccountContext} from '../../../lib/contexts/account';
import {ValidationErrorMessage} from '../../common/ValidationErrorMessage';

const RightContentItem = styled.button`
  background: #fff;
  border: none;
  color: rgba(249, 87, 56, 0.87);
  font-size: ${FontSize.CONTROL_MEDIUM};
`;

const LeftContentItem = styled.p`
  color: rgba(249, 87, 56, 0.87);
`;

const ImgFieldWrapper = styled.div`
  padding-top: 32px;
  width: 120px;
  height: 152px;
  margin: 0 auto 32px;
  position: relative;
`;

const Img = styled.img`
  height: 120px;
  width: 120px;
  object-fit: cover;
  border-radius: 60px;
  border: 1px solid rgba(0, 0, 0, 0.27);
`;

const NoSetImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  width: 120px;
  background: rgba(0, 0, 0, 0.27);
  border-radius: 60px;
  border: 1px solid rgba(0, 0, 0, 0.27);
`;

const ImgFieldOverlay = styled.label`
  position: absolute;
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 32px;
  left: 0;
  background: rgba(0, 0, 0, 0.27);
  border-radius: 60px;
`;

const Label = styled.p`
  font-size: ${FontSize.X_SMALL};
  color: ${Color.BLACK};
  width: 100%;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 8px;
`;

const TextFieldArea = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`;

const TextField = styled.input`
  font-size: ${FontSize.MEDIUM};
  width: 100%;
  margin: 0 auto;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #c4c4c4;
  padding: 8px 0;
`;

const SelectBoxFieldArea = styled.div`
  width: 100%;
  margin: 0 auto 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SelectBoxFieldWrapper = styled.div`
  width: 50px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;

const SelectBoxField = styled.select`
  width: 100%;
  font-size: ${FontSize.MEDIUM};
  border: none;
  background: #fff;
  font-size: ${FontSize.SMALL};
  line-height: 17px;
`;

const TextAreaWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const TextAreaField = styled.textarea`
  font-size: ${FontSize.MEDIUM};
  width: 100%;
  height: 95px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0 auto;
`;

const Triangle = styled.span`
  font-size: 8px;
  margin-left: 8px;
  position: absolute;
  right: 0;
  pointer-events: none;
`;

type Props = {
  history: H.History;
};

type FormValues = {
  image_url: string;
  base64: string;
  years_of_experience: string;
  sex: string;
  naming_price: string;
  grade: string;
  catch_phrase: string;
  self_introduction: string;
};

export const MypageEdit: React.FC<Props> = (props) => {
  const {history} = props;
  const {account, updateAccount} = useContext(AccountContext);

  const {register, setValue, handleSubmit, watch, errors} = useForm<FormValues>({
    defaultValues: {
      image_url: account ? account.image_url : '',
      base64: '',
      years_of_experience: account ? account.years_of_experience : '',
      sex: account ? account.sex : '',
      naming_price: account ? account.naming_price : '',
      grade: account ? account.grade : '',
      catch_phrase: account ? account.catch_phrase : '',
      self_introduction: account ? account.self_introduction : '',
    },
  });

  const options: JSX.Element[] = [<option key="none" value="" label="--" />];
  for (let i = 1; i < 21; i += 1) {
    options.push(
      <option key={i} value={i}>
        {`${i.toString()}年`}
      </option>,
    );
  }

  const onChangeImage = (file: any) => {
    encodeBase64(file).then((encoded) => {
      setValue('base64', encoded);
    });
  };

  const onSubmit = handleSubmit((value) => {
    const {base64, image_url, ...other} = value;
    const params = {
      ...other,
      image: {
        base64,
      },
      image_url,
    };

    updateAccount(params).then(() => {
      history.push('/');
    });
  });

  const RightContent: React.FC = () => <RightContentItem onClick={onSubmit}>保存</RightContentItem>;
  const LeftContent: React.FC = () => <LeftContentItem onClick={() => history.push('/')}>キャンセル</LeftContentItem>;
  console.log(watch('image_url'));
  console.log(watch('base64'));
  return (
    <form>
      <Header title="" RightContent={RightContent} LeftContent={LeftContent} />
      <MainContainer>
        <Padding padding="0 16px">
          <ImgFieldWrapper>
            <input
              id="img"
              type="file"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChangeImage(e.target.files![0])}
              style={{display: 'none'}}
              capture="img"
            />
            <input
              name="base64"
              ref={register({
                required: true,
              })}
              style={{display: 'none'}}
            />

            {watch('image_url') && <Img src={watch('base64') as string} alt="" />}
            <ImgFieldOverlay htmlFor="img">
              <Camera />
            </ImgFieldOverlay>
          </ImgFieldWrapper>

          <Label>歴</Label>
          <SelectBoxFieldArea>
            <SelectBoxFieldWrapper>
              <SelectBoxField
                ref={register({
                  required: true,
                })}
                name="years_of_experience"
                id="experience_selectbox"
              >
                {options}
              </SelectBoxField>
              <Triangle>▼</Triangle>
            </SelectBoxFieldWrapper>
          </SelectBoxFieldArea>

          <Label>性別</Label>
          <SelectBoxFieldArea>
            <SelectBoxFieldWrapper>
              <SelectBoxField
                ref={register({
                  required: true,
                })}
                name="sex"
              >
                <option value="" label="--" />
                <option value="" label="選択無し">
                  選択なし
                </option>
                <option value="男" label="男性">
                  男性
                </option>
                <option value="女" label="女性">
                  女性
                </option>
              </SelectBoxField>
              <Triangle>▼</Triangle>
            </SelectBoxFieldWrapper>
          </SelectBoxFieldArea>

          <Label>指名料</Label>
          <TextFieldArea>
            <TextField
              name="naming_price"
              ref={register({
                required: true,
              })}
              placeholder="入力してください"
            />
          </TextFieldArea>

          <Label>ランク</Label>
          <TextFieldArea>
            <TextField
              name="grade"
              ref={register({
                required: true,
              })}
              placeholder="入力してください"
            />
          </TextFieldArea>

          <Label>キャッチフレーズ</Label>
          <TextFieldArea>
            <TextField
              name="catch_phrase"
              ref={register({
                required: true,
              })}
              placeholder="入力してください"
            />
          </TextFieldArea>

          <Label>自己紹介</Label>
          <TextAreaWrapper>
            <TextAreaField
              name="self_introduction"
              ref={register({
                required: true,
              })}
              placeholder="入力してください"
            />
          </TextAreaWrapper>
          {Object.keys(errors).length > 0 && <ValidationErrorMessage message="未入力の項目があります" />}
        </Padding>
      </MainContainer>
    </form>
  );
};
