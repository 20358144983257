// TODO setoken ルール検討して
/* eslint consistent-return: "off" */
export const encodeBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    if (!file) {
      return resolve(undefined);
    }

    const fr = new FileReader();
    fr.onload = (ev) => {
      if (ev.target !== null) {
        resolve((ev.target as any).result as string);
      }
    };
    fr.onerror = (e) => reject(e);

    fr.readAsDataURL(file);
  });
