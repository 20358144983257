import apiBase from './apiBase';

export type Sales = {
  skill_sales: number;
  product_sales: number;
  ec_sales: number;
};

type GetSalesOfMonthRes = {
  month: string;
} & Sales;

type GetSalesOfYearRes = {
  year: string;
} & Sales;

export type GoalData = {
  month: number;
  target: number;
};

type GetGoalsOfYearRes = {
  year: string;
  data: GoalData[];
};

type GetPutGoalReq = {
  year: number;
  month: number;
  amount: number;
};

type GetPutGoalRes = {
  year: number;
  month: number;
  amount: number;
};

export const getSalesOfMonth = (salonId: number, year: number, month: number) =>
  apiBase.get<GetSalesOfMonthRes>(`/salon/${salonId}/sales?year=${year}&month=${month}`);

export const getSalesOfYear = (salonId: number, year: number) =>
  apiBase.get<GetSalesOfYearRes>(`/salon/${salonId}/sales?year=${year}`);

export const getGoalsOfYear = (salonId: number, year: number) =>
  apiBase.get<GetGoalsOfYearRes>(`/salon/${salonId}/targets?year=${year}`);

export const putGoal = (salonId: number, params: GetPutGoalReq) =>
  apiBase.put<GetPutGoalRes>(`/salon/${salonId}/target`, params);
