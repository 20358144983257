import apiBase from './apiBase';

export type StylistType = {
  id: number;
  user_name: string;
  user_kana_name: string;
  receptionable_number: number;
};

export type GetStylistOfSalonType = Pick<StylistType, 'id' | 'user_kana_name' | 'user_name' | 'receptionable_number'>;

export const getStylistOfSalon = (salonId: string) =>
  apiBase.get<{data: GetStylistOfSalonType[]}>(`/salon/${salonId}/stylists`);

export type ReservationType = {
  id: number;
  user_name: string;
  visit_salon_time: string;
  finish_time: string;
  stylist_id: number;
};

export type SalonType = {
  start_time: string;
  end_time: string;
};

type GetReservationOfSalonType = {
  date: string;
  business: SalonType | undefined;
  data: ReservationType[];
};

export const getReservationOfSalon = (salonId: string, date: string) =>
  apiBase.get<GetReservationOfSalonType>(`/salon/${salonId}/reservations?date=${date}`);

export type ReservationInfoType = {
  id: number;
  visit_salon_time: string;
  finish_time: string;
  price: number;
  use_point: number;
  payment_amount: number;
  reward_point: number;
  request: string;
  memo: string;
  status_label: string;
  menu: {
    name: string;
    price: number;
  };
  coupon: {
    name: string;
    price: number;
  };
  user: {
    id: number;
    kanji_first_name: string;
    kanji_last_name: string;
    kana_first_name: string;
    kana_last_name: string;
    phone_number: string;
    sex: string;
  };
  stylist: {
    user_name: string;
  };
  qa: [];
};

export const getReservationInfo = (reservationId: string) =>
  apiBase.get<ReservationInfoType>(`/reservation/${reservationId}`);

export type SalonOfCategory = {
  id: number;
  salon_id: number;
  name: string;
  reduction_rate: number;
};

export const getReservationOfCategories = (salonId: string) =>
  apiBase.get<SalonOfCategory[]>(`/salon/${salonId}/service/categories`);

export type SalonOfMenu = {
  id: number;
  category_id: number;
  name: string;
  price: number;
  minute: number;
};

export const getReservationOfMenus = (salonId: string) =>
  apiBase.get<{data: SalonOfMenu[]}>(`/salon/${salonId}/service/menus`);

export type SalonOfCoupon = {
  id: number;
  stylist_id: number;
  name: string;
  price: number;
  minute: number;
};

export const getReservationOfCoupons = (salonId: string) =>
  apiBase.get<{data: SalonOfCoupon[]}>(`/salon/${salonId}/coupons`);

export type AddReservationType = {
  stylist_id: number;
  is_named: boolean;
  visit_salon_time: string;
  finish_time: string;
  coupon_id?: number;
  menu_id?: number;
  payment_amount: number;
  user_id: number;
  user_kana_name: string;
  user_name: string;
  sex: string;
  phone_number: string;
  memo: string;
};

export const addReservation = (salonId: string, params: AddReservationType) =>
  apiBase.put<{}>(`/salon/${salonId}/reservation`, params);
