import React, {FC} from 'react';
import styled from 'styled-components';
import Flatpickr from 'react-flatpickr';
import flatpickr from 'flatpickr';
import {ReactComponent as CarretDown} from '../../../image/carret_down.svg';

const WrapperSelect = styled.div`
  width: 100%;
  position: relative;
  .select-custom {
    width: 100%;
    height: 2rem;
    border: none;
    border-bottom: 1px solid #e0e0e0;
  }
  .carret-down {
    position: absolute;
    right: 0.4rem;
    top: 1rem;
  }
`;

export const SelectShiftTime: FC<{
  time?: Date;
  onChange: flatpickr.Options.Hook;
}> = ({time, onChange}) => {
  return (
    <WrapperSelect>
      <Flatpickr
        data-enable-time
        onChange={onChange}
        value={time}
        options={{
          noCalendar: true,
          time_24hr: true,
        }}
      />
      <CarretDown className="carret-down" />
    </WrapperSelect>
  );
};
