// TODO setoken06 API繋ぎこみで外して
/* eslint react/no-array-index-key: 0 */

import React from 'react';
import styled from 'styled-components';
import {Link} from '../../../common/Link';
import {FontSize} from '../../../../style/variable';

const SearchArea = styled.div`
  padding: 16px;
`;

const SearchTitle = styled.p`
  font-size: ${FontSize.X_SMALL};
  color: #212121;
`;

const CustomerName = styled.p`
  padding: 16px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

type Props = {
  customers: {id: string; name: string}[];
};

const SearchList: React.FC<Props> = ({customers}) => {
  return (
    <SearchArea>
      <SearchTitle>名前の一致</SearchTitle>
      {customers.map((customer, i) => (
        <Link to={`/customer/detail/${customer.id}`} key={customer.id}>
          <CustomerName>{customer.name}</CustomerName>
        </Link>
      ))}
    </SearchArea>
  );
};

export default SearchList;
