import React, {FC, useState, useCallback} from 'react';
import * as H from 'history';
import styled from 'styled-components';
import {Route, Switch} from 'react-router-dom';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {Margin} from '../../common/Margin';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {SalesInvoiceList} from './SalesInvoiceList';

export type InvoiceType = 'notApproved' | 'correctionRequested' | 'approved';

export type Invoice = {
  id: string;
  date: string;
  label: string;
  type: string;
};

type Props = {
  history: H.History;
};

const tabItems = ['未承認', '修正依頼中', '承認済'];

const HeaderContentWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;
`;

const LeftContent: FC<{history: H.History<any>}> = ({history}) => (
  <HeaderContentWrapper type="button" onClick={() => history.push('/sales')}>
    <ArrowLeft />
  </HeaderContentWrapper>
);

const Tabs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`;

const TabItem = styled.div<{isSelected: boolean}>`
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  text-align: center;
  color: ${({isSelected}) => (isSelected ? '#F93346' : '#bdbdbd')};
  border-bottom: 1px solid ${({isSelected}) => (isSelected ? '#F93346' : '#bdbdbd')};
`;

export const SalesInvoice: FC<Props> = ({history}) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleClickTabItem = useCallback(
    (i: number) => () => {
      setSelectedIndex(i);
      if (i === 0) {
        history.replace('/sales/invoice/not_approved');
        return;
      }
      if (i === 1) {
        history.replace('/sales/invoice/requesting');
      }
      if (i === 2) {
        history.replace('/sales/invoice/approved');
      }
    },
    [history],
  );

  return (
    <>
      <Header title="請求書発行" LeftContent={() => <LeftContent history={history} />} />
      <MainContainer>
        <Margin margin="32px 16px 8px 16px">
          <Tabs>
            {tabItems.map((item, i) => {
              return (
                <TabItem key={item} isSelected={i === selectedIndex} onClick={handleClickTabItem(i)}>
                  {item}
                </TabItem>
              );
            })}
          </Tabs>

          <Switch>
            <Route path="/sales/invoice/not_approved" component={() => <SalesInvoiceList status={0} />} />
            <Route path="/sales/invoice/requesting" component={() => <SalesInvoiceList status={1} />} />
            <Route path="/sales/invoice/approved" component={() => <SalesInvoiceList status={2} />} />
          </Switch>
        </Margin>
      </MainContainer>
      <Footer isColored="MY_PAGE" />
    </>
  );
};
