import React, {FC, useCallback, useState, useEffect, useContext, useMemo} from 'react';
import * as H from 'history';
import styled from 'styled-components';
import Calendar, {CalendarTileProperties, OnChangeDateCallback, ViewCallback} from 'react-calendar';
import {useHistory} from 'react-router';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {Color, FontSize, TextAlign} from '../../../style/variable';
import {Margin} from '../../common/Margin';
import {ReactComponent as Plus} from '../../../image/plus.svg';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {convertDateToJa} from '../../../lib/utils/formatDate';
import {Text} from '../../common/Text';
import {AccountContext} from '../../../lib/contexts/account';
import {Shift, getShiftsOfMonth, getShiftsOfDay} from '../../../lib/api/shift';

type Props = {
  history: H.History;
};

const HeaderContentWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;
`;

const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
`;

const LeftContent: FC<{history: H.History<any>}> = ({history}) => (
  <HeaderContentWrapper type="button" onClick={() => history.goBack()}>
    <ArrowLeft />
  </HeaderContentWrapper>
);

const RightContent: FC<{selectedDate: Date}> = ({selectedDate}) => {
  const history = useHistory();
  const handleClick = useCallback(() => {
    history.push({pathname: '/shift/active', state: {date: selectedDate}});
  }, [history, selectedDate]);

  return (
    <HeaderContentWrapper type="button" onClick={handleClick}>
      <Plus />
    </HeaderContentWrapper>
  );
};

const WrapperCalender = styled.div`
  .react-calendar {
    border: none;
    margin: auto;
    .react-calendar__tile {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
    abbr[title] {
      text-decoration: none;
    }
  }

  .react-calendar__tile--active {
    background: ${Color.ORANGE_LIGHT};
    color: inherit;
  }
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: ${Color.ORANGE_LIGHT};
  }
  .sunday {
    color: ${Color.ORANGE};
  }
  .saturday {
    color: ${Color.BLUE_LIGHT};
  }
`;

const WrapperDetailInfo = styled.div``;

const WrapperSelectedDate = styled.div`
  padding: 0.5rem 1rem;
  background: ${Color.ORANGE_LIGHT};
`;

const WrapperToDo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
`;

const HasShift = styled.div`
  width: 8px;
  height: 8px;
  margin: auto;
  border-radius: 16px;
  background: #f95738;
`;

const initialMonth = () => {
  const date = new Date();
  const month = date.toLocaleDateString('fr-CA', {
    year: 'numeric',
    month: '2-digit',
  });
  return month; // YYYY-MM
};

export const ShiftPage: FC<Props> = ({history}) => {
  const {account} = useContext(AccountContext);

  const [selectedMonth, setSelectedMonth] = useState<string>(initialMonth()); // YYYY-MM
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [shiftsOfMonth, setShiftsOfMonth] = useState<
    Array<{
      work_date: string;
      shifts: Shift[];
    }>
  >([]);
  const [shiftsOfDay, setShiftsOfDay] = useState<Shift[]>([]);

  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);

  useEffect(() => {
    if (salonId) {
      getShiftsOfMonth(salonId, selectedMonth)
        .then(({data}) => {
          setShiftsOfMonth(data.data);
        })
        .catch((e) => {
          alert('エラーが発生しました');
        });
    }
  }, [salonId, selectedMonth]);

  useEffect(() => {
    if (salonId && selectedDate) {
      const dateAsString = selectedDate.toLocaleDateString('fr-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });
      getShiftsOfDay(salonId, dateAsString)
        .then(({data}) => {
          setShiftsOfDay(data.data);
        })
        .catch((e) => {
          alert('エラーが発生しました');
        });
    }
  }, [salonId, selectedDate]);

  const selectedDateLabel = useMemo(() => {
    if (selectedDate) {
      return convertDateToJa(selectedDate);
    }
    return '';
  }, [selectedDate]);

  const tileClassName = useCallback(({date, view}: CalendarTileProperties) => {
    const classNames: string[] = [];
    if (view === 'month') {
      if (date.getDay() === 0) {
        classNames.push('sunday');
      }
      if (date.getDay() === 6) {
        classNames.push('saturday');
      }
    }
    return classNames;
  }, []);

  const tileContent = useCallback(
    ({date, view}: CalendarTileProperties) => {
      const dateAsString = date.toLocaleDateString('fr-CA', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      });

      if (view === 'month') {
        const datesWithShifts = shiftsOfMonth.map((item) => item.work_date);
        if (datesWithShifts.includes(dateAsString)) {
          return <HasShift />;
        }
      }
      return null;
    },
    [shiftsOfMonth],
  );

  const handleChange: OnChangeDateCallback = useCallback((date: Date | Date[]) => {
    if (date instanceof Date) {
      setSelectedDate(date);
    }
  }, []);

  const handleChangeActiveDate: ViewCallback = useCallback(({activeStartDate}) => {
    const month = activeStartDate.toLocaleDateString('fr-CA', {
      year: 'numeric',
      month: '2-digit',
    });
    setSelectedMonth(month);
  }, []);

  return (
    <>
      <Header
        title="シフト"
        LeftContent={() => <LeftContent history={history} />}
        RightContent={() => selectedDate && <RightContent selectedDate={selectedDate} />}
      />
      <MainContainer>
        <Margin margin="32px 0 8px 0">
          <WrapperCalender>
            <Calendar
              calendarType="US"
              locale="ja-JP"
              view="month"
              // minDate={new Date()}
              prevLabel={<ArrowLeft />}
              nextLabel={<ArrowRight />}
              prev2Label={null}
              next2Label={null}
              tileClassName={tileClassName}
              tileContent={tileContent}
              onChange={handleChange}
              onActiveDateChange={handleChangeActiveDate}
            />
            {selectedDate && (
              <WrapperDetailInfo>
                <WrapperSelectedDate>
                  <Text size={FontSize.SMALL} color={Color.GRAY_LIGHT} align={TextAlign.LEFT}>
                    {selectedDateLabel}
                  </Text>
                </WrapperSelectedDate>
                {shiftsOfDay.map((item) => {
                  return (
                    <WrapperToDo key={item.id}>
                      <p>{item.type_label}</p>
                      <p>{item.pattern}</p>
                    </WrapperToDo>
                  );
                })}
              </WrapperDetailInfo>
            )}
          </WrapperCalender>
        </Margin>
      </MainContainer>
      <Footer isColored="MY_PAGE" />
    </>
  );
};
