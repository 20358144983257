import * as React from 'react';
import styled from 'styled-components';
import {Color} from '../../../../style/variable';
import {Label} from './Label';

type TextAreaProps = {
  label: string;
  value: string;
  placeholder?: string;
  setValue: (value: string) => void;
};
const TextArea = (props: TextAreaProps) => {
  const {label, value, placeholder, setValue} = props;

  return (
    <div>
      <div>
        <Label>{label}</Label>
      </div>
      <div>
        <TextAreaStyle value={value} onChange={(e) => setValue(e.target.value)} placeholder={placeholder} rows={4} />
      </div>
    </div>
  );
};

const TextAreaStyle = styled.textarea`
  outline: none;
  border: none;
  border-bottom: 1px solid ${Color.GRAY_BORDER};
  font-size: 16px;
  line-height: 20px;
  padding: 8px 0;
  width: 100%;
`;

export {TextArea};
