import React, {FC, useCallback, useState, useMemo, useEffect, useContext} from 'react';
import * as H from 'history';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import flatpickr from 'flatpickr';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {Color} from '../../../style/variable';
import {Margin} from '../../common/Margin';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {CheckBox} from '../../parts/CheckBox';
import {ShiftType, ShiftPattern, getShiftTypes, getShiftPatterns, postAddShift} from '../../../lib/api/shift';
import {AccountContext} from '../../../lib/contexts/account';
import {SelectShiftType} from './SelectShiftType';
import {SelectShiftPattern} from './SelectShiftPattern';
import 'flatpickr/dist/themes/material_orange.css';
import {SelectShiftTime} from './SelectShiftTime';

const HeaderContentWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;
`;

const ArrowRight = styled(ArrowLeft)`
  margin: 0 12px;
  transform: rotate(180deg);
  height: 12px;
  width: 12px;
  path {
    stroke: #757575;
  }
`;

const LeftContent: FC<{history: H.History<any>}> = ({history}) => (
  <HeaderContentWrapper type="button" onClick={() => history.push('/shift')}>
    <ArrowLeft />
  </HeaderContentWrapper>
);

const WrapperDate = styled.div`
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e0e0e0;
`;

const WrapperSection = styled.div`
  margin-bottom: 1.5rem;
  .flatpickr-input {
    width: 100%;
    height: 2rem;
    border: none;
    border-bottom: 1px solid #e0e0e0;
  }
`;

const TitleLabel = styled.div`
  margin-bottom: 0.5rem;
  font-size: 12px;
`;

const CheckBoxCustom = styled.div`
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.04em;
  color: ${Color.GRAY_LIGHT};
  .checkbox-custom-label {
    margin-left: 0.5rem;
  }
`;

const TextCustom = styled.input`
  width: 100%;
  border: none;
  border-bottom: 1px solid #e0e0e0;
`;

const TextareaCutom = styled.textarea`
  width: 100%;
  border: none;
  border-bottom: 1px solid #e0e0e0;
`;

const ButtonRegister = styled.button`
  width: 100%;
  height: 3rem;
  text-align: center;
  background: ${Color.ORANGE};
  color: ${Color.WHITE};
  border: none;
  font-size: 18px;
`;

export const ShiftActive: FC = () => {
  const history = useHistory<{date: Date}>();
  const {account} = useContext(AccountContext);

  const [shiftTypes, setShiftTypes] = useState<ShiftType[]>([]);
  const [shiftPatterns, setShiftPatterns] = useState<ShiftPattern[]>([]);

  const [shiftType, setShiftType] = useState<ShiftType | undefined>(undefined);
  const [shiftPattern, setShiftPattern] = useState<ShiftPattern | undefined>(undefined);

  const [startTime, setStartTime] = useState<Date>(new Date());
  const [endTime, setEndTime] = useState<Date>(new Date());

  const [isPublished, setIsPublished] = useState<boolean>(true);
  const [holiday, setHoliday] = useState<string | undefined>(undefined);
  const [contact, setContact] = useState<string | undefined>(undefined);
  const [memo, setMemo] = useState<string>('');

  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);

  useEffect(() => {
    getShiftTypes()
      .then(({data}) => {
        setShiftTypes(data.data);
      })
      .catch((e) => {
        alert('エラーが発生しました');
      });
  }, []);

  useEffect(() => {
    if (salonId) {
      getShiftPatterns(salonId)
        .then(({data}) => {
          setShiftPatterns(data.data);
        })
        .catch((e) => {
          alert('エラーが発生しました');
        });
    }
  }, [salonId]);

  const targetDate = useMemo(() => {
    const {date} = history.location.state;
    const dateAsString = date.toLocaleDateString('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    return dateAsString;
  }, [history]);

  const handleChangeSelectShiftType = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const {value} = e.target;
      const index = shiftTypes.findIndex((item) => item.type === parseInt(value, 10));
      if (index > -1) {
        setShiftType(shiftTypes[index]);
      }
    },
    [shiftTypes],
  );

  const handleChangeSelectShiftPattern = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const {value} = e.target;
      const index = shiftPatterns.findIndex((item) => item.pattern === value);
      if (index > -1) {
        setShiftPattern(shiftPatterns[index]);
      }
    },
    [shiftPatterns],
  );

  const handleClickIsPublished = useCallback(() => {
    setIsPublished((prev) => !prev);
  }, []);

  const handleChangeHoliday = useCallback((e: any) => {
    setHoliday(e.target.value);
  }, []);

  const handleChangeContact = useCallback((e: any) => {
    setContact(e.target.value);
  }, []);

  const handleChangeMemo = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {value} = e.target;
    setMemo(value);
  }, []);

  const handleChangeSelectStartTime: flatpickr.Options.Hook = useCallback((dates: Date[]) => {
    if (dates[0]) {
      setStartTime(dates[0]);
    }
  }, []);

  const handleChangeSelectEndTime: flatpickr.Options.Hook = useCallback((dates: Date[]) => {
    if (dates[0]) {
      setEndTime(dates[0]);
    }
  }, []);

  const handleClickRegister = useCallback(async () => {
    const startTimeAsString = startTime.toLocaleTimeString('it-IT');
    const endTimeAsString = endTime.toLocaleTimeString('it-IT');

    if (!shiftType) {
      alert('未選択項目があります。');
      return;
    }

    if (shiftType.type === 1 && !shiftPattern) {
      alert('未選択項目があります。');
      return;
    }

    const params = {
      work_date: targetDate,
      start_time: `${targetDate} ${startTimeAsString}`,
      end_time: `${targetDate} ${endTimeAsString}`,
      type: shiftType ? shiftType.type : 1,
      is_private: false,
      memo,
      pattern: shiftPattern ? shiftPattern.pattern : '',
      holiday_message: holiday,
      holiday_contact_method: contact,
    };

    if (salonId) {
      try {
        await postAddShift(salonId, params);
        history.replace('/shift');
      } catch (error) {
        alert('エラーが発生しました');
      }
    }
  }, [startTime, endTime, shiftType, shiftPattern, targetDate, memo, holiday, contact, salonId, history]);

  return (
    <>
      <Header title="登録" LeftContent={() => <LeftContent history={history} />} />
      <MainContainer>
        <Margin margin="32px 16px 8px 16px">
          <WrapperDate>
            {targetDate}
            <ArrowRight />
            {targetDate}
          </WrapperDate>
          <WrapperSection>
            <TitleLabel>シフト種別</TitleLabel>
            <SelectShiftType options={shiftTypes} value={shiftType} onChange={handleChangeSelectShiftType} />
          </WrapperSection>

          {shiftType && (shiftType.type === 1 || shiftType.type === 4) && (
            <WrapperSection>
              <TitleLabel>勤務パターン</TitleLabel>
              <SelectShiftPattern
                options={shiftPatterns}
                value={shiftPattern}
                onChange={handleChangeSelectShiftPattern}
              />
            </WrapperSection>
          )}

          {shiftType && shiftType.type === 0 && (
            <WrapperSection>
              <TitleLabel>休日の表示</TitleLabel>
              <TextCustom
                type="text"
                placeholder="何も入力しない場合は自動で×"
                value={holiday}
                onChange={handleChangeHoliday}
              />
            </WrapperSection>
          )}

          {shiftType && shiftType.type === 0 && (
            <WrapperSection>
              <TitleLabel>連絡方法</TitleLabel>
              <TextCustom
                type="text"
                placeholder="任意で入力してください"
                value={contact}
                onChange={handleChangeContact}
              />
            </WrapperSection>
          )}

          {shiftType && shiftType.type === 4 && (
            <WrapperSection>
              <TitleLabel>開始時間</TitleLabel>
              <SelectShiftTime onChange={handleChangeSelectStartTime} time={startTime} />
            </WrapperSection>
          )}

          {shiftType && shiftType.type === 4 && (
            <WrapperSection>
              <TitleLabel>終了時間</TitleLabel>
              <SelectShiftTime onChange={handleChangeSelectEndTime} time={endTime} />
            </WrapperSection>
          )}

          <WrapperSection>
            <TitleLabel>スケジュールの公開</TitleLabel>
            <CheckBoxCustom>
              <CheckBox checked={!isPublished} onClick={handleClickIsPublished} />
              <span className="checkbox-custom-label">非公開</span>
            </CheckBoxCustom>
          </WrapperSection>

          <WrapperSection>
            <TitleLabel>備考</TitleLabel>
            <TextareaCutom rows={5} placeholder="テキスト入力" value={memo} onChange={handleChangeMemo} />
          </WrapperSection>

          <ButtonRegister onClick={handleClickRegister}>登録</ButtonRegister>
        </Margin>
      </MainContainer>
      <Footer isColored="MY_PAGE" />
    </>
  );
};
