import * as React from 'react';
import {addDays} from 'date-fns';

import {TimeLine} from './Time';
import {ShiftLine} from './Shift';
import {Manipulator} from './Manipulator';

type WeeklyCalendarProps = {
  targetDate: Date;
  onClickRightButton: () => void;
  onClickLeftButton: () => void;
};
const WeeklyCalendar = (props: WeeklyCalendarProps) => {
  const {targetDate, onClickRightButton, onClickLeftButton} = props;

  const datesToDisplayShift: Date[] = range(0, 6).map((num) => addDays(targetDate, num));

  return (
    <>
      <Manipulator
        targetDate={targetDate}
        onClickRightButton={onClickRightButton}
        onClickLeftButton={onClickLeftButton}
      />
      <div style={{display: 'flex'}}>
        <div style={{width: '60px'}}>
          <TimeLine />
        </div>
        <div style={{display: 'flex', flex: 1}}>
          {datesToDisplayShift.map((date) => {
            return <ShiftLine key={`shift-${date}`} date={date} />;
          })}
        </div>
      </div>
    </>
  );
};

const range = (start: number, end: number) => Array.from({length: end - start + 1}, (_v, k) => k + start);

export {WeeklyCalendar};
