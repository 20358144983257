import React, {useContext} from 'react';
import {BrowserRouter, Route, Switch, Redirect, RouteProps} from 'react-router-dom';
import {AccountContext} from '../lib/contexts/account';

// page components
import {Customer} from './pages/Customer';
import {CustomerDetail} from './pages/CustomerDetail';
import {AddCustomer} from './pages/AddCustomer';
import {PurchaseRecord} from './pages/PurchaseRecord';
import {PasswordUpdate} from './pages/PasswordUpdate';
import {PasswordForgot, SentMail, PasswordResetting, PasswordSettingCompleted} from './pages/PasswordForgot';
import {AddMedicalRecord} from './pages/AddMedicalRecord';
import {FriendIntroduction} from './pages/FriendIntroduction';
import {MedicalRecordDetail} from './pages/MedicalRecordDetail';
import {CustomerUpdate} from './pages/CustomerUpdate';
import {MedicalRecordUpdate} from './pages/MedicalRecordUpdate';
import {ShiftPage} from './pages/Shift';
import {ShiftActive} from './pages/ShiftActive';
import {Sales} from './pages/Sales';
import {SalesDetail} from './pages/SalesDetail';
import {SalesInvoice} from './pages/SalesInvoice';
import {SalesInvoiceActive} from './pages/SalesInvoiceActive';
import {SalesGoal} from './pages/SalesGoal';
import {SalesGoalDetail} from './pages/SalesGoalDetail';
import {Login} from './pages/Login';
import {Mypage} from './pages/Mypage';
import {MypageEdit} from './pages/MypageEdit';
import {Settings} from './pages/Settings';
import {SettingsPayment} from './pages/SettingsPayment';
import {SettingsBank} from './pages/SettingsBank';
import {SettingsCoupon} from './pages/SettingsCoupon';
import {SettingsCouponEdit} from './pages/SettingsCouponEdit';
import {Information} from './pages/Information';
import {InformationDetail} from './pages/InformationDetail';
import {Blog} from './pages/Blog';
import {BlogCreate} from './pages/BlobCreate';
import {BlogDetail} from './pages/BlogDetail';
import {BlogPreview} from './pages/BlogPreview';
import {BlogUpdate} from './pages/BlogUpdate';
import {InventoryMenu} from './pages/InventoryMenu';
import {InventoryCreate} from './pages/InventoryCreate';
import {InventoryList} from './pages/InventoryList';
import {InventoryOrder} from './pages/InventoryOrder';
import {InventoryUpdate} from './pages/InventoryUpdate';
import {ReservationCalendar} from './pages/ReservationCalendar';
import {ReservationCreate} from './pages/ReservationCreate';
import {ReservationDetail} from './pages/ReservationDetail';
import {ReservationShift} from './pages/ReservationShift';

const PrivateRoute: React.FC<RouteProps & {isLogin: boolean}> = ({isLogin, children, ...rest}) => {
  return (
    <Route
      {...rest}
      render={({location}) =>
        isLogin ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: {from: location},
            }}
          />
        )}
    />
  );
};

const Routes = () => {
  const {account, isMountAccount} = useContext(AccountContext);

  if (!isMountAccount) {
    return null;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/password_forgot" component={PasswordForgot} />
        <Route exact path="/email_sent" component={SentMail} />
        <Route exact path="/password_resetting" component={PasswordResetting} />
        <Route exact path="/pasword_setting_completed" component={PasswordSettingCompleted} />

        <PrivateRoute isLogin={!!account}>
          <Route exact path="/" component={Mypage} />
          <Route exact path="/mypage/edit" component={MypageEdit} />
          <Route exact path="/notification" component={Information} />
          <Route exact path="/notification/:id" component={InformationDetail} />

          {/* 設定 */}
          <Route exact path="/settings" component={Settings} />
          <Route exact path="/settings/payment" component={SettingsPayment} />
          <Route exact path="/settings/bank" component={SettingsBank} />
          <Route exact path="/settings/coupon" component={SettingsCoupon} />
          <Route exact path="/settings/coupon/add" component={SettingsCouponEdit} />
          <Route exact path="/settings/coupon/detail/:id" component={SettingsCouponEdit} />
          <Route exact path="/settings/password_update" component={PasswordUpdate} />

          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog/create" component={BlogCreate} />
          <Route exact path="/blog/preview" component={BlogPreview} />
          <Route exact path="/blog/detail/:id" component={BlogDetail} />
          <Route exact path="/blog/update" component={BlogUpdate} />

          <Route exact path="/inventory/menu" component={InventoryMenu} />
          <Route exact path="/inventory/create" component={InventoryCreate} />
          <Route exact path="/inventory/list" component={InventoryList} />
          <Route exact path="/inventory/:id/order" component={InventoryOrder} />
          <Route exact path="/inventory/:id/update" component={InventoryUpdate} />

          {/* シフト */}
          <Route exact path="/shift" component={ShiftPage} />
          <Route exact path="/shift/active" component={ShiftActive} />

          {/* 売上実績 */}
          <Route exact path="/sales" component={Sales} />
          <Route path="/sales/detail" component={SalesDetail} />

          <Route exact path="/sales/invoice/:status" component={SalesInvoice} />
          <Route exact path="/sales/invoice/detail/:id" component={SalesInvoiceActive} />

          <Route exact path="/sales/goal" component={SalesGoal} />
          <Route exact path="/sales/goal/:id" component={SalesGoalDetail} />

          {/* 予約 */}
          <Route exact path="/reservations" component={ReservationShift} />
          <Route exact path="/reservation/create" component={ReservationCreate} />
          <Route exact path="/reservation/detail/:id" component={ReservationDetail} />
          <Route exact path="/reservations/calendar" component={ReservationCalendar} />

          <Route exact path="/customer" component={Customer} />
          <Route exact path="/customer/detail/:id" component={CustomerDetail} />
          <Route exact path="/customer/detail/:id/friend_introduction" component={FriendIntroduction} />
          <Route exact path="/customer/purchase_record" component={PurchaseRecord} />
          <Route exact path="/customer/add" component={AddCustomer} />
          <Route exact path="/customer/edit/:id" component={CustomerUpdate} />
          <Route exact path="/customer/medical_record/add" component={AddMedicalRecord} />
          <Route exact path="/customer/medical_record/detail/:id" component={MedicalRecordDetail} />
          <Route exact path="/customer/medical_record/edit/:id" component={MedicalRecordUpdate} />
        </PrivateRoute>
        {/* アンケート作りたい */}
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
