import React, {useState} from 'react';
import styled from 'styled-components';
import {CheckBox} from '../../CheckBox';
import {FontSize} from '../../../../style/variable';
import {ReactComponent as Search} from '../../../../image/search.svg';
import {MaterialCategoryType} from '../../../../lib/api/karte';

const SearchArea = styled.div`
  display: flex;
  padding: 1px;
  width: 100%;
  align-items: center;
  background: #757575;
  border-radius: 4px 4px 0px 0px;
  flex-flow: column;
  margin-bottom: 24px;
`;

const SearchBar = styled.div`
  font-size: ${FontSize.SMALL};
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  color: #ffffff;
  padding: 7px 9px;
`;

const SearchContent = styled.div`
  display: flex;
  background-color: #ffffff;
  width: 100%;
  flex-wrap: wrap;
  border-bottom: 1px solid #e8e8e8;
`;

const Category = styled.div`
  background-color: #ffffff;
  width: 50%;
  display: flex;
  padding: 8px;
  display: flex;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #e8e8e8;
  }
  border-right: 1px solid #e8e8e8;
`;

const CategoryLabel = styled.div`
  margin-left: 8px;
  font-size: ${FontSize.X_SMALL};
  color: #212121;
`;

const Triangle = styled.span`
  font-size: 7px;
  height: 7px;
  color: #ffffff;
  margin-bottom: 7px;
`;

const SearchSubmitArea = styled.div`
  display: flex;
  background-color: #ffffff;
  width: 100%;
  flex-wrap: wrap;
  padding: 7px;
`;

const SearchSubmit = styled.div`
  height: 35px;
  background: #fa6f54;
  border-radius: 4px;
  color: #ffffff;
  font-size: ${FontSize.SMALL};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchText = styled.div`
  display: flex;
  align-items: center;
`;

const SearchIcon = styled(Search)`
  margin-right: 12px;
  & path {
    stroke: #ffffff;
    stroke-opacity: 1;
  }
`;

type Props = {
  categories: MaterialCategoryType[];
  setSelectedCategoryIds: React.Dispatch<React.SetStateAction<number[]>>;
};

const MaterialSearch: React.FC<Props> = ({categories, setSelectedCategoryIds}) => {
  const [open, setOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const onClickCheckBox = (id: number) => () => {
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((v) => v !== id));
    } else {
      setSelectedIds([...selectedIds, id]);
    }
  };

  const onSubmit = () => {
    setSelectedCategoryIds(selectedIds);
  };
  return (
    <SearchArea>
      <SearchBar onClick={() => setOpen(!open)}>
        <SearchText>
          <SearchIcon />
          絞り込む
        </SearchText>
        <Triangle>▼</Triangle>
      </SearchBar>
      {open && (
        <>
          <SearchContent>
            {categories.map((it) => (
              <Category key={it.id} onClick={onClickCheckBox(it.id)}>
                <CheckBox checked={selectedIds.includes(it.id)} variant="outlined" />
                <CategoryLabel>{it.name}</CategoryLabel>
              </Category>
            ))}
          </SearchContent>
          <SearchSubmitArea>
            <SearchSubmit onClick={onSubmit}>絞り込む</SearchSubmit>
          </SearchSubmitArea>
        </>
      )}
    </SearchArea>
  );
};

export default MaterialSearch;
