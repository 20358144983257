import * as React from 'react';
import styled from 'styled-components';
import dayjs from 'dayjs';
import {Color} from '../../../../style/variable';

type DateManipulatorProps = {
  targetDate: dayjs.Dayjs;
  onClickRightButton: () => void;
  onClickLeftButton: () => void;
};
const DateManipulator = (props: DateManipulatorProps) => {
  const {targetDate, onClickRightButton, onClickLeftButton} = props;

  return (
    <Wrapper>
      <LeftButton onClick={() => onClickLeftButton()} />
      <DateDisplay>{targetDate.format('MM/DD')}</DateDisplay>
      <RightButton onClick={() => onClickRightButton()} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const DateDisplay = styled.div`
  color: ${Color.BLACK};
  font-size: 24px;
  font-weight: bold;
`;

const LeftButton = styled.button`
  border: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 46px;
  position: relative;

  &::after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    width: 13px;
    height: 13px;
    border-right: 2px solid rgba(249, 87, 56, 0.8);
    border-bottom: 2px solid rgba(249, 87, 56, 0.8);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    cursor: pointer;
  }
`;

const RightButton = styled.button`
  border: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 46px;
  position: relative;

  &::after {
    display: block;
    content: '';
    position: absolute;
    right: 0;
    width: 13px;
    height: 13px;
    border-right: 2px solid rgba(249, 87, 56, 0.8);
    border-bottom: 2px solid rgba(249, 87, 56, 0.8);
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    cursor: pointer;
  }
`;

export {DateManipulator};
