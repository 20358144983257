import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import {Link} from '../../common/Link';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';

const RightContentItem = styled.p`
  color: rgba(249, 87, 56, 0.86);
`;

const Content = styled.div`
  height: 120px;
  margin: 0 16px;
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const RowWrapper = styled.div`
  display: flex;
`;

const Title = styled.p`
  width: 62px;
  text-align: left;
  font-size: 12px;
  line-height: 14px;
`;

const Text = styled.p`
  font-size: 12px;
  line-height: 14px;
`;

export const PurchaseRecord = () => {
  const history = useHistory();

  const LeftContent: React.FC = () => <ArrowLeft onClick={() => history.goBack()} />;
  const RightContent: React.FC = () => (
    <Link to="/">
      <RightContentItem>編集</RightContentItem>
    </Link>
  );

  return (
    <div>
      <Header title="購入履歴" RightContent={RightContent} LeftContent={LeftContent} />
      <MainContainer>
        <Content>
          <RowWrapper>
            <Title>購入日</Title>
            <Text>：2020-01-01</Text>
          </RowWrapper>
          <RowWrapper>
            <Title>商品ID</Title>
            <Text>：2020</Text>
          </RowWrapper>
          <RowWrapper>
            <Title>商品名</Title>
            <Text>：ワックス</Text>
          </RowWrapper>
          <RowWrapper>
            <Title>価格</Title>
            <Text>：1,000円</Text>
          </RowWrapper>
        </Content>
      </MainContainer>
    </div>
  );
};
