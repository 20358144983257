import React, {FC, useState, useEffect, useMemo, useContext} from 'react';
import * as H from 'history';
import styled from 'styled-components';
import useForm from 'react-hook-form';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {ValidationErrorMessage} from '../../common/ValidationErrorMessage';
import {Text} from '../../common/Text/index';
import {Margin} from '../../common/Margin';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {InputTextCustom} from './InputTextCustom';
import {SelectCustom} from './SelectCustom';
import {Color, FontSize} from '../../../style/variable';
import {
  AccountType,
  BankAccountType,
  getBankAccountTypes,
  updateBankAccount,
  UpdateProfileParams,
} from '../../../lib/api/auth';
import {AccountContext} from '../../../lib/contexts/account';

type Props = {
  history: H.History;
};

const HeaderContentWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;
`;

const LeftContent: FC<{history: H.History<any>}> = ({history}) => (
  <HeaderContentWrapper type="button" onClick={() => history.push('/settings')}>
    <ArrowLeft />
  </HeaderContentWrapper>
);

const ButtonSave = styled.button`
  width: 100%;
  height: 3rem;
  text-align: center;
  background: ${Color.ORANGE};
  color: ${Color.WHITE};
  border: none;
  font-size: 18px;
  border-radius: 4px;
`;

type FormValues = {
  bank_name: string;
  bank_number: string;
  branch_store_name: string;
  branch_store_number: string;
  bank_account_name: string;
  bank_account_type: string;
  bank_account_number: string;
};

export const SettingsBank: FC<Props> = ({history}) => {
  const {account, mountAccount} = useContext(AccountContext);
  const [bankAccountTypes, setBankAccountTypes] = useState<BankAccountType[]>([]);
  const [result, setResult] = useState<'success' | 'failed' | null>(null);
  const {register, handleSubmit, errors, setValue} = useForm<FormValues>({
    defaultValues: account
      ? {
          bank_name: account.bank_name || 'ok',
          bank_number: String(account.bank_number) || '',
          branch_store_name: account.branch_store_name || '',
          branch_store_number: String(account.branch_store_number) || '',
          bank_account_name: account.bank_account_name || '',
          bank_account_type: account.bank_account_type || '',
          bank_account_number: String(account.bank_account_number) || '',
        }
      : undefined,
  });

  const options = useMemo(() => {
    return bankAccountTypes.map((item) => {
      return {value: item.label, label: item.label};
    });
  }, [bankAccountTypes]);

  const onSubmit = handleSubmit((data, e) => {
    e.preventDefault();

    updateBankAccount({
      ...data,
      bank_number: Number(data.bank_number),
      branch_store_number: Number(data.branch_store_number),
      bank_account_number: Number(data.bank_account_number),
    })
      .then(() => {
        setResult('success');
        mountAccount();
      })
      .catch(() => {
        setResult('failed');
      });
  });

  useEffect(() => {
    getBankAccountTypes().then(({data}) => {
      setBankAccountTypes(data);
      setValue('bank_account_type', account ? account.bank_account_type : '');
    });
  }, []);

  return (
    <>
      <Header title="口座情報" LeftContent={() => <LeftContent history={history} />} />
      {account && (
        <MainContainer>
          <Margin margin="32px 16px 8px 16px">
            <InputTextCustom
              label="金融機関名"
              placeholder="○○銀行"
              name="bank_name"
              register={register({
                required: '必須の項目です',
                maxLength: {
                  value: 21,
                  message: '21文字以内で入力してください',
                },
              })}
            />
            {errors.bank_name && <ValidationErrorMessage message={errors.bank_name.message} />}

            <InputTextCustom
              label="金融機関番号"
              placeholder="0010"
              type="text"
              name="bank_number"
              register={register({
                required: '必須の項目です',
                pattern: {
                  value: /[\d]{4}/,
                  message: '半角数字4桁で入力してください',
                },
                maxLength: {
                  value: 4,
                  message: '半角数字4桁で入力してください',
                },
              })}
            />
            {errors.bank_number && <ValidationErrorMessage message={errors.bank_number.message} />}

            <InputTextCustom
              label="支店名"
              placeholder="新宿東口店"
              name="branch_store_name"
              register={register({
                required: '必須の項目です',
                maxLength: {
                  value: 21,
                  message: '21文字以内で入力してください',
                },
              })}
            />
            {errors.branch_store_name && <ValidationErrorMessage message={errors.branch_store_name.message} />}

            <InputTextCustom
              label="支店番号"
              placeholder="012"
              type="text"
              name="branch_store_number"
              register={register({
                required: '必須の項目です',
                pattern: {
                  value: /[\d]{3}/,
                  message: '半角数字3桁で入力してください',
                },
                maxLength: {
                  value: 3,
                  message: '半角数字3桁で入力してください',
                },
              })}
            />
            {errors.branch_store_number && <ValidationErrorMessage message={errors.branch_store_number.message} />}

            <InputTextCustom
              label="口座名義人"
              placeholder="タナカ タロウ"
              name="bank_account_name"
              register={register({
                required: '必須の項目です',
                maxLength: {
                  value: 3,
                  message: '半角数字3桁で入力してください',
                },
              })}
            />
            {errors.bank_account_name && <ValidationErrorMessage message={errors.bank_account_name.message} />}

            <SelectCustom
              label="預金種目"
              options={options}
              name="bank_account_type"
              register={register({
                required: '必須の項目です',
              })}
            />
            {errors.bank_account_type && <ValidationErrorMessage message={errors.bank_account_type.message} />}

            <InputTextCustom
              label="口座番号"
              placeholder="1234567"
              type="number"
              name="bank_account_number"
              register={register({
                required: '必須の項目です',
                pattern: {
                  value: /[\d]{7}/,
                  message: '半角数字7桁で入力してください',
                },
                maxLength: {
                  value: 7,
                  message: '半角数字7桁で入力してください',
                },
              })}
            />
            {errors.bank_account_number && <ValidationErrorMessage message={errors.bank_account_number.message} />}

            <Margin margin="1rem 0 0 0" />

            <ButtonSave onClick={onSubmit}>保存</ButtonSave>
            <Margin margin="1rem 0 0 0" />
            {result === 'success' ? (
              <Text color={Color.BLUE_LIGHT} size={FontSize.LARGE}>
                保存しました
              </Text>
            ) : null}
            {result === 'failed' ? (
              <Text color={Color.RED} size={FontSize.LARGE}>
                保存に失敗しました
              </Text>
            ) : null}
          </Margin>
        </MainContainer>
      )}

      <Footer isColored="MY_PAGE" />
    </>
  );
};
