import React, {useState} from 'react';
import styled from 'styled-components';
import useForm from 'react-hook-form';
import {useHistory} from 'react-router';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {Margin} from '../../common/Margin';
import {Text} from '../../common/Text';
import {FontSize, Color} from '../../../style/variable';
import {ReactComponent as EyeOff} from '../../../image/eye-off.svg';
import {ReactComponent as Eye} from '../../../image/eye.svg';
import {passwordResetAtLogin, PasswordResetAtLoginparams} from '../../../lib/api/auth';
import {ValidationErrorMessage} from '../../common/ValidationErrorMessage';

const Label = styled.p`
  font-weight: bold;
  margin-bottom: 16px;
  font-size: ${FontSize.MEDIUM};
`;

const InputFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 16px;
`;

const InputField = styled.input`
  width: 100%;
  height: 25px;
  padding-bottom: 8px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
  border-radius: 0;
`;

const SubmitButton = styled.button`
  width: 100%;
  background: rgba(249, 87, 56, 0.87);
  border-radius: 4px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  color: #fff;
  border: none;
  font-weight: bold;
`;

const InactiveButton = styled.button`
  width: 100%;
  background: rgba(249, 87, 56, 0.26);
  border-radius: 4px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  color: #fff;
  border: none;
  font-weight: bold;
`;

const EyeOffButton = styled.button`
  height: 24px;
  background: #fff;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: none;
`;

export const PasswordUpdate = () => {
  const [result, setResult] = useState<'success' | 'diff' | 'failed' | null>(null);
  const [isShownPassword, setIsShownPassword] = useState<boolean>(false);
  const [isShownNewPassword, setIsShownNewPassword] = useState<boolean>(false);
  const history = useHistory();
  const LeftContent: React.FC = () => <ArrowLeft onClick={() => history.push('/settings')} />;
  const {handleSubmit, register, errors, watch} = useForm<PasswordResetAtLoginparams>({
    defaultValues: {
      current_password: '',
      new_password: '',
      new_password_confirmation: '',
    },
  });

  const resultDistribution = () => {
    switch (result) {
      case 'success':
        return <Text color={Color.BLUE_LIGHT}>変更に成功しました</Text>;
      case 'diff':
        return (
          <Text color={Color.RED} size={FontSize.LARGE}>
            パスワードが異なっています
          </Text>
        );
      case 'failed':
        return (
          <Text color={Color.RED} size={FontSize.LARGE}>
            変更に失敗しました
          </Text>
        );
      default:
        break;
    }
  };

  const onSubmit = handleSubmit((value) => {
    if (value.new_password === value.new_password_confirmation) {
      setResult(null);
      passwordResetAtLogin(value)
        .then(() => {
          setResult('success');
          history.push('/settings');
        })
        .catch(() => {
          setResult('failed');
        });
    } else {
      setResult('diff');
    }
  });

  return (
    <div>
      <Header title="パスワードの設定" LeftContent={LeftContent} />
      <MainContainer>
        <Margin margin="32px 16px 0">
          <form onSubmit={onSubmit}>
            <Label>現在のパスワード</Label>
            <InputFieldWrapper>
              <InputField name="current_password" ref={register} type="password" placeholder="入力してください" />
            </InputFieldWrapper>

            <ValidationErrorMessage message={errors.current_password && errors.current_password.message} />

            <Label>新しいパスワード</Label>
            <InputFieldWrapper>
              <InputField
                name="new_password"
                ref={register}
                type={isShownPassword ? 'text' : 'password'}
                placeholder="入力してください"
              />
              <EyeOffButton type="button" onClick={() => setIsShownPassword(!isShownPassword)}>
                {isShownPassword ? <Eye /> : <EyeOff />}
              </EyeOffButton>
            </InputFieldWrapper>

            <ValidationErrorMessage message={errors.new_password && errors.new_password.message} />

            <Label>新しいパスワード（確認）</Label>
            <InputFieldWrapper>
              <InputField
                name="new_password_confirmation"
                ref={register}
                type={isShownNewPassword ? 'text' : 'password'}
                placeholder="入力してください"
              />
              <EyeOffButton type="button" onClick={() => setIsShownNewPassword(!isShownNewPassword)}>
                {isShownNewPassword ? <Eye /> : <EyeOff />}
              </EyeOffButton>
            </InputFieldWrapper>

            <ValidationErrorMessage
              message={errors.new_password_confirmation && errors.new_password_confirmation.message}
            />

            <SubmitButton type="submit">変更する</SubmitButton>

            {resultDistribution()}
          </form>
        </Margin>
      </MainContainer>
    </div>
  );
};
