import React from 'react';
import styled from 'styled-components';
import {Link} from 'react-router-dom';
import {ReactComponent as Mine} from '../../image/mine.svg';
import {ReactComponent as Menu} from '../../image/menu.svg';
import {ReactComponent as Book} from '../../image/book.svg';
import {Color} from '../../style/variable';
import useMediaQuery, {BreakPoint, WidthCriteria} from '../../lib/hooks/useMedisQuery';

const Wrapper = styled.footer<{isPC: boolean}>`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  position: fixed;
  /* bottom: ${({isPC}) => (isPC ? 'calc(calc(100vh - 813px) / 2)' : '0')}; */
  bottom: ${({isPC}) => (isPC ? '10vh' : '0')};
  width: 100%;
  max-width: ${({isPC}) => (isPC ? '560px' : 'none')};
  background: #fff;
`;

const RouterLink = styled(Link)`
  width: 25%;
  text-decoration: none;
`;

const NavItem = styled.div<{iscolored: boolean}>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${({iscolored}) =>
    iscolored &&
    `
    p {
      color: ${Color.ORANGE};
    }

    svg {
      path {
        stroke: ${Color.ORANGE};
        stroke-opacity: 1;
      }
    }
  `}
`;

const Text = styled.p`
  letter-spacing: 0.08em;
  color: rgba(0, 0, 0, 0.27);
  font-size: 10px;
  line-height: 12px;
`;

type Props = {
  isColored?: 'MY_PAGE' | 'CUSTOMER' | 'RESERVATION';
};

const Footer: React.FC<Props> = ({isColored}) => {
  const isPC = useMediaQuery(BreakPoint.mobile, WidthCriteria.min);

  return (
    <Wrapper isPC={isPC}>
      <RouterLink to="/">
        <NavItem iscolored={isColored === 'MY_PAGE'}>
          <Mine />
          <Text>マイページ</Text>
        </NavItem>
      </RouterLink>
      <RouterLink to="/customer">
        <NavItem iscolored={isColored === 'CUSTOMER'}>
          <Menu />
          <Text>お客様一覧</Text>
        </NavItem>
      </RouterLink>
      <RouterLink to="/reservations">
        <NavItem iscolored={isColored === 'RESERVATION'}>
          <Book />
          <Text>予約一覧</Text>
        </NavItem>
      </RouterLink>
    </Wrapper>
  );
};

export default Footer;
