import React from 'react';
import {useHistory} from 'react-router';
import styled from 'styled-components';
import dayjs, {cellWidth, cellHeight} from '../../../../lib/utils/dayjs';
import {ScheduleType} from './ReservationTableBody';

const OverlayCell = styled.div<{startIndex: number; colspan: number}>`
  left: calc(${cellWidth} * ${({startIndex}) => startIndex});
  width: calc(${cellWidth} * ${({colspan}) => colspan} - 2px);
  height: calc(${cellHeight} - 2px);
  background: #ffa500;
  position: absolute;
  padding: 4px;
  display: flex;
`;

const Label = styled.p`
  color: #212121;
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

type Props = {
  days: dayjs.Dayjs[];
  schedules: ScheduleType[];
};
export const OverlaySchedule: React.FC<Props> = ({days, schedules}) => {
  const history = useHistory();
  const cells = schedules.map((it) => {
    let startIndex = 0;
    let colspan = 1;
    const isSameDay = days.some((day) => {
      if (!day.isSame(dayjs(it.startTime)) && !day.isAfter(dayjs(it.startTime))) {
        startIndex += 1;
      }
      if (day.isSame(dayjs(it.endTime))) {
        return true;
      }
      if (day.isAfter(dayjs(it.startTime))) {
        colspan += 1;
      }
      return false;
    });
    return {uid: it.id, startIndex, colspan, isSameDay, name: it.customerName};
  });
  return (
    <>
      {cells
        .filter((cell) => cell.isSameDay)
        .map((cell) => (
          <OverlayCell
            id={`${cell.uid}`}
            key={cell.uid}
            onClick={() => history.push(`/reservation/detail/${cell.uid}`)}
            {...cell}
          >
            <Label>{cell.name}</Label>
          </OverlayCell>
        ))}
    </>
  );
};
