import React, {FC, useCallback} from 'react';
import styled from 'styled-components';
import {ReactComponent as CarretDown} from '../../../image/carret_down.svg';

const MainWrapper = styled.div`
  width: 100%;
  position: relative;
  .select-custom {
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
  .carret-down {
    position: absolute;
    top: 2.5rem;
    right: 1rem;
  }
`;

const Label = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  font-size: 12px;
`;

export const SelectCustom: FC<{
  options: Array<any>;
  label: string;
  name: string;
  register: any;
}> = ({options, label, name, register}) => {
  return (
    <MainWrapper>
      <Label>{label}</Label>
      <select className="select-custom" name={name} ref={register}>
        <option value="">選択してください</option>
        {options.map((item) => {
          return (
            <option key={item.value} value={item.value}>
              {item.label}
            </option>
          );
        })}
      </select>
      <CarretDown className="carret-down" />
    </MainWrapper>
  );
};
