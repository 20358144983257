/* eslint no-shadow: 0 */
import React, {useState, useEffect} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import {Spacer} from '../../common/Spacer';
import {BackButton} from '../../parts/BackButton';
import {Text} from '../../common/Text';
import {Space, FontSize, TextAlign, LineHeight, FontWeight} from '../../../style/variable';
import {getMessage, Message} from '../../../lib/api/message';
import {URLToLink} from '../../../lib/utils/URLToLink';

const LeftContent: React.FC = () => <BackButton path="/notification" />;

type Props = RouteComponentProps<{id: string}>;

export const InformationDetail: React.FC<Props> = ({match}) => {
  const [message, setMessage] = useState<Message | null>(null);

  useEffect(() => {
    getMessage(match.params.id).then(({data}) => {
      setMessage(data);
    });
  }, []);

  return (
    <>
      <Header title="メッセージ" LeftContent={LeftContent} />
      <MainContainer marginHorizontal={Space * 2}>
        <Spacer sizes={{pb: '2x'}} />
        <Text size={FontSize.MEDIUM} weight={FontWeight.BOLD} block>
          {message ? message.title : ''}
        </Text>
        <Spacer sizes={{pb: '3x'}} />
        <Text size={FontSize.SMALL} align={TextAlign.RIGHT} block>
          {message ? message.send_at : ''}
        </Text>
        <Spacer sizes={{pb: '2x'}} />
        <Text size={FontSize.SMALL} align={TextAlign.LEFT} lineHeight={LineHeight.MEDIUM} block>
          {message ? URLToLink(message.body) : ''}
        </Text>
      </MainContainer>
      {/* <Footer isColored="MY_PAGE" /> */}
    </>
  );
};
