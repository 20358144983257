import React, {useState} from 'react';
import styled from 'styled-components';
import {addDays} from 'date-fns';
import Header from '../../common/Header';
import {BackButton} from '../../parts/BackButton';
import {Padding} from '../../common/Padding';
import {WeeklyCalendar} from './Calendar';

const ReservationCalendar = () => {
  const targetDateMock: Date = new Date(2020, 0, 1);
  const [targetDate, setTargetDate] = useState<Date>(targetDateMock);

  const changeTargetDateToNextWeek = () => setTargetDate(addDays(targetDate, 7));

  const changeTargetDateToLastWeek = () => setTargetDate(addDays(targetDate, -7));

  return (
    <>
      <Header title="予約日時" LeftContent={LeftContent} />

      <CustomContainer>
        <Padding padding="24px 0 0 0" />
        <WeeklyCalendar
          targetDate={targetDate}
          onClickRightButton={changeTargetDateToNextWeek}
          onClickLeftButton={changeTargetDateToLastWeek}
        />
      </CustomContainer>
    </>
  );
};

const CustomContainer = styled.div`
  min-height: 100%;
  width: 100%;
  padding: 41px 0 52px;
`;

const LeftContent = () => <BackButton path="/" />;

export {ReservationCalendar};
