import React from 'react';
import styled from 'styled-components';
import {Link} from '../../common/Link';

const ItemWrapper = styled.li<{
  display: string;
  isRightArrow: boolean;
}>`
  display: ${({display}) => display};
  justify-content: left;
  align-items: center;
  min-height: 46px;
  position: relative;

  ${({isRightArrow}) =>
    isRightArrow
      ? `
    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 1em;
      right: 2em;
      width: 10px;
      height: 10px;
      border-right: 2px solid #666;
      border-bottom: 2px solid #666;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    } `
      : null};
`;

export type Props = {
  to?: string;
  display?: 'flex' | 'block';
  isRightArrow?: boolean;
};

export const Item: React.FC<Props> = ({to, display, isRightArrow, children, ...rest}) =>
  to ? (
    <Link to={to as string}>
      <ItemWrapper display={display as string} isRightArrow={isRightArrow as boolean} {...rest}>
        {children}
      </ItemWrapper>
    </Link>
  ) : (
    <ItemWrapper display={display as string} isRightArrow={isRightArrow as boolean} {...rest}>
      {children}
    </ItemWrapper>
  );

Item.defaultProps = {
  display: 'flex',
  isRightArrow: true,
};
