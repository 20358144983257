import React from 'react';
import styled from 'styled-components';
import {FontSize} from '../../style/variable';

const StyledInput = styled.input`
  border: none;
  color: #242424;
  background: #fff;
  font-size: ${FontSize.MEDIUM};
  position: relative;

  ::-webkit-clear-button {
    -webkit-appearance: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  ::-webkit-calendar-picker-indicator {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0%;
  }
`;

type Props = {
  date: string;
  handleChange: (date: string) => void;
  type?: 'date' | 'month';
};

export const DatePicker: React.FC<Props> = ({date, handleChange, type}) => (
  <>
    <StyledInput
      type={type || 'date'}
      value={date}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event.target.value)}
    />
  </>
);
