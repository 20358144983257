import React from 'react';
import styled from 'styled-components';

type Props = {
  message: any;
};

const Wrapper = styled.div`
  color: #da3922;
  padding: 4px 0;
`;

export const ValidationErrorMessage: React.FC<Props> = ({message}) => {
  return <Wrapper>{message}</Wrapper>;
};
