import React from 'react';
import styled from 'styled-components';
import {InventoryTableBase, ColoredTableRow} from './style';
import {MockDealer} from './mockType';

type Props = {
  dealers: MockDealer[];
};
const InventoryDealerTable = (props: Props) => {
  const {dealers} = props;

  return (
    <InventoryDealerTableWrapper>
      <table>
        <thead>
          <tr>
            <th>ディーラー</th>
            <th>最低額</th>
            <th>発注額</th>
          </tr>
        </thead>
        <tbody>
          {dealers.map((dealer) => (
            <ColoredTableRow color={dealer.color} key={`dealer-${dealer.id}`}>
              <td>{dealer.name}</td>
              <td>{`${dealer.minAmount}円`}</td>
              <td>{`${dealer.orderedAmount}円`}</td>
            </ColoredTableRow>
          ))}
        </tbody>
      </table>
    </InventoryDealerTableWrapper>
  );
};

// Memo:
// as anyについて
// styled-components v4にて TypeScriptで継承を使おうとすると発生する問題。
// v5にupgradeすると治る模様。
// ref: https://github.com/microsoft/TypeScript/issues/37597
//
// TODO: styled-componentsをv5にupgrade後、as anyを外し TypeCheckが通ることを確認する
const InventoryDealerTableWrapper = styled(InventoryTableBase as any)`
  td:first-child,
  th:first-child {
    width: 50%;
  }

  td:first-child {
    text-align: left;
  }

  td {
    text-align: center;
  }
`;

export {InventoryDealerTable};
