import React from 'react';
import styled from 'styled-components';

const Container = styled.div<{isPC: boolean}>`
  width: 100%;
  height: ${({isPC}) => (isPC ? '100vh' : 'none')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  isPC: boolean;
};

export const PCLayout: React.FC<Props> = ({isPC, children}) => {
  return <Container isPC={isPC}>{children}</Container>;
};
