import apiBase from './apiBase';

export type KarteType = {
  id: number;
  user_id: number;
  date: string;
  thumbnail: string;
};
export const getKartes = (userId: string) => apiBase.get<KarteType[]>(`/user/${userId}/kartes`);

export type MaterialType = {
  id: number;
  data?: {name: string};
  name: string;
  description: string;
  price: number;
  material_category_id: number;
};

export type RecommendType = {
  id: number;
  name: string;
  description: string;
  price: number;
  category_id: number;
};

export type ImageType = {
  id?: number;
  url?: string;
  base64: string;
};

export type KarteDetailType = {
  id?: number;
  images: ImageType[];
  date: string;
  materials: MaterialType[];
  recommend?: RecommendType;
  recommend_product_description: string;
  ref_images: ImageType[];
  memo: string;
};

export type MaterialCategoryType = {
  id: number;
  name: string;
};

export type ProductCategoryType = {
  id: number;
  name: string;
};

export const getKarte = (karteId: string) => apiBase.get<KarteDetailType>(`/karte/${karteId}`);
export const getMaterials = () => apiBase.get<MaterialType[]>(`/materials`);
export const getMaterialCategories = () => apiBase.get<MaterialCategoryType[]>(`/materialCategories`);
export const getRecommends = () => apiBase.get<RecommendType[]>(`/products`);
export const getRecommendCategories = () => apiBase.get<ProductCategoryType[]>(`/categories`);

type UpdateImageType = {
  base64?: string;
};
export type UpdateKarteType = {
  karte_id?: number;
  images: UpdateImageType[];
  date: string;
  material_ids: number[];
  recommend_id?: number;
  recommend_product_description: string;
  ref_images: UpdateImageType[];
  memo: string;
};
export const addKarte = (userId: string, param: UpdateKarteType) =>
  apiBase.post<{id: number}>(`/user/${userId}/karte`, {user_id: userId, ...param});
export const updateKarte = (karteId: string, param: UpdateKarteType) => apiBase.put(`/karte/${karteId}`, param);
