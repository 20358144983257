import React, {FC, useCallback, useState, useEffect} from 'react';
import * as H from 'history';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {Margin} from '../../common/Margin';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {Coupon, getCoupons} from '../../../lib/api/coupon';
import {CouponItem} from './CouponItem';
import {ReactComponent as Plus} from '../../../image/plus.svg';
import {Link} from '../../common/Link';

type Props = {
  history: H.History;
};

const HeaderContentWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;
`;

const LeftContent: FC<{history: H.History<any>}> = ({history}) => (
  <HeaderContentWrapper type="button" onClick={() => history.push('/settings')}>
    <ArrowLeft />
  </HeaderContentWrapper>
);

const RightContent: FC = () => {
  const history = useHistory();
  const handleClick = useCallback(() => {
    history.push('/settings/coupon/add');
  }, [history]);

  return (
    <HeaderContentWrapper type="button" onClick={handleClick}>
      <Plus />
    </HeaderContentWrapper>
  );
};

export const SettingsCoupon: FC<Props> = ({history}) => {
  const [coupons, setCoupons] = useState<Coupon[]>([]);

  useEffect(() => {
    getCoupons()
      .then(({data}) => {
        setCoupons(data);
      })
      .catch((e) => {
        alert(e.message);
      });
  }, []);

  return (
    <>
      <Header
        title="クーポン"
        LeftContent={() => <LeftContent history={history} />}
        RightContent={() => <RightContent />}
      />
      <MainContainer>
        <Margin margin="32px 16px 64px 16px">
          {coupons.map((item) => {
            return (
              <Link key={item.id} to={`/settings/coupon/detail/${item.id}`}>
                <CouponItem coupon={item} />
              </Link>
            );
          })}
        </Margin>
      </MainContainer>
      <Footer isColored="MY_PAGE" />
    </>
  );
};
