import React, {useContext, useMemo} from 'react';
import styled, {css} from 'styled-components';
import * as H from 'history';
import {RouteComponentProps} from 'react-router-dom';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {Link} from '../../common/Link';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {FontSize} from '../../../style/variable';
import {ReactComponent as Plus} from '../../../image/plus_circle.svg';
import {useCustomer} from '../../../lib/hooks/customer';
import {Spinner} from '../../common/Spinner';
import {AccountContext} from '../../../lib/contexts/account';
import {useKartes} from '../../../lib/hooks/kartes';

const RightContentItem = styled.p`
  color: rgba(249, 87, 56, 0.86);
`;

const Menu = css`
  font-size: ${FontSize.MEDIUM};
  color: rgba(0, 0, 0, 0.86);
  padding: 16px 0;
  margin: 0 16px;
  height: 51px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

const MenuContent = styled.div`
  ${Menu};
`;

const Tel = styled.a`
  ${Menu};
  color: #1e90ff;
`;

const LinkContent = styled(Link)`
  ${Menu};
  justify-content: space-between;
`;

const Memo = styled.div`
  ${Menu};
  height: 87px;
`;

const MedicalRecord = styled(Link)`
  height: 88px;
  margin: 16px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding: 16px;
`;

const MedicalRecordContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 16px;
`;

const Thumbnail = styled.img`
  width: 64px;
  height: 64px;
  left: 28px;
  top: 466px;
  border-radius: 12px;
`;

const Date = styled.div`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.56);
`;

const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
  height: 15px;
  width: 15px;

  path {
    stroke: #757575;
  }
`;

const HoverButton = styled.button`
  border-radius: 26px;
  position: fixed;
  bottom: calc(68px + 54px);
  right: 16px;
  background: #fff;
  border: none;
  height: 72px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const ReservationLink = styled(Link)`
  position: fixed;
  bottom: 52px;
  height: 54px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background: #fa6f54;
`;

type Props = {history: H.History} & RouteComponentProps<{id: string}>;

export const CustomerDetail: React.FC<Props> = (props) => {
  const {history, match} = props;
  const userId = match.params.id;
  const {account} = useContext(AccountContext);
  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);

  const customer = useCustomer(`${salonId}`, userId);
  const kartes = useKartes(userId);

  if (customer === null) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  const LeftContent: React.FC = () => <ArrowLeft onClick={() => history.goBack()} />;
  const RightContent: React.FC = () => (
    <Link to={`/customer/edit/${userId}`}>
      <RightContentItem>編集</RightContentItem>
    </Link>
  );

  return (
    <div>
      <Header
        title={`${customer.kanji_first_name}${customer.kanji_last_name}`}
        RightContent={RightContent}
        LeftContent={LeftContent}
      />
      <MainContainer>
        <MenuContent>{`お客様ID：${customer.id}`}</MenuContent>
        <Tel href={`tel:${customer.phone_number}`}>{customer.phone_number}</Tel>
        <MenuContent>{`生年月日：${customer.birth_day}`}</MenuContent>
        <MenuContent>{`総来店回数：${customer.salon_visit_count}`}</MenuContent>
        <LinkContent to={`/customer/detail/${userId}/friend_introduction`}>
          友達紹介履歴
          <ArrowRight />
        </LinkContent>
        {customer.memo && <Memo>{customer.memo}</Memo>}
        {kartes.map((it) => (
          <MedicalRecord to={`/customer/medical_record/detail/${it.id}`} key={it.id}>
            <Thumbnail src={it.thumbnail} />
            <MedicalRecordContent>
              <Date>{it.date}</Date>
              <ArrowRight />
            </MedicalRecordContent>
          </MedicalRecord>
        ))}
      </MainContainer>
      <ReservationLink to="/reservation/create">新規予約</ReservationLink>
      <HoverButton onClick={() => history.push('/customer/medical_record/add')}>
        <Plus />
      </HoverButton>
      <Footer isColored="CUSTOMER" />
    </div>
  );
};
