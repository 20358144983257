import {useState} from 'react';
import {Spinner} from '../../components/common/Spinner';

export const useSpinner = () => {
  const [loading, setLoading] = useState<typeof Spinner | null>(null);

  const finishLoading = () => {
    setLoading(null);
  };

  const startLoading = () => {
    setLoading(Spinner);
  };

  return {loading, finishLoading, startLoading};
};
