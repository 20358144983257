import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{padding: string}>`
  padding: ${({padding}) => padding || 0};
`;

type Props = {
  padding: string;
};

export const Padding: React.FC<Props> = ({padding, children}) => <Wrapper padding={padding}>{children}</Wrapper>;
