import React, {useState, useContext} from 'react';
import styled from 'styled-components';
import useForm from 'react-hook-form';
import {useHistory} from 'react-router';
import {ReactComponent as Lock} from '../../../image/lock.svg';
import {ReactComponent as Mail} from '../../../image/mail.svg';
import {ReactComponent as EyeOff} from '../../../image/eye-off.svg';
import {ReactComponent as Eye} from '../../../image/eye.svg';
import {AccountContext} from '../../../lib/contexts/account';
import {Margin} from '../../common/Margin';
import {ValidationErrorMessage} from '../../common/ValidationErrorMessage';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 40px 0;
`;

const Title = styled.p`
  margin: 200px 0 128px;
  font-family: Bitter;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 291px;
`;

const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TextField = styled.input`
  flex-grow: 1;
  margin-left: 5px;
  height: 24px;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
`;

const EyeOffButton = styled.button`
  height: 24px;
  background: #fff;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.87);
`;

const Button = styled.button`
  height: 45px;
  font-size: 15px;
  font-weight: bold;
  margin-top: 10px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 35px;
`;

const PasswordForgot = styled.button`
  color: #3478f6;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  margin-top: 8px;
  border: none;
  background: #fff;
  float: right;
`;

type FormValue = {
  email: string;
  password: string;
};

export const Login = () => {
  const {register, handleSubmit} = useForm<FormValue>({
    defaultValues: {
      email: 'test1@test.com',
      password: 'pass1234',
    },
  });
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<string | null>(null);
  const {login, mountAccount} = useContext(AccountContext);
  const history = useHistory();

  const onSubmit = handleSubmit((data, e) => {
    e.preventDefault();

    login(data)
      .then(() => {
        mountAccount();
        history.push('/');
      })
      .catch(() => {
        setIsFailed('ログインに失敗しました');
      });
  });

  return (
    <Wrapper>
      <Title>MIRROR BALL</Title>
      <Form onSubmit={onSubmit}>
        <Margin margin="0 0 40px 0">
          <TextFieldWrapper>
            <Mail />
            <TextField name="email" ref={register} placeholder="Mail address" type="email" />
          </TextFieldWrapper>
        </Margin>
        <Margin margin="0 0 40px 0">
          <TextFieldWrapper>
            <Lock />
            <TextField
              name="password"
              type={isShowPassword ? 'text' : 'password'}
              ref={register}
              placeholder="Password"
            />
            <EyeOffButton type="button" onClick={() => setIsShowPassword(!isShowPassword)}>
              {isShowPassword ? <Eye /> : <EyeOff />}
            </EyeOffButton>
          </TextFieldWrapper>
          <PasswordForgot type="button" onClick={() => history.push('/password_forgot')}>
            パスワードを忘れた方はこちら
          </PasswordForgot>
        </Margin>
        <Margin margin="0 auto">
          <ValidationErrorMessage message={isFailed} />
        </Margin>
        <Button type="submit">ログイン</Button>
      </Form>
    </Wrapper>
  );
};
