import React, {FC, useState, useCallback, useMemo, useContext, useEffect} from 'react';
import * as H from 'history';
import styled from 'styled-components';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {Margin} from '../../common/Margin';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {List} from '../../parts/List';
import {Color} from '../../../style/variable';
import {getGoalsOfYear, GoalData} from '../../../lib/api/sale';
import {AccountContext} from '../../../lib/contexts/account';

type Props = {
  history: H.History;
};

type Direction = 'right' | 'left';

const HeaderContentWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;
`;

const LeftContent: FC<{history: H.History<any>}> = ({history}) => (
  <HeaderContentWrapper type="button" onClick={() => history.push('/sales')}>
    <ArrowLeft />
  </HeaderContentWrapper>
);

const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
`;

const WrapperNavi = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 14px;
`;

const WrapperMonth = styled.div`
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
`;

const WrapperGoals = styled.div`
  width: 100%;
`;

const TextAmount = styled.div`
  color: ${Color.GRAY_LIGHT};
`;

const WrapperListItem = styled.div`
  width: calc(100% - 50px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
`;

export const SalesGoal: FC<Props> = ({history}) => {
  const {account} = useContext(AccountContext);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [goals, setGoals] = useState<GoalData[]>([]);
  console.log(goals);

  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);

  const selectedYear = useMemo(() => {
    return selectedDate.getFullYear();
  }, [selectedDate]);

  useEffect(() => {
    if (salonId) {
      getGoalsOfYear(salonId, selectedYear)
        .then(({data}) => {
          setGoals(data.data);
        })
        .catch((e) => {
          alert('エラーが発生しました');
        });
    }
  }, [salonId, selectedYear]);

  const handleClickNavi = useCallback(
    (direction: Direction) => () => {
      setSelectedDate((prev) => {
        const newValue = new Date(prev);
        if (direction === 'right') {
          newValue.setFullYear(newValue.getFullYear() + 1);
        } else {
          newValue.setFullYear(newValue.getFullYear() - 1);
        }
        return newValue;
      });
    },
    [],
  );

  return (
    <>
      <Header title="目標設定" LeftContent={() => <LeftContent history={history} />} />
      <MainContainer>
        <Margin margin="32px 16px 8px 16px">
          <WrapperNavi>
            <ArrowLeft onClick={handleClickNavi('left')} />
            <WrapperMonth>{selectedYear}</WrapperMonth>
            <ArrowRight onClick={handleClickNavi('right')} />
          </WrapperNavi>
          <WrapperGoals>
            <List withVerticalMargin="30px" withHeadLine={false}>
              {goals.map((item) => {
                return (
                  <List.Item key={item.month} to={`/sales/goal/${selectedYear}-${item.month}`}>
                    <WrapperListItem>
                      <div>{item.month}</div>
                      <TextAmount>{`${`¥${item.target.toLocaleString()}`}`}</TextAmount>
                    </WrapperListItem>
                  </List.Item>
                );
              })}
            </List>
          </WrapperGoals>
        </Margin>
      </MainContainer>
      <Footer isColored="MY_PAGE" />
    </>
  );
};
