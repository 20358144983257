import React, {useRef} from 'react';
import styled from 'styled-components';
import {Link} from '../../../common/Link';
import {ReactComponent as Plus} from '../../../../image/plus.svg';
import {ReactComponent as Search} from '../../../../image/search.svg';
import {ReactComponent as SearchCross} from '../../../../image/search_cross.svg';
import useMediaQuery, {BreakPoint, WidthCriteria} from '../../../../lib/hooks/useMedisQuery';

const Wrapper = styled.div<{isPC: boolean}>`
  position: fixed;
  top: ${({isPC}) => (isPC ? 'calc(calc(100vh - 80vh) / 2)' : '0')};
  height: 48px;
  max-width: 560px;
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 36px 16px 16px;
  z-index: 10;
`;

const SearchInput = styled.input`
  border: none;
  font-size: 16px;
  transform: scale(0.8);
  width: 80%;
`;

const PlusIcon = styled(Plus)`
  margin-left: 21px;
`;

const SearchIcon = styled(Search)`
  margin: 0px 10px;
`;

const SearchCrossIcon = styled(SearchCross)`
  margin-right: 16px;
`;

const SearchArea = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  height: 32px;
`;

const LinkArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CancelArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  margin-left: 12px;
`;

const CancelText = styled.div`
  color: #3c80f5;
`;

type Props = {
  searchInput: string;
  setSearchInput: React.Dispatch<React.SetStateAction<string>>;
  isTyping: boolean;
  setIsTyping: React.Dispatch<React.SetStateAction<boolean>>;
};

const HeaderWithSearch: React.FC<Props> = ({searchInput, setSearchInput, isTyping, setIsTyping}) => {
  const inputEl = useRef<HTMLInputElement | null>(null);
  const isPC = useMediaQuery(BreakPoint.mobile, WidthCriteria.min);

  const handleClick = () => {
    inputEl!.current!.focus();
    setIsTyping(true);
  };

  const handleCancelClick = () => {
    setSearchInput('');
    setIsTyping(false);
  };

  return (
    <Wrapper isPC={isPC}>
      <SearchArea onClick={handleClick}>
        <SearchIcon />
        <SearchInput
          ref={inputEl}
          type="text"
          value={searchInput}
          placeholder="検索"
          onChange={(e) => setSearchInput(e.target.value)}
        />
        {searchInput !== '' && <SearchCrossIcon onClick={handleCancelClick} />}
      </SearchArea>
      {isTyping || searchInput !== '' ? (
        <CancelArea>
          <CancelText onClick={handleCancelClick}>キャンセル</CancelText>
        </CancelArea>
      ) : (
        <LinkArea>
          <Link to="/customer/add">
            <PlusIcon />
          </Link>
        </LinkArea>
      )}
    </Wrapper>
  );
};

export default HeaderWithSearch;
