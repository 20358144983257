/* eslint no-nested-ternary: off */
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import {Waypoint} from 'react-waypoint';
import Header from '../../common/Header';
import MainContainer from '../../common/MainContainer';
import {BackButton} from '../../parts/BackButton';
import {ReactComponent as Plus} from '../../../image/plus_circle.svg';
import {Margin} from '../../common/Margin';
import {FontSize} from '../../../style/variable';
import {Link} from '../../common/Link';
import useMediaQuery, {BreakPoint, WidthCriteria} from '../../../lib/hooks/useMedisQuery';
import {getBlogs, BlogsType} from '../../../lib/api/blog';

const HoverButton = styled.button<{isPC: boolean}>`
  border-radius: 26px;
  position: fixed;
  bottom: ${({isPC}) => (isPC ? 'calc(calc(100vh - 80vh) / 2 + 68px)' : '68px')};
  right: ${({isPC}) => (isPC ? 'calc(calc(100vw - 560px) / 2)' : '16px')};
  background: #fff;
  border: none;
  height: 72px;
`;

const CenterContent = styled.div`
  margin: 48px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ListItem = styled(Link)`
  height: 102px;
  padding: 16px 0;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e8e8e8;
`;

const Img = styled.img`
  height: 70px;
  width: 70px;
  border-radius: 4px;
  object-fit: cover;
  border: 1px solid #c4c4c4;
`;

const Title = styled.p`
  font-size: ${FontSize.X_SMALL};
  line-height: 22px;
`;

const Author = styled.p`
  color: #696969;
  font-size: ${FontSize.X_SMALL};
`;

const Date = styled.div`
  color: #696969;
  font-size: ${FontSize.X_SMALL};
`;

const MainContent = styled.div`
  width: calc(100% - 70px);
  margin-left: 8px;
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 4px;
`;

export const Blog = () => {
  const [blogs, setBlogs] = useState<BlogsType | null>(null);
  const isPC = useMediaQuery(BreakPoint.mobile, WidthCriteria.min);

  const LeftContent: React.FC = () => <BackButton path="/" />;
  const history = useHistory();
  // console.log(currentPage);

  // const handleEnter = () => {
  //   console.log('ok');
  //   if (currentPage !== null) {
  //     startLoading();
  //     setTimeout(() => {
  //       getBlogs(currentPage + 1)
  //         .then(({ data }) => {
  //           if (blogs) {
  //             const nextBlogs = blogs.concat(data.data);
  //             setBlogs(nextBlogs);
  //             if (data.data.length === 0) {
  //               setCurrentPage(null);
  //             } else {
  //               setCurrentPage(currentPage + 1);
  //             }
  //           }
  //         })
  //         .finally(() => {
  //           finishLoading();
  //         });
  //     }, 1000);
  //   }
  // };

  useEffect(() => {
    getBlogs().then(({data}) => {
      setBlogs(data.data);
    });
  }, []);

  return (
    <div>
      <Header title="STYLIST BLOG" LeftContent={LeftContent} />
      <MainContainer>
        {blogs ? (
          blogs.length === 0 ? (
            <CenterContent>アイテムがありません</CenterContent>
          ) : (
            blogs.map((blogItem) => (
              <Margin margin="0 16px" key={blogItem.id}>
                <ListItem to={`/blog/detail/${blogItem.id}`}>
                  <Img src={blogItem.image_url} width="70" height="70" />
                  <MainContent>
                    <Title>{blogItem.title}</Title>
                    <BottomContent>
                      <Author>{blogItem.author}</Author>
                      <Date>{blogItem.created_at.slice(0, 10)}</Date>
                    </BottomContent>
                  </MainContent>
                </ListItem>
              </Margin>
            ))
          )
        ) : null}
        {/* <div>{loading ? <CenterContent>{loading}</CenterContent> : <Waypoint onEnter={handleEnter} />}</div> */}
      </MainContainer>
      <HoverButton onClick={() => history.push('/blog/create')} isPC={isPC}>
        <Plus />
      </HoverButton>
      {/* <Footer isColored="MY_PAGE" /> */}
    </div>
  );
};
