const katakanaToHiragana = (src: any) => {
  return src.replace(/[\u30a1-\u30f6]/g, (match: string) => {
    const chr = match.charCodeAt(0) - 0x60;
    return String.fromCharCode(chr);
  });
};

export const sortCharArray = (arr: {name: string; id: string}[]) => {
  arr.sort((a, b) => {
    const a2 = katakanaToHiragana(a.name.toString());
    const b2 = katakanaToHiragana(b.name.toString());

    if (a2 < b2) {
      return -1;
    }
    if (a2 > b2) {
      return 1;
    }
    return 0;
  });

  return arr;
};
