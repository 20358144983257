import {useMedia} from 'react-use';

export const BreakPoint = {
  mobile: 560,
};
export type BreakPoint = typeof BreakPoint[keyof typeof BreakPoint];

export const WidthCriteria = {
  max: 'max-width',
  min: 'min-width',
};
export type WidthCriteria = typeof WidthCriteria[keyof typeof WidthCriteria];

export default (breakpoint: BreakPoint, criteria: WidthCriteria): boolean => {
  const emSize = breakpoint / 16;
  const result = useMedia(`(${criteria}: ${emSize}em)`);

  return result;
};
