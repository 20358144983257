import React, {useContext, useMemo} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import styled, {css} from 'styled-components';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {BackButton} from '../../parts/BackButton';
import {Space, FontSize} from '../../../style/variable';
import {useIntroductions} from '../../../lib/hooks/customer';
import {AccountContext} from '../../../lib/contexts/account';
import {Spinner} from '../../common/Spinner';
import {Margin} from '../../common/Margin';

const Wrapper = styled.div`
  padding: 16px 0px;
`;

const Label = styled.p`
  font-size: ${FontSize.X_SMALL};
  margin-bottom: 16px;
`;
const Text = css`
  display: flex;
  flex-flow: column;
  align-items: baseline;
  padding: 16px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;
const DateArea = styled.div`
  ${Text}
`;

const NameArea = styled.div`
  ${Text}
`;

const StaffNameArea = styled.div`
  ${Text}
`;

const TextItem = styled.p`
  color: rgba(0, 0, 0, 0.87);
`;

const Driver = styled.div`
  margin: 24px 0px 8px;
  border: 4px solid #fafafa;
`;

type Props = RouteComponentProps<{id: string}>;
export const FriendIntroduction: React.FC<Props> = ({match}) => {
  const userId = match.params.id;
  const {account} = useContext(AccountContext);
  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);

  const introductions = useIntroductions(`${salonId}`, userId);

  if (introductions === null) {
    return <Spinner />;
  }

  const LeftContent: React.FC = () => <BackButton />;

  const invitedElm = introductions.invited_users.map((it) => (
    <div key={it.id}>
      <DateArea>
        <Label>来店日時</Label>
        <TextItem>{it.last_visit_time}</TextItem>
      </DateArea>
      <NameArea>
        <Label>紹介した</Label>
        <TextItem>{`${it.kanji_first_name}${it.kana_last_name}`}</TextItem>
      </NameArea>
      <StaffNameArea>
        <Label>担当</Label>
        <TextItem>{it.person_in_charge_name}</TextItem>
      </StaffNameArea>
      <Driver />
    </div>
  ));

  const user = introductions.be_invited_by_user || undefined;

  return (
    <Wrapper>
      <Header title="友達紹介" LeftContent={LeftContent} />
      <MainContainer marginHorizontal={Space * 2}>
        <NameArea>
          <Label>紹介された</Label>
          {user && <TextItem>{`${user.kanji_first_name}${user.kana_last_name}`}</TextItem>}
        </NameArea>
        <Margin margin="40px 0px -16px 0px">
          <Label>紹介した</Label>
        </Margin>
        {invitedElm}
      </MainContainer>
      <Footer isColored="MY_PAGE" />
    </Wrapper>
  );
};
