import React from 'react';
import styled from 'styled-components';
import {Color, FontSize, TextAlign, FontWeight, LineHeight} from '../../../style/variable';

const Wrapper = styled('div')<{
  block: boolean;
  color: Color;
  size: FontSize;
  weight: FontWeight;
  align: TextAlign;
  lineHeight: LineHeight;
}>`
  display: ${({block}) => (block ? 'block' : 'inline-block')};
  color: ${({color}) => color};
  text-align: ${({align}) => align};
  font-size: ${({size}) => size};
  font-weight: ${({weight}) => weight};
  line-height: ${({lineHeight}) => lineHeight};
`;

type Props = React.HTMLAttributes<HTMLElement> & {
  component?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  block?: boolean;
  color?: Color;
  align?: TextAlign;
  size?: FontSize;
  weight?: FontWeight;
  lineHeight?: LineHeight;
};

export const Text: React.FC<Props> = ({
  component,
  block,
  color,
  size,
  weight,
  align,
  lineHeight,
  children,
  ...rest
}) => (
  <Wrapper
    {...rest}
    as={component as any}
    block={block as boolean}
    color={color as Color}
    size={size as FontSize}
    weight={weight as FontWeight}
    align={align as TextAlign}
    lineHeight={lineHeight as LineHeight}
  >
    {children}
  </Wrapper>
);

Text.defaultProps = {
  component: 'span',
  block: false,
  color: Color.BLACK,
  size: FontSize.MEDIUM,
  weight: FontWeight.NORMAL,
  align: TextAlign.CENTER,
  lineHeight: LineHeight.MEDIUM,
};
