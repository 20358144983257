import React from 'react';
import styled from 'styled-components';
import {Item} from './Item';
import {Divider} from '../../common/Divider';

const Wrapper = styled.ul<{withHorizontalMargin: string; withVerticalMargin: string}>`
  margin: ${({withHorizontalMargin, withVerticalMargin}) => `${withVerticalMargin} ${withHorizontalMargin}`};
  text-align: center;
  list-style-type: none;
`;

type Props = {
  children: React.ReactNode;
  withHeadLine?: boolean;
  withBottomLine?: boolean;
  withHorizontalMargin?: string;
  withVerticalMargin?: string;
};

export const List = ({withHeadLine, withBottomLine, withHorizontalMargin, withVerticalMargin, children}: Props) => {
  const listLength = React.Children.count(children);
  return (
    <Wrapper withHorizontalMargin={withHorizontalMargin as any} withVerticalMargin={withVerticalMargin as any}>
      {withHeadLine ? <Divider /> : null}
      {React.Children.map(children, (child, index) => (
        <>
          {child}
          {index + 1 === listLength ? null : <Divider />}
        </>
      ))}
      {withBottomLine ? <Divider /> : null}
    </Wrapper>
  );
};

List.defaultProps = {
  withHeadLine: true,
  withBottomLine: true,
  withHorizontalMargin: '16px',
  withVerticalMargin: '32px',
};

List.Item = Item;
