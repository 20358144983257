import * as React from 'react';
import styled from 'styled-components';
import {Color} from '../../../../style/variable';
import {DateCell} from './Date';

type ShiftLineProps = {
  date: Date;
};
const ShiftLine = (props: ShiftLineProps) => {
  const {date} = props;

  return (
    <ShiftLineWrapper>
      <DateCell date={date} />
      <ShiftCell shiftStatus="filled" />
      <ShiftCell shiftStatus="vacant" />
      <ShiftCell shiftStatus="vacant" />
      <ShiftCell shiftStatus="vacant" />
      <ShiftCell shiftStatus="vacant" />
      <ShiftCell shiftStatus="vacant" />
      <ShiftCell shiftStatus="filled" />
      <ShiftCell shiftStatus="filled" />
      <ShiftCell shiftStatus="filled" />
      <ShiftCell shiftStatus="filled" />
      <ShiftCell shiftStatus="filled" />
      <ShiftCell shiftStatus="filled" />
      <ShiftCell shiftStatus="filled" />
      <ShiftCell shiftStatus="filled" />
      <ShiftCell shiftStatus="filled" />
      <ShiftCell shiftStatus="filled" />
    </ShiftLineWrapper>
  );
};

const ShiftLineWrapper = styled.div`
  width: calc(100% / 7);
`;

type ShiftStatus = 'filled' | 'vacant';
type ShiftCellProps = {
  shiftStatus: ShiftStatus;
};
const ShiftCell = (props: ShiftCellProps) => {
  const {shiftStatus} = props;

  return (
    <ShiftCellWrapper>
      {(() => {
        if (shiftStatus === 'filled') {
          return <VacantShift />;
        }
        if (shiftStatus === 'vacant') {
          return <FilledShift />;
        }
        return <></>;
      })()}
    </ShiftCellWrapper>
  );
};

const ShiftCellWrapper = styled.div`
  width: 100%;
  height: 36px;
  border: 1px solid ${Color.GRAY_BORDER};
  border-right: none;
  border-bottom: none;
  cursor: pointer;
`;

const VacantShift = () => <VacantShiftStyle>◯</VacantShiftStyle>;

const VacantShiftStyle = styled.div`
  color: ${Color.RED};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilledShift = () => <FilledShiftStyle>×</FilledShiftStyle>;

const FilledShiftStyle = styled.div`
  color: ${Color.BLACK};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {ShiftLine};
