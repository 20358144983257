import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {useHistory, RouteComponentProps} from 'react-router';
import useForm from 'react-hook-form';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import {FontSize} from '../../../style/variable';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {MedicalRecordForm} from '../../parts/Form/MedicalRecord/MedicalRecordForm';
import MaterialForm from '../../parts/Form/MedicalRecord/MaterialForm';
import RecommendItemForm from '../../parts/Form/MedicalRecord/RecommendItemForm';
import {Spinner} from '../../common/Spinner';
import {toUpdateKarteType, useKarte} from '../../../lib/hooks/kartes';
import {KarteDetailType, MaterialType, RecommendType, updateKarte} from '../../../lib/api/karte';

const RightContentItem = styled.button`
  background: #fff;
  border: none;
  color: rgba(249, 87, 56, 0.87);
  font-size: ${FontSize.CONTROL_MEDIUM};
`;

const Material = styled.p`
  font-size: ${FontSize.MEDIUM};
  color: rgba(0, 0, 0, 0.56);
  margin-right: 16px;
`;

const RecommendItem = styled.p`
  font-size: ${FontSize.MEDIUM};
  color: rgba(0, 0, 0, 0.56);
  margin-right: 16px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export type ViewStyle = 'Form' | 'MaterialForm' | 'RecommendItemForm';

type Props = RouteComponentProps<{id: string}>;
export const MedicalRecordUpdate: React.FC<Props> = ({match}) => {
  const history = useHistory();
  const karteId = match.params.id;
  const karte = useKarte(karteId);
  const [medicalRecord, setMedicalRecord] = useState<KarteDetailType | null>(karte);
  const [viewType, setViewType] = useState<ViewStyle>('Form');
  useEffect(() => {
    setMedicalRecord(karte);
  }, [karte]);
  if (karte === null || medicalRecord === null) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  const updateForm = (newValues: Partial<KarteDetailType>) => {
    setMedicalRecord({...medicalRecord, ...newValues});
  };

  const onSubmit = () => {
    const update = toUpdateKarteType(medicalRecord);
    updateKarte(karteId, update).then(() => {
      history.push(`/customer/medical_record/detail/${karteId}`);
    });
  };
  const RightContent: React.FC = () => <RightContentItem onClick={onSubmit}>完了</RightContentItem>;
  const LeftContent: React.FC = () => <ArrowLeft onClick={() => history.goBack()} />;

  const content = (() => {
    switch (viewType) {
      case 'MaterialForm':
        return (
          <MaterialForm
            setViewType={setViewType}
            materials={medicalRecord.materials}
            setMaterials={(materials: MaterialType[]) => updateForm({materials})}
          />
        );
      case 'RecommendItemForm':
        return (
          <RecommendItemForm
            setViewType={setViewType}
            setRecommendItem={(recommend: RecommendType) => updateForm({recommend})}
          />
        );
      default:
        return (
          <>
            <Header title="カルテの編集" RightContent={RightContent} LeftContent={LeftContent} />
            <MainContainer>
              <MedicalRecordForm setViewType={setViewType} medicalRecord={medicalRecord} updateForm={updateForm} />
            </MainContainer>
          </>
        );
    }
  })();

  return <div>{content}</div>;
};
