import React, {FC, useState, useCallback, useContext, useEffect, useMemo} from 'react';
import * as H from 'history';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {Margin} from '../../common/Margin';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {Color} from '../../../style/variable';
import {TextCustom} from './TextCustom';
import {ModalReason} from './ModalReason';
import {Invoice, getInvoiceDetail, putRemand, putAccept} from '../../../lib/api/invoice';
import {AccountContext} from '../../../lib/contexts/account';

type Props = {
  history: H.History;
};

const HeaderContentWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;
`;

const LeftContent: FC<{history: H.History<any>}> = ({history}) => (
  <HeaderContentWrapper type="button" onClick={() => history.push('/sales/invoice/not_approved')}>
    <ArrowLeft />
  </HeaderContentWrapper>
);

const WrapperSection = styled.div`
  margin-bottom: 0.5rem;
  padding: 1rem;
  font-size: 14px;
  background: ${Color.WHITE};
`;

const TotalRow = styled.div<{isTotal?: boolean}>`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  font-size: 14px;
  font-weight: ${({isTotal}) => (isTotal ? 'bold' : 'normal')};
`;

const RowSecond = styled.div`
  width: 100%;
  display: flex;
  justify-content: justify-content;
  align-items: center;
  .TextCustom {
    margin-right: 0.5rem;
  }
`;

const Border = styled.div`
  width: 100%;
  height: 1px;
  margin-bottom: 0.5rem;
  background: #979797;
`;

const ButtonApprove = styled.button`
  width: 100%;
  height: 3rem;
  text-align: center;
  background: ${Color.ORANGE};
  color: ${Color.WHITE};
  border: none;
  font-size: 18px;
  border-radius: 4px;
`;

const ButtonNotApprove = styled.button`
  width: 100%;
  height: 3rem;
  margin-bottom: 4rem;
  background: ${Color.WHITE};
  text-align: center;
  border: none;
  font-size: 18px;
  border-radius: 4px;
`;

export const SalesInvoiceActive: FC<Props> = ({history}) => {
  const {account} = useContext(AccountContext);
  const {id} = useParams<{id: string}>();

  const [invoice, setInvoice] = useState<Invoice | null>(null);

  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);

  useEffect(() => {
    if (salonId) {
      getInvoiceDetail(salonId, id)
        .then(({data}) => {
          setInvoice(data);
        })
        .catch((e) => {
          alert('エラーが発生しました');
        });
    }
  }, [salonId, id]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClickApprove = useCallback(async () => {
    if (salonId) {
      try {
        await putAccept(salonId, id);
      } catch (e) {
        alert('エラーが発生しました');
      }
    }
    setIsModalOpen(true);
    history.replace('/sales/invoice/not_approved');
  }, [id, salonId, history]);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleClickSend = useCallback(
    async (reason: string) => {
      if (salonId) {
        try {
          await putRemand(salonId, id, reason);
        } catch (e) {
          alert('エラーが発生しました');
        }
      }
      setIsModalOpen(false);
    },
    [id, salonId],
  );

  const handleClickCancel = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <>
      <Header
        title={invoice && invoice.name ? invoice.name : '請求書発行'}
        LeftContent={() => <LeftContent history={history} />}
      />
      <MainContainer>
        <div style={{background: '#FAFAFA'}}>
          {invoice && (
            <Margin margin="32px 0 8px 0">
              <WrapperSection>
                <TextCustom label="請求日" value={invoice.apply_date} />
              </WrapperSection>
              <WrapperSection>
                <TotalRow>
                  <div>小計</div>
                  <div>{`￥${invoice.total_price.toLocaleString()}`}</div>
                </TotalRow>
                <TotalRow>
                  <div>消費税</div>
                  <div>{`￥${invoice.tax.toLocaleString()}`}</div>
                </TotalRow>
                <Border />
                <TotalRow isTotal>
                  <div>合計</div>
                  <div>{`￥${invoice.payment_amount.toLocaleString()}`}</div>
                </TotalRow>
              </WrapperSection>
              {invoice.data.map((item) => {
                return (
                  <WrapperSection key={item.id}>
                    <TextCustom label="概要" value={item.title} />
                    <RowSecond>
                      <TextCustom label="数量" value={`￥${item.amount.toLocaleString()}`} />
                      <TextCustom label="単価" value={`￥${item.unit_price.toLocaleString()}`} />
                      <TextCustom label="金額" value={`￥${item.payment_amount.toLocaleString()}`} />
                    </RowSecond>
                  </WrapperSection>
                );
              })}
              {invoice.status === 0 && (
                <>
                  <Margin margin="32px 16px 8px 16px">
                    <ButtonApprove onClick={handleClickApprove}>承認</ButtonApprove>
                  </Margin>
                  <Margin margin="16px 16px 8px 16px">
                    <ButtonNotApprove onClick={openModal}>未承認</ButtonNotApprove>
                  </Margin>
                </>
              )}
            </Margin>
          )}
        </div>
        {isModalOpen && <ModalReason onClickSend={handleClickSend} onClickCancel={handleClickCancel} />}
      </MainContainer>
      <Footer isColored="MY_PAGE" />
    </>
  );
};
