import * as React from 'react';
import styled from 'styled-components';
import {Color} from '../../../../style/variable';
import {Label as LabeComponent} from './Label';

const Input = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid ${Color.GRAY_BORDER};
  font-size: 16px;
  line-height: 20px;
  padding: 8px 0;
  width: 100%;
`;

type TextInputProps = {
  label: string;
  placeholder?: string;
  name: string;
  register: any;
};

const TextInput = (props: TextInputProps) => {
  const {label, placeholder, name, register} = props;

  return (
    <div>
      <div>
        <LabeComponent>{label}</LabeComponent>
      </div>
      <div>
        <Input name={name} ref={register} placeholder={placeholder} />
      </div>
    </div>
  );
};

export {TextInput};
