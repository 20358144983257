import apiBase from './apiBase';

export type Coupon = {
  id: number;
  stylist_id: number;
  salon_id: number;
  image_url: string;
  name: string;
  price: number;
  minute: number;
  description: string;
  reward_point: number;
  repair_cost: number;
  is_public: number;
  menus: CouponMenu[];
};

export type CouponMenu = {
  id: number;
  salon_id: number;
  category_id: number;
  name: string;
  description: string;
  price: number;
  repair_cost: number;
  minute: number;
  reward_point: number;
  is_public: number;
  image_url: string;
};

type PostCouponReq = {
  salon_id: number;
  name: string;
  price: number;
  minute: number;
  description: string;
  menu_ids: number[];
  image: {
    base64?: string;
    image_url?: string;
  };
};

export const getCouponCategories = (salonId: number) =>
  apiBase.get<CouponMenu[]>(`/salon/${salonId}/service/categories`);

export const getCoupons = () => apiBase.get<Coupon[]>(`/me/coupons`);

export const getCouponDetail = (couponId: string) => apiBase.get<Coupon>(`/me/coupon/${couponId}`);

export const postCoupon = (params: PostCouponReq) => apiBase.post<Coupon>(`/me/coupon`, params);

export const deleteCoupon = (couponId: string) => apiBase.delete<Coupon>(`/me/coupon/${couponId}`);
