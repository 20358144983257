import React, {FC} from 'react';
import styled from 'styled-components';
import {Color} from '../../../style/variable';

const MainWrapper = styled.div`
  width: 100%;
`;

const Label = styled.div`
  margin-bottom: 0.5rem;
  font-size: 12px;
  color: ${Color.GRAY_LIGHT};
`;

const Row = styled.div`
  width: 100%;
  height: 2rem;
  margin-bottom: 0.5rem;
  border: none;
  border-bottom: 1px solid #979797;
  font-weight: 500;
  font-size: 16px;
  text-align: right;
`;

export const TextCustom: FC<{
  value: string;
  label: string;
}> = ({value, label}) => {
  return (
    <MainWrapper className="TextCustom">
      <Label>{label}</Label>
      <Row>{value}</Row>
    </MainWrapper>
  );
};
