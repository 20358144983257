import React from 'react';
import styled from 'styled-components';
import {Link as DomLink} from 'react-router-dom';

const LinkWrapper = styled(DomLink)`
  text-decoration: none;
  color: inherit;
`;

type Props = {
  to: any;
};

export const Link: React.FC<Props> = ({to, children, ...rest}) => (
  <LinkWrapper {...rest} to={to}>
    {children}
  </LinkWrapper>
);
