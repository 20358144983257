import React from 'react';
import styled from 'styled-components';
import {ReactComponent as Plus} from '../../../image/plus.svg';

const AddImageLabel = styled.label`
  height: 58px;
  min-width: 71px;
  border: 1px solid rgba(249, 87, 56, 0.56);
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type Props = {
  onChange: (file: File) => void;
};

export const AddImage: React.FC<Props> = ({onChange}) => {
  const randomId = Math.random()
    .toString(36)
    .substring(7);
  return (
    <>
      <input id={randomId} type="file" onChange={(e: any) => onChange(e.target.files![0])} style={{display: 'none'}} />
      <AddImageLabel htmlFor={randomId}>
        <Plus />
      </AddImageLabel>
    </>
  );
};
