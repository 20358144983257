import axios from 'axios';
import apiBase from './apiBase';
import configuration from '../config';

export type AccountType = {
  id: string;
  user_name: string;
  user_kana_name: string;
  email: string;
  email_verified_at: string | null;
  image_url: string;
  contract_type: string;
  position: string;
  grade: string;
  main_salon_id: number;
  sub_salon_id: number;
  phone_number: string;
  emergency_phone_number: string;
  monthy_holiday_number: number;
  ec_feed_back_rate: number;
  commission_rate: number;
  treatment_sale_free_commission: number;
  treatment_sale_name_commission: number;
  product_sale_commission: number;
  hire_date: string;
  leave_date: string;
  years_of_experience: string;
  sex: string;
  naming_price: string;
  rank: string;
  catch_phrase: string;
  self_introduction: string;
  is_deleted: number;
  created_at: string;
  updated_at: string;
  bank_name: string;
  bank_number: number;
  branch_store_name: string;
  branch_store_number: number;
  bank_account_name: string;
  bank_account_type: string;
  bank_account_number: number;
};

export type LoginParams = {
  email: string;
  password: string;
};

export type LoginRes = {
  token: {
    access_token: string;
    refresh_token: string;
  };
  profile: {
    id: number;
    name: string;
    email: string;
  };
};

export type BankAccountType = {
  label: string;
};

export type PaymentInfo = {
  name: string;
  card_no: string;
  exp_year: string;
  exp_month: string;
  cvv: string;
};

export const login = (params: LoginParams) =>
  axios({
    method: 'POST',
    url: `${configuration.apiURL}/login`,
    data: params,
  });

export const logout = () => apiBase.post<any>('/logout');

export const getMe = () => apiBase.get<AccountType>('/me');

export type UpdateProfileParams = Partial<AccountType> &
  Partial<{
    image: {
      base64: string;
    };
  }>;

export const updateProfile = (params: UpdateProfileParams) => apiBase.put<AccountType>('/me', params);

export const getBankAccountTypes = () => apiBase.get<BankAccountType[]>('/me/bankAccount/types');

export const getPaymentInfo = () => apiBase.get<PaymentInfo>('/me/paymentInfo');

export const updatePaymentInfo = (params: Partial<PaymentInfo>) => apiBase.put<PaymentInfo>('/me/paymentInfo', params);

export const updateBankAccount = (params: UpdateProfileParams) => apiBase.put<AccountType>('/me/bankAccount', params);

export type PasswordResetParams = {
  token: string;
  email: string;
  password: string;
  password_confirmation: string;
};

export const passwordReset = (params: PasswordResetParams) => apiBase.post('/password/reset', params);

export const sendMailPasswordReset = (params: {email: string}) => apiBase.post('/password/email', params);

export const refreshToken = () => apiBase.get('/token/refresh');

export type PasswordResetAtLoginparams = {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
};

export const passwordResetAtLogin = (params: PasswordResetAtLoginparams) => apiBase.put('/me/password', params);
