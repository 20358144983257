import React from 'react';
import styled from 'styled-components';
import useForm from 'react-hook-form';
import {useHistory} from 'react-router';
import {Input, Layout, SubmitButton, Title} from './internal';
import {sendMailPasswordReset} from '../../../lib/api/auth';
import {ValidationErrorMessage} from '../../common/ValidationErrorMessage';
import {Margin} from '../../common/Margin';

const Text = styled.p`
  width: 295px;
  font-size: 14px;
  line-height: 17px;
  color: #242424;
  margin-bottom: 40px;
`;

export const PasswordForgot: React.FC = () => {
  const history = useHistory();
  const {register, handleSubmit, errors} = useForm<{
    email: string;
  }>({
    defaultValues: {
      email: '',
    },
  });
  const onSubmit = handleSubmit((value) => {
    sendMailPasswordReset(value)
      .then(() => {
        history.push('/email_sent');
      })
      .catch(() => {
        console.error('メール送信失敗');
        alert('メールの送信に失敗しました');
      });
  });

  return (
    <Layout>
      <Title>パスワードを忘れた方</Title>
      <Text>登録しているメールアドレスを入力して、本人確認メールを送信してください。</Text>
      <form onSubmit={onSubmit}>
        <Margin margin="0 0 40px 0">
          <Input
            text="メールアドレス"
            register={register({
              required: 'メールアドレスを入力してください',
              maxLength: 256,
              pattern: {
                value: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
                message: 'メールアドレスの形式が正しくありません',
              },
            })}
            name="email"
            placeholder="mirror@ball.com"
            type="email"
          />
          <ValidationErrorMessage message={errors.email && errors.email.message} />
        </Margin>
        <SubmitButton text="送信" />
      </form>
    </Layout>
  );
};
