import React from 'react';
import {useHistory} from 'react-router';
import {Layout, Title, HandleButton, Text} from './internal';

export const SentMail: React.FC = () => {
  const history = useHistory();

  return (
    <Layout>
      <Title>メールを確認してください</Title>
      <Text>
        exanple@MIRROBOALL.comにメールを送信しました。メールに記載されたリンクをクリックすると再設定できます。
        <br />
        メールが届かない場合、迷惑メールフォルダーなどもご確認ください。
      </Text>

      <HandleButton text="ログイン" handleClick={() => history.push('/login')} />
    </Layout>
  );
};
