import React from 'react';
import {SpinnerCircular} from 'spinners-react';

export const Spinner: React.FC = () => {
  return (
    <SpinnerCircular
      size={50}
      thickness={100}
      speed={200}
      color="rgba(249, 87, 56, 1)"
      secondaryColor="rgba(255, 255, 255, 1)"
    />
  );
};
