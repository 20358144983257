import React from 'react';
import {useHistory} from 'react-router';
import {Layout, Title, HandleButton, Text} from './internal';

export const PasswordSettingCompleted: React.FC = () => {
  const history = useHistory();

  return (
    <Layout>
      <Title>パスワード設定完了</Title>
      <Text>パスワードの再設定が完了しました。 「ログイン」へお進みください</Text>

      <HandleButton text="ログイン" handleClick={() => history.push('/login')} />
    </Layout>
  );
};
