import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';

const Button = styled.button`
  border: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 46px;
  position: relative;

  &::after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    width: 13px;
    height: 13px;
    border-right: 2px solid rgba(249, 87, 56, 0.8);
    border-bottom: 2px solid rgba(249, 87, 56, 0.8);
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
`;

type Props = {
  path?: string;
};

export const BackButton: React.FC<Props> = ({path}) => {
  const history = useHistory();
  return <Button onClick={() => (path ? history.push(path) : history.goBack())} />;
};
