import React, {FC, useCallback} from 'react';
import styled from 'styled-components';
import {ReactComponent as CarretDown} from '../../../image/carret_down.svg';
import {ShiftPattern} from '../../../lib/api/shift';

const WrapperSelect = styled.div`
  width: 100%;
  position: relative;
  .select-custom {
    width: 100%;
    height: 2rem;
    border: none;
    border-bottom: 1px solid #e0e0e0;
  }
  .carret-down {
    position: absolute;
    right: 0.4rem;
    top: 1rem;
  }
`;

export const SelectShiftPattern: FC<{
  options: ShiftPattern[];
  value: ShiftPattern | undefined;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}> = ({options, value, onChange}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      onChange(e);
    },
    [onChange],
  );

  return (
    <WrapperSelect>
      <select className="select-custom" onChange={handleChange} value={value ? value.pattern : ''} required>
        <option value="" disabled>
          選択してください
        </option>
        {options.map((item) => {
          return (
            <option key={item.pattern} value={item.pattern}>
              {item.pattern}
            </option>
          );
        })}
      </select>
      <CarretDown className="carret-down" />
    </WrapperSelect>
  );
};
