export enum FontSize {
  INHERIT = 'inherit',
  XX_SMALL = '0.5rem', // 8px
  X_SMALL = '0.75rem', // 12px
  SMALL = '0.87rem', // 14px
  MEDIUM = '1rem', // 16px
  LARGE = '1.125rem', // 18px
  X_LARGE = '1.25rem', // 20px
  TITLE = '1.5rem', // 24px
  TITLE_LARGE = '2.5rem', // 40px
  CONTROL_MEDIUM = '1rem', // 16px
  CONTROL_LARGE = '1.5rem', // 24px
}

export const Space = 8;

export enum Color {
  TRANSPARENT = 'transparent',
  BLACK = '#242424',
  BLACK_HOVER = '#646464',
  GRAY_DARK = '#666',
  GRAY_DARK_HOVER = '#949494',
  GRAY = '#c7c7cc',
  GRAY_LIGHT = 'rgba(0, 0, 0, 0.54)',
  GRAY_LIGHT_HOVER = '#d7d7d7',
  GRAY_LIGHTER = '#f9f9f9',
  GRAY_PALE = '#fafafa',
  GRAY_BORDER = '#e0e0e0',
  WHITE = '#fff',
  WHITE_HOVER = '#e5e5e5',
  PRIMARY_GREEN = '#00735b',
  PRIMARY_GREEN_HOVER = '#4d9d8c',
  GREEN_LIGHT = '#daf7f0',
  GREEN_LIGHT_HOVER = '#c6e0da',
  RED = '#ff223c',
  RED_HOVER = '#ff6476',
  RED_LIGHT = '#ffdee2',
  RED_BORDER = '#fa6f54',
  ORANGE = 'rgba(249, 87, 56, 0.87)',
  ORANGE_HOVER = '#ec8f73',
  ORANGE_LIGHT = 'rgba(249, 87, 56, 0.12)',
  SILVER = '#6b9abd',
  GOLD = '#b29745',
  PURPLE = '#8287b7',
  BORDER = 'rgba(0, 0, 0, 0.12)',
  APP_BORDER = '#ccc',
  APP_BACKGROUND = '#fff',
  LOGIN_BACKGROUND = '#ddd',
  FONT = 'rgba(0, 0, 0, 0.56)',
  ATTENTION = 'rgba(249, 87, 56, 0.87)',
  BLUE_LIGHT = 'rgba(53, 117, 174, 0.87);',
  BEIGE = '#FDD3CB',
}

export enum FontWeight {
  NORMAL = 'normal',
  BOLD = 'bold',
}

export enum TextAlign {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export enum LineHeight {
  INHERIT = 'inherit',
  X_SMALL = '1.1',
  SMALL = '1.3',
  MEDIUM = '1.5',
  LARGE = '1.7',
  X_LARGE = '1.8',
  XX_LARGE = '2.0',
}
