import {useState, useEffect} from 'react';
import {
  getKarte,
  getKartes,
  getMaterialCategories,
  getMaterials,
  getRecommendCategories,
  getRecommends,
  KarteDetailType,
  KarteType,
  MaterialCategoryType,
  MaterialType,
  ProductCategoryType,
  RecommendType,
  UpdateKarteType,
} from '../api/karte';

export const useKartes = (userId?: string) => {
  const [kartes, setKartes] = useState<KarteType[]>([]);

  useEffect(() => {
    if (userId === undefined) {
      setKartes([]);
    } else {
      getKartes(userId)
        .then(({data}) => {
          setKartes(data);
        })
        .catch((err) => {
          setKartes([]);
        });
    }
  }, []);

  return kartes;
};

export const useKarte = (karteId?: string) => {
  const [karte, setKarte] = useState<KarteDetailType | null>(null);

  useEffect(() => {
    if (karteId === undefined) {
      setKarte(null);
    } else {
      getKarte(karteId)
        .then(({data}) => {
          setKarte(data);
        })
        .catch((err) => {
          setKarte(null);
        });
    }
  }, []);

  return karte;
};

export const useMaterials = () => {
  const [materials, setMaterials] = useState<MaterialType[]>([]);

  useEffect(() => {
    getMaterials()
      .then(({data}) => {
        setMaterials(data);
      })
      .catch((err) => {
        setMaterials([]);
      });
  }, []);

  return materials;
};

export const useRecommends = () => {
  const [recommends, setRecommends] = useState<RecommendType[]>([]);

  useEffect(() => {
    getRecommends()
      .then(({data}) => {
        setRecommends(data);
      })
      .catch((err) => {
        setRecommends([]);
      });
  }, []);

  return recommends;
};

export const useMaterialCategories = () => {
  const [categories, setCategories] = useState<MaterialCategoryType[]>([]);

  useEffect(() => {
    getMaterialCategories()
      .then(({data}) => {
        setCategories(data);
      })
      .catch((err) => {
        setCategories([]);
      });
  }, []);

  return categories;
};

export const useProductCategories = () => {
  const [categories, setCategories] = useState<ProductCategoryType[]>([]);

  useEffect(() => {
    getRecommendCategories()
      .then(({data}) => {
        setCategories(data);
      })
      .catch((err) => {
        setCategories([]);
      });
  }, []);

  return categories;
};

export const toUpdateKarteType = (karte: KarteDetailType): UpdateKarteType => {
  return {
    karte_id: karte.id,
    images: karte.images,
    date: karte.date,
    material_ids: karte.materials.map((it) => it.id),
    recommend_id: karte.recommend ? karte.recommend.id : undefined,
    recommend_product_description: karte.recommend_product_description,
    ref_images: karte.ref_images,
    memo: karte.memo,
  };
};
