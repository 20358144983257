import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import Header from '../../common/Header';
import {Padding} from '../../common/Padding';
import MainContainer from '../../common/MainContainer';
import {Color} from '../../../style/variable';
import {InventoryDealerTable, InventoryOrderTable, MockDealer, MockOrder} from '../../parts/Inventory/Table';

const mockOrders: MockOrder[] = [
  {id: 'aaa', name: 'ホゲホゲ', basicAmount: 100, remainingAmount: 100, orderAmount: 0},
  {id: 'bbb', name: 'ホゲホゲ', basicAmount: 100, remainingAmount: 100, orderAmount: 0},
  {id: 'ccc', name: 'ホゲホゲ', basicAmount: 100, remainingAmount: 100, orderAmount: 0},
  {id: 'ddd', name: 'ホゲホゲ', basicAmount: 100, remainingAmount: 100, orderAmount: 0},
  {id: 'eee', name: 'ホゲホゲ', basicAmount: 100, remainingAmount: 100, orderAmount: 0},
  {id: 'fff', name: 'ホゲホゲ', basicAmount: 100, remainingAmount: 100, orderAmount: 0},
  {id: 'ggg', name: 'ホゲホゲ', basicAmount: 100, remainingAmount: 100, orderAmount: 0},
  {id: 'hhh', name: 'ホゲホゲ', basicAmount: 100, remainingAmount: 100, orderAmount: 0},
  {id: 'iii', name: 'ホゲホゲ', basicAmount: 100, remainingAmount: 100, orderAmount: 0},
];

const mockDealers: MockDealer[] = [
  {id: 'aaa', name: 'ホゲホゲ', minAmount: 10000, orderedAmount: 0, color: '#3a3a3a'},
  {id: 'bbb', name: 'ホゲホゲ', minAmount: 10000, orderedAmount: 0, color: '#919900'},
  {id: 'ccc', name: 'ホゲホゲ', minAmount: 10000, orderedAmount: 0, color: '#ee2411'},
  {id: 'ddd', name: 'ホゲホゲ', minAmount: 10000, orderedAmount: 0, color: '#88EE00'},
  {id: 'eee', name: 'ホゲホゲ', minAmount: 10000, orderedAmount: 0, color: '#55ccdd'},
  {id: 'fff', name: 'ホゲホゲ', minAmount: 10000, orderedAmount: 0, color: '#000099'},
  {id: 'ggg', name: 'ホゲホゲ', minAmount: 10000, orderedAmount: 0, color: '#aa33dd'},
];

export const InventoryCreate: React.FC = () => {
  const [orders, setOrders] = useState<MockOrder[]>([]);

  useEffect(() => {
    (async () => {
      setOrders(mockOrders);
    })();
  }, []);

  const updateRemainingAmount = (targetOrder: MockOrder, newRemainingAmount: number) => {
    const amountDiff = targetOrder.basicAmount - (newRemainingAmount || 0);
    const newOrderAmount = amountDiff > 0 ? amountDiff : 0;

    const newOrder = {...targetOrder, remainingAmount: newRemainingAmount, orderAmount: newOrderAmount};
    updateOrder(targetOrder, newOrder);
  };

  const updateOrderAmount = (targetOrder: MockOrder, newOrderAmount: number) => {
    const newOrder = {...targetOrder, orderAmount: newOrderAmount};
    updateOrder(targetOrder, newOrder);
  };

  // Todo: Array.prototype.replace的な感じで抽象化したい
  const updateOrder = (targetOrder: MockOrder, newProduct: MockOrder) => {
    const targetIndex = orders.findIndex((order) => order.id === targetOrder.id);
    const former = orders.slice(0, targetIndex);
    const latter = orders.slice(targetIndex + 1, orders.length);
    const newOrders = [...former, newProduct, ...latter];
    setOrders(newOrders);
  };

  return (
    <>
      <Header title="棚卸し" />

      <MainContainer>
        <Padding padding="48px 0 0 0" />
        <InventoryDealerTable dealers={mockDealers} />

        <Padding padding="48px 0 0 0;" />

        <InventoryOrderTable
          orders={orders}
          updateRemainingAmount={updateRemainingAmount}
          updateOrderAmount={updateOrderAmount}
        />

        <Padding padding="48px 0 0 0" />
        <RegisterButton>登録する</RegisterButton>
      </MainContainer>
    </>
  );
};

const RegisterButton = styled.button`
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 560px;
  border: none;
  text-align: center;
  padding: 16px 0;
  background-color: ${Color.ORANGE};
  color: ${Color.WHITE};
`;
