import {createGlobalStyle} from 'styled-components';
import {FontSize} from './variable';

const GlobalStyle = createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.04em;
    word-break: break-all;
  }

  html {
    height: 100%;
    font-family: Bitter;
    box-sizing: border-box;
    font-family: Bitter, sans-serif;
  }

  body {
    background: #fff;
    overflow: scroll;
    height: 100%;
    font-size: ${FontSize.MEDIUM};
  }

  button,
  input,
  optgroup,
  textarea {
    margin: 0; /* 2 */
    font-size: 100%; /* 1 */
    font-family: inherit; /* 1 */
    line-height: 1.15; /* 1 */
    -webkit-appearance: none;
    outline: none;
    border-radius: 0;
  }

  select {
    margin: 0; /* 2 */
    font-size: 100%; /* 1 */
    font-family: inherit; /* 1 */
    line-height: 1.15; /* 1 */
    -webkit-appearance: none;
    border-radius: 0;
  }
`;

export default GlobalStyle;
