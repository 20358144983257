import * as React from 'react';
import styled from 'styled-components';
import {format} from 'date-fns';
import {Color} from '../../../../style/variable';

type ManipulatorProps = {
  targetDate: Date;
  onClickRightButton: () => void;
  onClickLeftButton: () => void;
};
const Manipulator = (props: ManipulatorProps) => {
  const {targetDate, onClickRightButton, onClickLeftButton} = props;

  return (
    <Wrapper>
      <LeftButton onClick={() => onClickLeftButton()} />
      <DateDisplay>{format(targetDate, 'yyyy月MM月')}</DateDisplay>
      <RightButton onClick={() => onClickRightButton()} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;

const DateDisplay = styled.div`
  color: ${Color.GRAY_DARK};
  font-size: 14px;
  font-weight: bold;
`;

const LeftButton = styled.button`
  width: 16px;
  height: 16px;
  background-color: ${Color.RED};
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  &::after {
    display: block;
    content: '';
    left: 3.5px;
    position: absolute;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: calc(4px * 1.732) solid ${Color.WHITE};
    transform: rotate(-90deg);
  }
`;

const RightButton = styled.button`
  width: 16px;
  height: 16px;
  background-color: ${Color.RED};
  border: none;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;

  &::after {
    display: block;
    content: '';
    position: absolute;
    right: 3.5px;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: calc(4px * 1.732) solid ${Color.WHITE};
    transform: rotate(90deg);
  }
`;

export {Manipulator};
