import React, {FC, useState, useCallback, useEffect} from 'react';
import * as H from 'history';
import styled from 'styled-components';
import useForm from 'react-hook-form';
import {ValidationErrorMessage} from '../../common/ValidationErrorMessage';
import {Text} from '../../common/Text/index';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {Margin} from '../../common/Margin';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {InputTextCustom} from './InputTextCustom';
import {Color, FontSize} from '../../../style/variable';
import storage from '../../../lib/storage';
import {PaymentInfo, getPaymentInfo, updatePaymentInfo} from '../../../lib/api/auth';

type Props = {
  history: H.History;
};

const HeaderContentWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;
`;

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LeftContent: FC<{history: H.History<any>}> = ({history}) => (
  <HeaderContentWrapper type="button" onClick={() => history.push('/settings')}>
    <ArrowLeft />
  </HeaderContentWrapper>
);

const ButtonSave = styled.button`
  width: 100%;
  height: 3rem;
  text-align: center;
  background: ${Color.ORANGE};
  color: ${Color.WHITE};
  border: none;
  font-size: 18px;
  border-radius: 4px;
`;

type FormValues = {
  name: string;
  card_no: string;
  exp_date: string;
  cvv: string;
};

export const SettingsPayment: FC<Props> = ({history}) => {
  const {register, errors, setValue, handleSubmit, watch} = useForm<FormValues>();
  const [result, setResult] = useState<'success' | 'failed' | null>(null);
  const [prevPaymentInfo, setPrevPaymentInfo] = useState<PaymentInfo | null>(null);

  const onSubmit = handleSubmit((value, e) => {
    e.preventDefault();

    const {exp_date, ...other} = value;
    const arr = exp_date.split('/');

    updatePaymentInfo({
      ...other,
      exp_year: arr[1],
      exp_month: arr[0],
    })
      .then(() => {
        setResult('success');
      })
      .catch(() => {
        setResult('failed');
      });
  });

  useEffect(() => {
    getPaymentInfo().then(({data}) => {
      setPrevPaymentInfo(data);
    });
  }, []);

  useEffect(() => {
    const noSlash = watch('exp_date', '').replace('/', '');
    const withSlash = `${noSlash.slice(0, 2)}/${noSlash.slice(2)}`;

    if (withSlash !== watch('exp_date')) {
      setValue('exp_date', withSlash);
    }
  }, [watch('exp_date')]);

  return (
    <>
      <Header title="支払い情報" LeftContent={() => <LeftContent history={history} />} />
      <MainContainer>
        <Margin margin="32px 16px 8px 16px">
          <InputTextCustom
            label="名義"
            placeholder="TANAKA TARO"
            name="name"
            register={register({
              required: '必須の項目です',
            })}
          />
          {errors.name && <ValidationErrorMessage message={errors.name.message} />}

          <InputTextCustom
            label="カード番号"
            placeholder="1234 5678 9012 3456"
            name="card_no"
            register={register({
              required: '必須の項目です',
              pattern: {
                value: /[\d]{16}/,
                message: '半角数字16桁で入力してください',
              },
              maxLength: {
                value: 16,
                message: '半角数字16桁で入力してください',
              },
            })}
          />
          {errors.card_no && <ValidationErrorMessage message={errors.card_no.message} />}

          <RowWrapper>
            <InputTextCustom
              type="text"
              label="有効期限日付"
              placeholder="月/年"
              name="exp_date"
              register={register({
                required: '必須の項目です',
              })}
              maxLength={7}
            />
            <Margin margin="0 24px 0 0" />
            <InputTextCustom
              label="cvv"
              placeholder="123"
              name="cvv"
              type="text"
              register={register({
                required: '必須の項目です',
                pattern: {
                  value: /[\d]{3}/,
                  message: '半角数字3桁で入力してください',
                },
                maxLength: {
                  value: 3,
                  message: '半角数字3桁で入力してください',
                },
              })}
            />
          </RowWrapper>

          {errors.exp_date && <ValidationErrorMessage message={`有効期限は${errors.exp_date.message}`} />}
          {errors.cvv && <ValidationErrorMessage message={`CVVは${errors.cvv.message}`} />}

          <Margin margin="1rem 0 0 0" />

          <ButtonSave onClick={onSubmit}>保存</ButtonSave>
          <Margin margin="1rem 0 0 0" />
          {result === 'success' ? (
            <Text color={Color.BLUE_LIGHT} size={FontSize.LARGE}>
              保存しました
            </Text>
          ) : null}
          {result === 'failed' ? (
            <Text color={Color.RED} size={FontSize.LARGE}>
              保存に失敗しました
            </Text>
          ) : null}
        </Margin>
      </MainContainer>
      <Footer isColored="MY_PAGE" />
    </>
  );
};
