import React from 'react';
import styled from 'styled-components';
import {InventoryTableBase, TableInput} from './style';
import {MockOrder} from './mockType';
import {Color} from '../../../../style/variable';

type Props = {
  orders: MockOrder[];
  updateRemainingAmount: (targetOrder: MockOrder, newRemainingAmount: number) => void;
  updateOrderAmount: (targetOrder: MockOrder, newOrderAmount: number) => void;
};
const InventoryOrderTable: React.FC<Props> = (props) => {
  const {orders, updateRemainingAmount, updateOrderAmount} = props;

  return (
    <InventoryOrderTableWrapper>
      <table>
        <thead>
          <tr>
            <th>商品名</th>
            <th>基準量</th>
            <th>残量</th>
            <th>発注数</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order) => (
            <tr key={`order-${order.id}`}>
              <td>{order.name}</td>
              <td>{order.basicAmount}</td>
              <td>
                <TableInput
                  type="number"
                  min={0}
                  value={order.remainingAmount || 0}
                  onChange={(e) => updateRemainingAmount(order, parseInt(e.target.value, 10))}
                />
              </td>
              <td>
                <TableInput
                  type="number"
                  min={0}
                  value={order.orderAmount || 0}
                  onChange={(e) => updateOrderAmount(order, parseInt(e.target.value, 10))}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </InventoryOrderTableWrapper>
  );
};

const InventoryOrderTableWrapper = styled(InventoryTableBase as any)`
  td:first-child,
  th:first-child {
    width: 40%;
  }

  td:first-child {
    text-align: left;
  }

  td {
    text-align: right;
    color: ${Color.PRIMARY_GREEN};
  }
`;

export {InventoryOrderTable};
