import React, {FC, useCallback, useEffect, useMemo, useState, useContext} from 'react';
import * as H from 'history';
import styled from 'styled-components';
import {useParams} from 'react-router';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {Margin} from '../../common/Margin';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {ReactComponent as Plus} from '../../../image/plus-white.svg';
import {Color} from '../../../style/variable';
import {InputTextCustom} from './InputTextCustom';
import {CheckBox} from '../../parts/CheckBox';
import {
  Coupon,
  getCouponDetail,
  postCoupon,
  CouponMenu,
  getCouponCategories,
  deleteCoupon,
} from '../../../lib/api/coupon';
import {AccountContext} from '../../../lib/contexts/account';
import {encodeBase64} from '../../../lib/base64';

type Props = {
  history: H.History;
};

const HeaderContentWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;
`;

const LeftContent: FC<{onClickBack: () => void}> = ({onClickBack}) => {
  return (
    <HeaderContentWrapper type="button" onClick={onClickBack}>
      <ArrowLeft />
    </HeaderContentWrapper>
  );
};

const Clear = styled.div`
  color: ${Color.ORANGE};
  border: none;
  background: #fff;
`;

const Save = styled.div`
  color: ${Color.ORANGE};
  border: none;
  background: #fff;
`;

const RightContent: FC<{isMenu: boolean; onClickSave: () => void}> = ({isMenu, onClickSave}) => {
  const handleClick = useCallback(() => {
    onClickSave();
  }, [onClickSave]);

  return (
    <HeaderContentWrapper type="button" onClick={handleClick}>
      {isMenu && <Clear>クリア</Clear>}
      {!isMenu && <Save>保存</Save>}
    </HeaderContentWrapper>
  );
};

const Label = styled.div`
  margin-bottom: 0.5rem;
  font-size: 12px;
`;

const WrapperCheckbox = styled.div`
  margin-bottom: 1.5rem;
  font-size: 12px;
  color: #242424;
  .Checkbox-label {
    margin-left: 0.5rem;
  }
`;

const ImgPickerLabel = styled.label<any>`
  width: 85px;
  height: 113px;
  margin: 1.5rem auto;
  background: rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  > img {
    width: 85px;
    height: 113px;
    object-fit: cover;
  }

  > svg {
    z-index: 2;
    position: absolute;
  }
`;

const TextareaCutom = styled.textarea`
  width: 100%;
  border: none;
  border-bottom: 1px solid #e0e0e0;
`;

const ButtonDelete = styled.button`
  width: 100%;
  height: 3rem;
  text-align: center;
  color: ${Color.ORANGE};
  background: rgba(255, 255, 255, 0.87);
  border: none;
  font-size: 18px;
  margin: 2rem 0;
`;

const WrapperMenu = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem 0.5rem 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  p {
    width: 90%;
  }
`;

const ArrowRight = styled.span`
  display: block;
  width: 10px;
  height: 10px;
  border-right: 2px solid #666;
  border-bottom: 2px solid #666;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  margin-right: 1rem;
`;

const WrapperOptions = styled.div``;

const WrapperOptionItem = styled.div`
  padding: 1rem;
  font-size: 14px;
  color: #242424;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  :last-child {
    border-bottom: none;
  }
  .Checkbox {
    margin-left: 0.5rem;
  }
`;

const ButtonOk = styled.button`
  width: 100%;
  height: 3rem;
  text-align: center;
  background: ${Color.ORANGE};
  color: ${Color.WHITE};
  border: none;
  font-size: 18px;
`;

export const SettingsCouponEdit: FC<Props> = ({history}) => {
  const {account} = useContext(AccountContext);

  const [coupon, setCoupon] = useState<
    Pick<Coupon, 'name' | 'description' | 'price' | 'minute' | 'menus'> & {
      image: {base64?: string; image_url?: string};
    }
  >({
    name: '',
    image: {
      image_url: undefined,
      base64: undefined,
    },
    description: '',
    price: 0,
    minute: 0,
    menus: [],
  });

  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [isWavyShown, setIsWavyShown] = useState(false);
  const [options, setOptions] = useState<CouponMenu[]>([]);
  const [isMenu, setIsMenu] = useState<boolean>(false);

  const {id} = useParams<{id: string}>();

  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);

  const randomId = useMemo(() => {
    return Math.random()
      .toString(36)
      .substring(7);
  }, []);

  const isEditing = useMemo(() => {
    return !Number.isNaN(parseInt(id, 10));
  }, [id]);

  const couponCategoryIds = useMemo(() => {
    return coupon.menus.map((item) => item.id);
  }, [coupon.menus]);

  const menusLabel = useMemo(() => {
    if (coupon.menus.length > 0) {
      const labels = coupon.menus.map((item) => item.name);
      return labels.join(', ');
    }
    return '選択してください';
  }, [coupon.menus]);

  useEffect(() => {
    if (!Number.isNaN(parseInt(id, 10))) {
      getCouponDetail(id)
        .then(({data}) => {
          setCoupon({
            name: data.name,
            description: data.description,
            price: data.price,
            minute: data.minute,
            menus: data.menus,
            image: {
              base64: undefined,
              image_url: data.image_url,
            },
          });
        })
        .catch((e) => {
          alert('エラーが発生しました');
        });
    }
  }, [id]);

  useEffect(() => {
    if (salonId && isMenu) {
      getCouponCategories(1)
        .then(({data}) => {
          setOptions(data);
        })
        .catch((e) => {
          alert('エラーが発生しました');
        });
    }
  }, [salonId, isMenu]);

  const handleChangeInputFile = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    encodeBase64(file).then((encoded) => {
      setCoupon((prev) => ({
        ...prev,
        image: {
          base64: encoded,
          image_url: undefined,
        },
      }));
    });
  }, []);

  const handleClickCheckbox = useCallback(() => {
    setIsWavyShown((prev) => !prev);
  }, []);

  const handleChangeName = useCallback((value: string) => {
    setCoupon((prev) => {
      return {...prev, name: value};
    });
  }, []);

  const handleChangeMinute = useCallback((value: string) => {
    const minute = parseInt(value, 10);
    setCoupon((prev) => {
      return {...prev, minute};
    });
  }, []);

  const handleChangePrice = useCallback((value: string) => {
    const price = Number(value);
    setCoupon((prev) => {
      return {...prev, price};
    });
  }, []);

  const handleChangeDescription = useCallback((e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const {value} = e.target;
    setCoupon((prev) => {
      return {...prev, description: value};
    });
  }, []);

  const handleClickSave = useCallback(async () => {
    if (isMenu) {
      setCoupon((prev) => {
        return {...prev, menus: []};
      });
      return;
    }

    try {
      if (!salonId) {
        throw new Error('エラーが発生しました');
      }

      const menu_ids = coupon.menus.map((item) => item.id);

      const params = {
        salon_id: salonId,
        name: coupon.name,
        price: coupon.price,
        minute: coupon.minute,
        description: coupon.description,
        menu_ids,
        image: {
          base64: coupon.image.base64 || undefined,
          image_url: coupon.image.base64 ? undefined : coupon.image.image_url,
        },
      };

      await postCoupon(params);
      history.replace('/settings/coupon');
    } catch (e) {
      alert('エラーが発生しました');
    }
  }, [coupon, history, isMenu, salonId, selectedImage]);

  const handleClickDelete = useCallback(async () => {
    try {
      await deleteCoupon(id);
      alert('消去しました');
      history.replace('/settings/coupon');
    } catch (e) {
      alert('エラーが発生しました');
    }
  }, [id, history]);

  const handleChangeTmpCategories = (value: CouponMenu) => () => {
    const IsChecked = coupon.menus.find((menu) => menu.id === value.id);
    const nextMenus = IsChecked ? coupon.menus.filter((menu) => menu.id !== value.id) : coupon.menus.concat([value]);

    setCoupon((prev) => ({
      ...prev,
      menus: nextMenus,
    }));
  };

  const handleClickBack = useCallback(() => {
    if (isMenu) {
      setIsMenu(false);
      return;
    }
    history.push('/settings/coupon');
  }, [history, isMenu]);

  const handleClickMenu = useCallback(() => {
    setIsMenu(true);
  }, []);

  const handleClickOk = useCallback(() => {
    const arr = options.filter((item) => {
      return couponCategoryIds.includes(item.id);
    });
    setCoupon((prev) => {
      return {...prev, categories: arr};
    });
    setIsMenu(false);
  }, [couponCategoryIds, options]);

  return (
    <>
      <Header
        title="クーポン登録"
        LeftContent={() => <LeftContent onClickBack={handleClickBack} />}
        RightContent={() => <RightContent isMenu={isMenu} onClickSave={handleClickSave} />}
      />
      <MainContainer>
        <Margin margin="32px 16px 8px 16px">
          {!isMenu && (
            <>
              <Label>画像の選択</Label>
              <input id={randomId} type="file" onChange={handleChangeInputFile} style={{display: 'none'}} />
              <ImgPickerLabel htmlFor={randomId}>
                <Plus />
                <img
                  src={coupon.image.base64 || coupon.image.image_url}
                  alt="クーポンサムネイル"
                  height="85px"
                  width="113px"
                />
              </ImgPickerLabel>

              <InputTextCustom
                label="クーポン名"
                value={coupon.name}
                placeholder="入力してください"
                onChange={handleChangeName}
              />

              <InputTextCustom
                label="価格"
                value={isWavyShown ? `${coupon.price}~` : `${coupon.price}`}
                placeholder="15000"
                unit="円"
                type="number"
                onChange={handleChangePrice}
              />

              <WrapperCheckbox>
                <CheckBox checked={isWavyShown} onClick={handleClickCheckbox} />
                <span className="Checkbox-label">価格に「~」を表示</span>
              </WrapperCheckbox>

              <InputTextCustom
                label="時間"
                value={coupon.minute}
                placeholder="90"
                unit="分"
                type="number"
                onChange={handleChangeMinute}
              />

              <Label>メニュー</Label>
              <WrapperMenu onClick={handleClickMenu}>
                <p>{menusLabel}</p>
                <ArrowRight />
              </WrapperMenu>

              <Label>クーポンの説明</Label>
              <TextareaCutom
                rows={5}
                placeholder="入力してください"
                value={coupon.description}
                onChange={handleChangeDescription}
              />
              {isEditing && <ButtonDelete onClick={handleClickDelete}>消去</ButtonDelete>}
            </>
          )}

          {isMenu && (
            <>
              <WrapperOptions>
                {options.map((item) => {
                  return (
                    <WrapperOptionItem key={item.id}>
                      <div>{item.name}</div>
                      <CheckBox
                        checked={!!coupon.menus.some((menu) => menu.id === item.id)}
                        onClick={handleChangeTmpCategories(item)}
                      />
                    </WrapperOptionItem>
                  );
                })}
              </WrapperOptions>
              <ButtonOk onClick={handleClickOk}>決定</ButtonOk>
            </>
          )}
        </Margin>
      </MainContainer>
      <Footer isColored="MY_PAGE" />
    </>
  );
};
