import React from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';

const Wrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.div`
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

const BackButton = styled.button`
  margin-left: 25px;
  border: 0;
  display: flex;
  justify-content: left;
  align-items: center;
  height: 46px;
  position: relative;

  &::after {
    display: block;
    content: '';
    position: absolute;
    left: 0;
    width: 13px;
    height: 13px;
    border-right: 2px solid #242424;
    border-bottom: 2px solid #242424;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
`;

const Main = styled.div`
  margin: 80px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Layout: React.FC = ({children}) => {
  const history = useHistory();

  return (
    <Wrapper>
      <Header>
        <BackButton onClick={() => history.push('/login')} />
      </Header>
      <Main>{children}</Main>
    </Wrapper>
  );
};

export const Title = styled.p`
  color: #242424;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 24px;
  font-weight: bold;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 40px;
`;

const InputArea = styled.div`
  width: 100%;

  > p {
    color: #000000;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 8px;
  }

  > input {
    font-size: 16px;
    line-height: 19px;
    padding: 0 4px;
    width: 295px;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: 1px solid #242424;
    color: #242424;
    margin-bottom: 0px;
  }
`;

type InputProps = {
  text: string;
  register: any;
  name: string;
  placeholder: string;
  type: string;
};

export const Input: React.FC<InputProps> = (props) => {
  const {text, name, register, placeholder, type} = props;

  return (
    <InputArea>
      <p>{text}</p>
      <input name={name} ref={register} placeholder={placeholder} type={type} />
    </InputArea>
  );
};

const WhiteButton = styled.button`
  width: 295px;
  height: 45px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.87);
  box-sizing: border-box;
  border-radius: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SubmitButton: React.FC<{text: string}> = ({text}) => <WhiteButton type="submit">{text}</WhiteButton>;

export const HandleButton: React.FC<{text: string; handleClick: () => void}> = ({text, handleClick}) => (
  <WhiteButton onClick={handleClick}>{text}</WhiteButton>
);
