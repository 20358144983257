import apiBase from './apiBase';

export type CustomerType = {
  id: number;
  user_name: string;
  email: string;
  email_verified_at: string;
  line_id: number;
  point: number;
  invite_code: string;
  be_invited_user_id: number;
  kanji_first_name: string;
  kanji_last_name: string;
  kana_first_name: string;
  kana_last_name: string;
  phone_number: string;
  city: string;
  birth_day: string;
  sex: string;
  salon_visit_count: number;
  cart_update_count: number;
  stripe_customer_id: number;
  image_url: string;
  regist_from_pos: number;
  regist_by_pos_id: number;
  regist_by_stylist_id: number;
  regist_by_admin_id: number;
  created_at: string;
  updated_at: string;
  deleted_at: string;
  memo: string;
};

export type ShortCustomer = Pick<
  CustomerType,
  'id' | 'kanji_first_name' | 'kanji_last_name' | 'kana_first_name' | 'kana_last_name'
>;

export const getCustomers = (salonId: string) => apiBase.get<any>(`/salon/${salonId}/users`);

export const getCustomer = (salonId: string, userId: string) =>
  apiBase.get<CustomerType>(`/salon/${salonId}/user/${userId}`);

type BeInvitedType = {
  id: number;
  user_name: string;
  kanji_first_name: string;
  kanji_last_name: string;
  kana_first_name: string;
  kana_last_name: string;
};

type InvitedType = {
  id: number;
  user_name: string;
  kanji_first_name: string;
  kanji_last_name: string;
  kana_first_name: string;
  kana_last_name: string;
  visit_count: number;
  person_in_charge_id: number;
  person_in_charge_name: string;
  last_visit_time: string;
};

export type IntroductionType = {
  be_invited_by_user: BeInvitedType | null;
  invited_users: InvitedType[];
};

export const getIntroductions = (salonId: string, userId: string) =>
  apiBase.get<IntroductionType>(`/salon/${salonId}/user/${userId}/introductions`);

export type UpdateCustomerType = {
  kanji_first_name: string;
  kanji_last_name: string;
  kana_first_name: string;
  kana_last_name: string;
  phone_number: string;
  birth_day: string;
  memo: string;
};

export const addCustomer = (salonId: string, params: UpdateCustomerType) =>
  apiBase.post<{id: number}>(`/salon/${salonId}/user/`, params);

export const updateCustomer = (salonId: string, userId: string, params: UpdateCustomerType) =>
  apiBase.put(`/salon/${salonId}/user/${userId}`, params);
