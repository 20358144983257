import React, {FC, useState, useCallback, useMemo, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {Margin} from '../../common/Margin';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {getSalesOfYear, Sales} from '../../../lib/api/sale';
import {AccountContext} from '../../../lib/contexts/account';

const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
`;

const WrapperNavi = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  font-size: 14px;
`;

const WrapperMonth = styled.div`
  font-size: 24px;
  display: flex;
  align-items: center;
  text-align: center;
`;

const WrapperTable = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  table {
    width: 100%;
    border-collapse: collapse;
    tr {
      th {
        padding: 0.5rem;
        background: #f1f1f1;
        font-weight: bold;
        text-align: left;
        border: 1px solid #979797;
      }
      td {
        border: 1px solid #979797;
        padding: 0.5rem;
      }
    }
  }
`;

type Direction = 'right' | 'left';

export const SalesByYear: FC = () => {
  const {account} = useContext(AccountContext);

  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [sales, setSales] = useState<Sales | null>(null);

  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);

  const selectedYear = useMemo(() => {
    return selectedDate.getFullYear();
  }, [selectedDate]);

  useEffect(() => {
    if (salonId) {
      getSalesOfYear(salonId, selectedYear)
        .then(({data}) => {
          setSales(data);
        })
        .catch((e) => {
          alert('エラーが発生しました');
        });
    }
  }, [salonId, selectedYear]);

  const handleClickNavi = useCallback(
    (direction: Direction) => () => {
      setSelectedDate((prev) => {
        const newValue = new Date(prev);
        if (direction === 'right') {
          newValue.setFullYear(newValue.getFullYear() + 1);
        } else {
          newValue.setFullYear(newValue.getFullYear() - 1);
        }
        return newValue;
      });
    },
    [],
  );

  return (
    <Margin margin="32px 0 8px 0">
      <WrapperNavi>
        <ArrowLeft onClick={handleClickNavi('left')} />
        <WrapperMonth>{selectedYear}</WrapperMonth>
        <ArrowRight onClick={handleClickNavi('right')} />
      </WrapperNavi>
      <WrapperTable>
        {sales && (
          <table>
            <thead>
              <tr>
                <th>区分</th>
                <th>実績</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>技術売上</td>
                <td>{sales.skill_sales}</td>
              </tr>
              <tr>
                <td>物販売上</td>
                <td>{sales.product_sales}</td>
              </tr>
              <tr>
                <td>EC売上</td>
                <td>{sales.ec_sales}</td>
              </tr>
            </tbody>
          </table>
        )}
      </WrapperTable>
    </Margin>
  );
};
