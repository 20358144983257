import React, {FC, useState, useCallback} from 'react';
import styled from 'styled-components';
import {Color} from '../../../style/variable';
import {Margin} from '../../common/Margin';

const MainWrapper = styled.div`
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.27);
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapperModal = styled.div`
  max-width: 560px;
  width: 100%;
  margin: 1rem;
  padding: 1rem;
  color: ${Color.GRAY_LIGHT};
  border: 1px solid #979797;
  border-radius: 8px;
  background: ${Color.WHITE};
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 1rem;
  color: #000000;
  font-weight: bold;
  font-size: 16px;
`;

const TextareaCutom = styled.textarea`
  width: 100%;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
`;

const ButtonReply = styled.button`
  width: 100%;
  height: 3rem;
  text-align: center;
  background: ${Color.ORANGE};
  color: ${Color.WHITE};
  border: none;
  font-size: 18px;
  border-radius: 4px;
`;

const ButtonCancel = styled.button`
  width: 100%;
  height: 3rem;
  background: ${Color.WHITE};
  text-align: center;
  border: none;
  font-size: 18px;
  border-radius: 4px;
`;

type Props = {
  onClickSend: (reason: string) => void;
  onClickCancel: () => void;
};

export const ModalReason: FC<Props> = ({onClickSend, onClickCancel}) => {
  const [reason, setReason] = useState('');

  const handleChangeReason = useCallback((e: any) => {
    setReason(e.target.value);
  }, []);

  const handleClickReply = useCallback(() => {
    onClickSend(reason);
  }, [onClickSend, reason]);

  const handleClickCancel = useCallback(() => {
    onClickCancel();
  }, [onClickCancel]);

  return (
    <MainWrapper className="TextCustom">
      <WrapperModal>
        <Title>未承認理由</Title>
        <TextareaCutom
          rows={10}
          placeholder="未承認の理由を入力してください。"
          value={reason}
          onChange={handleChangeReason}
        />
        <>
          <Margin margin="8px 16px 8px 16px">
            <ButtonReply onClick={handleClickReply}>送信</ButtonReply>
          </Margin>
          <Margin margin="8px 16px 8px 16px">
            <ButtonCancel onClick={handleClickCancel}>キャンセル</ButtonCancel>
          </Margin>
        </>
      </WrapperModal>
    </MainWrapper>
  );
};
