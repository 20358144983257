import React, {FC, useState, useCallback, useEffect, useMemo, useContext} from 'react';
import * as H from 'history';
import styled from 'styled-components';
import {useParams} from 'react-router';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {Margin} from '../../common/Margin';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {Color} from '../../../style/variable';
import {InputTextCustom} from './InputTextCustom';
import {GoalData, getGoalsOfYear, putGoal} from '../../../lib/api/sale';
import {AccountContext} from '../../../lib/contexts/account';

type Props = {
  history: H.History;
};

const HeaderContentWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;
`;

const LeftContent: FC<{history: H.History<any>}> = ({history}) => (
  <HeaderContentWrapper type="button" onClick={() => history.push('/sales/goal')}>
    <ArrowLeft />
  </HeaderContentWrapper>
);

const ButtonRegister = styled.button`
  width: 100%;
  height: 3rem;
  margin-top: 2rem;
  text-align: center;
  background: ${Color.ORANGE};
  color: ${Color.WHITE};
  border: none;
  font-size: 18px;
`;

const WrapperGoals = styled.div`
  width: 100%;
`;

export const SalesGoalDetail: FC<Props> = ({history}) => {
  const {account} = useContext(AccountContext);
  const {id} = useParams<{id: string}>();

  const [currentValue, setCurrentValue] = useState<string>('');
  const [goals, setGoals] = useState<GoalData[]>([]);

  const selectedMonth = useMemo(() => {
    const arr = id.split('-');
    const month = arr[1];
    return parseInt(month, 10);
  }, [id]);

  const selectedYear = useMemo(() => {
    const arr = id.split('-');
    const year = arr[0];
    return parseInt(year, 10);
  }, [id]);

  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);

  const amount = useMemo(() => {
    const targetIndex = goals.findIndex((item) => item.month === selectedMonth);
    if (targetIndex > -1) {
      return String(goals[targetIndex].target);
    }
    return '';
  }, [goals, selectedMonth]);

  useEffect(() => {
    setCurrentValue(amount);
  }, [amount]);

  useEffect(() => {
    if (salonId) {
      getGoalsOfYear(salonId, selectedYear)
        .then(({data}) => {
          setGoals(data.data);
        })
        .catch((e) => {
          alert('エラーが発生しました');
        });
    }
  }, [salonId, selectedYear]);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentValue(e.target.value);
  }, []);

  const handleClickRegister = useCallback(async () => {
    try {
      const params = {year: selectedYear, month: selectedMonth, amount: parseInt(currentValue, 10)};
      if (salonId) {
        await putGoal(salonId, params);
      }
      history.replace('/sales/goal');
    } catch (e) {
      alert('エラーが発生しました');
    }
  }, [currentValue, salonId, selectedMonth, selectedYear, history]);

  return (
    <>
      <Header title="目標設定" LeftContent={() => <LeftContent history={history} />} />
      <MainContainer>
        <Margin margin="32px 16px 8px 16px">
          <WrapperGoals>
            <InputTextCustom label="目標金額" value={currentValue} onChange={handleChange} />
            <ButtonRegister onClick={handleClickRegister}>設定する</ButtonRegister>
          </WrapperGoals>
        </Margin>
      </MainContainer>
      <Footer isColored="MY_PAGE" />
    </>
  );
};
