import apiBase from './apiBase';

export type BlogType = {
  id: number;
  title: string;
  author: string;
  product: {
    id: string;
    product_id: string;
    category_id: string;
    image_url: string;
    name: string;
    sticker_price: number;
  } | null;
  descriptions: {
    id: number;
    blog_id: number;
    seq: number;
    headline: string;
    subtitle: string;
    image_url: string;
    body: string;
  }[];
  created_at: string;
  updated_at: string;
};

export type BlogsType = {
  id: BlogType['id'];
  title: BlogType['title'];
  author: BlogType['author'];
  image_url: string;
  created_at: BlogType['created_at'];
}[];

type GetBlogsRes = {
  data: BlogsType;
  current_page: number;
};

export const getBlogs = (page?: number) => apiBase.get<GetBlogsRes>(`/me/blogs?page=${page}`);

export const getBlog = (blogId: string) => apiBase.get<BlogType>(`/me/blog/${blogId}`);

export type PreviewBlogParams = {
  title: string;
  author: string;
  product?: Product;
  descriptions?: {
    seq: number;
    image: string;
    body: string;
  }[];
  created_at: string;
};

export type PostBlogParams = {
  title: string;
  product_id?: number;
  descriptions?: {
    // headline?: string,
    // subtitle?: string,
    base64?: string;
    image_url?: string;
    body?: string;
  }[];
};

export const postBlog = (params: PostBlogParams) => apiBase.post('/me/blog', params);

export const updateBlog = (params: PostBlogParams, blogId: number) => apiBase.put(`/me/blog/${blogId}`, params);

export const deleteBlog = (blogId: number) => apiBase.delete(`/me/blog/${blogId}`);

export type Product = {
  id: number;
  name: string;
  category_id: number;
  image_url: string;
  price: number;
  sticker_price: number;
};

export const getProductsForBlogPost = (id: string) => apiBase.get<Product[]>(`/products?category_ids=${id}`);

export type Category = {
  id: number;
  name: string;
};

export const getCategoriesForBlogPost = () => apiBase.get<Category[]>('/categories');
