import dayjs from 'dayjs';
import 'dayjs/locale/ja';

dayjs.locale('ja');
export default dayjs;

export const getTime = (
  day: dayjs.Dayjs,
  step: number,
  startHour: number,
  startMinute: number,
  endHour: number,
  endMinute: number,
): dayjs.Dayjs[] => {
  const days = [] as dayjs.Dayjs[];
  const startDay = day.set('hour', startHour).set('minute', startMinute);
  const endDay = day.set('hour', endHour).set('minute', endMinute);
  for (let i = 0; ; i += 1) {
    if (i > 100) break; // 無限ループ防止
    const stepDay = startDay.add(step * i, 'minute');
    if (stepDay.isSame(endDay)) break;
    days.push(stepDay);
  }
  return days;
};

export const isBetween = (day: dayjs.Dayjs, startTime: string, endTime: string) =>
  (day.isAfter(dayjs(startTime)) && day.isBefore(dayjs(endTime))) || day.isSame(dayjs(startTime));

export const cellWidth = '30px';
export const headerHeight = '30px';
export const cellHeight = '46px';
