import * as React from 'react';
import styled from 'styled-components';
import {getDate, getDay} from 'date-fns';
import {ja} from 'date-fns/locale';
import {Color} from '../../../../style/variable';

type DateCellProps = {
  date: Date;
};
const DateCell = (props: DateCellProps) => {
  const {date} = props;

  return (
    <DateCellWrapper>
      <div style={{textAlign: 'center'}}>{getDate(date)}</div>
      <div style={{textAlign: 'center'}}>{ja.localize ? ja.localize.day(getDay(date), {width: 'short'}) : ''}</div>
    </DateCellWrapper>
  );
};

const DateCellWrapper = styled.div`
  width: 100%;
  height: 38px;
  border: 1px solid ${Color.GRAY_BORDER};
  border-right: none;
  border-bottom: none;
  font-size: 14px;

  &:last-child {
    border-right: 1px solid ${Color.GRAY_BORDER};
  }
`;

const TitleCell = styled.div`
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${Color.GRAY_BORDER};
  border-right: none;
  border-bottom: none;
  font-size: 14px;
`;
export {DateCell, TitleCell};
