import * as React from 'react';
import {List} from '../../parts/List';
import {Padding} from '../../common/Padding';
import Header from '../../common/Header';
import MainContainer from '../../common/MainContainer';
import {BackButton} from '../../parts/BackButton';
import {Link} from '../../common/Link';

const LeftContent: React.FC = () => <BackButton path="/" />;

export const InventoryMenu: React.FC = () => {
  return (
    <>
      <Header title="棚卸し" LeftContent={LeftContent} />
      <MainContainer>
        <List withHeadLine={false} withBottomLine={false}>
          <Link to="/inventory/list">
            <Padding padding="8px 0 0 16px">
              <List.Item>実棚リスト</List.Item>
            </Padding>
          </Link>

          <Link to="/inventory/create">
            <Padding padding="8px 0 0 16px">
              <List.Item>実棚登録</List.Item>
            </Padding>
          </Link>
        </List>
      </MainContainer>
    </>
  );
};
