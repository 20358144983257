import {useState, useEffect} from 'react';
import {getBlog, getBlogs, BlogsType, BlogType} from '../api/blog';
import {useSpinner} from './useSpinner';

export const useBlogs = () => {
  const [blogs, setBlogs] = useState<BlogsType | null>(null);
  const {loading, finishLoading, startLoading} = useSpinner();

  useEffect(() => {
    startLoading();
    getBlogs()
      .then(({data}) => {
        setBlogs(data.data);
      })
      .finally(() => {
        finishLoading();
      });
  }, []);

  return {blogs, loading};
};

export const useBlog = (blogId: string | undefined) => {
  const [blog, setBlog] = useState<BlogType | null>(null);

  useEffect(() => {
    if (blogId === undefined) {
      setBlog(null);
    } else {
      getBlog(blogId)
        .then(({data}) => {
          setBlog(data);
        })
        .catch((err) => {
          setBlog(null);
        });
    }
  }, []);

  return blog;
};
