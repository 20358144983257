import React, {useState} from 'react';
import styled from 'styled-components';
import {useParams} from 'react-router-dom';
import dayjs from 'dayjs';
import Header from '../../common/Header';
import {Color} from '../../../style/variable';
import {BackButton} from '../../parts/BackButton';
import MainContainer from '../../common/MainContainer';
import {Padding} from '../../common/Padding';
import {TextField} from './Field';
import {useReservation} from '../../../lib/hooks/useReservation';
import {Spinner} from '../../common/Spinner';
import {Link} from '../../common/Link';

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const ReservationDetail: React.FC = () => {
  const {id} = useParams<{id: string}>();
  const {loading, reservation} = useReservation(id);
  if (!reservation || loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }

  const startDay = dayjs(reservation.visit_salon_time);
  const endDay = dayjs(reservation.finish_time);
  const {user, coupon, menu} = reservation;
  // 予約
  const status_label = `${reservation.status_label}`;
  // 山田太郎
  const stylist_user_name = `${reservation.stylist.user_name}`;
  // 2020-02-01
  const date = `${startDay.format('YYYY-MM-DD')}`;
  // 14:00
  const startTime = `${startDay.format('HH:mm')}`;
  // 16:00
  const endTime = `${endDay.format('HH:mm')}`;
  // 90分
  const minute = `${endDay.diff(startDay, 'm')}`;
  // テスト太郎
  const user_name = `${user.kanji_last_name} ${user.kanji_first_name}`;
  // テストタロウ
  const user_kana_name = `${user.kana_last_name} ${user.kana_first_name}`;
  // 000-0000-000
  const phone_number = `${user.phone_number}`;
  // 女性
  const sex = `${user.sex}`;
  // あいうえおかきくけこ
  const memo = `${reservation.memo}`;
  // 【来店日時】\n9:00
  // 利用なし
  const use_point = `${reservation.use_point || '利用なし'}`;
  // 4000
  const price = `${reservation.price}`;
  // 4000
  const payment_amount = `${reservation.payment_amount}`;
  // 40
  const reward_point = `${reservation.reward_point}`;
  // 要望です
  const request = `${reservation.request}`;
  // はい
  const qa = `${reservation.qa}`;

  return (
    <>
      <Header title="予約詳細" LeftContent={LeftContent} />

      <MainContainer>
        <MainArea>
          <Padding padding="24px 0 0 0" />
          <SectionTitle>予約情報</SectionTitle>

          <Padding padding="24px 0 0 0" />
          <TextField label="予約番号" value={id} />

          <Padding padding="24px 0 0 0" />
          <TextField label="ステータス" value={status_label} />

          <Padding padding="24px 0 0 0" />
          <TextField label="スタッフ" value={stylist_user_name} />

          <Padding padding="24px 0 0 0" />
          <TextField label="来店日時" value={date} />

          <Padding padding="24px 0 0 0" />
          <div style={{display: 'flex', width: '100%', justifyContent: 'space-between'}}>
            <TextField label="開始時刻" value={startTime} width="30%" />
            <TextField label="終了時刻" value={endTime} width="30%" />
            <TextField label="所要時刻" value={minute} width="30%" />
          </div>

          {coupon && (
            <>
              <Padding padding="24px 0 0 0" />
              <TextField label="クーポン" value={`${coupon.name} ¥${coupon.price}`} />
            </>
          )}
          {menu && (
            <>
              <Padding padding="24px 0 0 0" />
              <TextField label="メニュー" value={`${menu.name} ¥${menu.price}`} />
            </>
          )}

          <Padding padding="24px 0 0 0" />
          <SectionTitle>予約者情報</SectionTitle>

          <Padding padding="24px 0 0 0" />
          <TextField label="お客様名" value={<Link to={`/customer/detail/${user.id}`}>{user_name}</Link>} />

          <Padding padding="24px 0 0 0" />
          <TextField label="フリガナ" value={user_kana_name} />

          <Padding padding="24px 0 0 0" />
          <TextField label="電話番号" value={<a href={`tel:${phone_number}`}>{phone_number}</a>} />

          <Padding padding="24px 0 0 0" />
          <TextField label="性別" value={sex} />

          <Padding padding="24px 0 0 0" />
          <TextField label="お客様メモ" value={memo} />

          <Padding padding="24px 0 0 0" />
          {/* Todo: TextAreaにする */}
          <TextField label="前回の施術情報" value=" " />

          <Padding padding="24px 0 0 0" />
          <SectionTitle>お支払い情報</SectionTitle>

          <Padding padding="24px 0 0 0" />
          <TextField label="利用ポイント" value={use_point} />

          <Padding padding="24px 0 0 0" />
          <TextField label="合計金額" value={price} />

          <Padding padding="24px 0 0 0" />
          <TextField label="お支払い予定金額" value={payment_amount} />

          <Padding padding="24px 0 0 0" />
          <TextField label="付与予定ポイント" value={reward_point} />

          <Padding padding="24px 0 0 0" />
          <SectionTitle>要望</SectionTitle>
          {/* Todo: TextBoxにする */}
          <TextField value={request} />

          <Padding padding="24px 0 0 0" />
          <SectionTitle>質問回答</SectionTitle>

          {/* <Padding padding="24px 0 0 0" />
          <TextField label="当店のご利用は初めてですか?" value={tmp21} />

          <Padding padding="24px 0 0 0" />
          <TextField label="ショートカットはすきですか?" value={tmp22} /> */}
        </MainArea>
      </MainContainer>
    </>
  );
};

const LeftContent = () => <BackButton path="/reservations" />;

const SectionTitle = styled.h2`
  color: ${Color.BLACK};
  font-size: 18px;
`;

const MainArea = styled.div`
  padding: 0 24px;
  & a {
    color: #3478f6;
    text-decoration: none;
  }
`;
