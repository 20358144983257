// 受け取った文字列内のURLを検知してリンク化します
import React from 'react';
import styled from 'styled-components';
import reactStringReplace from 'react-string-replace';

const Link = styled.a`
  color: #4a90e2;
`;

export const URLToLink = (text: string) => {
  const regexp_url = /(https?:\/\/\S+)/g;

  return reactStringReplace(text, regexp_url, (match, i) => <Link href={match}>{match}</Link>);
};
