import React, {useState} from 'react';
import styled from 'styled-components';
import {format} from 'date-fns';
import {List} from '../../parts/List';
import {Padding} from '../../common/Padding';
import Header from '../../common/Header';
import MainContainer from '../../common/MainContainer';
import {BackButton} from '../../parts/BackButton';
import {Link} from '../../common/Link';
import {Color} from '../../../style/variable';

const LeftContent: React.FC = () => <BackButton path="/inventory/menu" />;

enum InventoryOrderStatus {
  Unordered,
  Ordered,
}

export const InventoryList: React.FC = () => {
  const [selectedOrderStatus, setSelectedOrderStatus] = useState<InventoryOrderStatus>(InventoryOrderStatus.Unordered);

  // 未発注のモックデータ
  const mockDataUnordered: {id: string; name: string; date: Date}[] = [
    {id: 'aaa', name: '田中太郎', date: new Date(2020, 0, 1)},
    {id: 'bbb', name: '田中太郎', date: new Date(2020, 0, 1)},
    {id: 'ccc', name: '田中太郎', date: new Date(2020, 0, 1)},
    {id: 'ddd', name: '田中太郎', date: new Date(2020, 0, 1)},
    {id: 'eee', name: '田中太郎', date: new Date(2020, 0, 1)},
    {id: 'fff', name: '田中太郎', date: new Date(2020, 0, 1)},
    {id: 'ggg', name: '田中太郎', date: new Date(2020, 0, 1)},
    {id: 'hhh', name: '田中太郎', date: new Date(2020, 0, 1)},
    {id: 'iii', name: '田中太郎', date: new Date(2020, 0, 1)},
    {id: 'jjj', name: '田中太郎', date: new Date(2020, 0, 1)},
  ];

  // 発注済のモックデータ
  const mockDataOrdered: {id: string; name: string; date: Date}[] = [
    {id: 'aaa', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'bbb', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'ccc', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'ddd', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'eee', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'fff', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'ggg', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'hhh', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'iii', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'jjj', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'hhh', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'iii', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'jjj', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'kkk', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'lll', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'mmm', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'nnn', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'ooo', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'ppp', name: '発注済太郎', date: new Date(2020, 0, 1)},
    {id: 'qqq', name: '発注済太郎', date: new Date(2020, 0, 1)},
  ];

  let mockData;
  if (selectedOrderStatus === InventoryOrderStatus.Unordered) {
    mockData = mockDataUnordered;
  } else if (selectedOrderStatus === InventoryOrderStatus.Ordered) {
    mockData = mockDataOrdered;
  } else {
    throw `Invalid InventoryOrderStatus ${selectedOrderStatus}`;
  }

  return (
    <>
      <Header title="実棚リスト" LeftContent={LeftContent} />
      <MainContainer>
        <Padding padding="24px 0 0 0" />
        <TabWrapper>
          <TabContent
            selected={selectedOrderStatus === InventoryOrderStatus.Unordered}
            onClick={() => setSelectedOrderStatus(InventoryOrderStatus.Unordered)}
          >
            未発注
          </TabContent>
          <TabContent
            selected={selectedOrderStatus === InventoryOrderStatus.Ordered}
            onClick={() => setSelectedOrderStatus(InventoryOrderStatus.Ordered)}
          >
            発注済
          </TabContent>
        </TabWrapper>

        <Padding padding="56px 0 0 0" />
        <LabelWrapper>
          <DateLabelField>年月日</DateLabelField>
          <Padding padding="0 16px 0 0" />
          <NameLabelField>登録者</NameLabelField>
        </LabelWrapper>

        <List withHeadLine={false} withBottomLine={false} withVerticalMargin="0">
          {mockData.map((data) => {
            return (
              <Link to={`/inventory/${data.id}/order`} key={data.id}>
                <Padding padding="0px 0 0 0px">
                  <List.Item>
                    <DateField>{format(data.date, 'yyyy/MM/dd')}</DateField>
                    <Padding padding="0 16px 0 0" />
                    <NameField>{data.name}</NameField>
                  </List.Item>
                </Padding>
              </Link>
            );
          })}
        </List>
      </MainContainer>
    </>
  );
};

const DateField = styled.div`
  font-size: 12px;
  font-weight: bold;
  width: 80px;
  text-align: left;
`;

const NameField = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-align: left;
`;

const LabelWrapper = styled.div`
  display: flex;
  margin: 0px 0px;
  padding: 0 16px;
`;

const DateLabelField = styled.div`
  font-size: 12px;
  font-weight: bold;
  width: 80px;
`;

const NameLabelField = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

const TabWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const TabContent = styled.div`
  color: ${(props: {selected: boolean}) => (props.selected ? Color.RED : Color.GRAY)};
  border-bottom: 1px solid ${(props: {selected: boolean}) => (props.selected ? Color.RED : Color.GRAY)};
  width: 50%;
  text-align: center;
  padding: 4px 0;
`;
