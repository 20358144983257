/* eslint react/no-array-index-key: [0] */
/* eslint no-shadow: 0 */
import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {useHistory, useLocation} from 'react-router';
import useForm from 'react-hook-form';
import Header from '../../common/Header';
import MainContainer from '../../common/MainContainer';
import {BackButton} from '../../parts/BackButton';
import {Margin} from '../../common/Margin';
import {encodeBase64} from '../../../lib/base64';
import {
  updateBlog,
  BlogType,
  deleteBlog as deleteBlogRequest,
  getProductsForBlogPost,
  getCategoriesForBlogPost,
  Product,
  Category,
  PostBlogParams,
} from '../../../lib/api/blog';
import {clipString} from '../../../lib/clipString';
import {ValidationErrorMessage} from '../../common/ValidationErrorMessage';

const Label = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 8px;
`;

const Input = styled.input`
  border: 1px solid #979797;
  border-radius: 8px;
  margin-bottom: 24px;
  width: 100%;
  height: 35px;
  padding: 8px 16px;
`;

const SelectBox = styled.select`
  height: 35px;
  width: 100%;
  border-radius: 8px;
  padding: 8px 16px;
  background: #fff;
`;

const Section = styled.div`
  border-bottom: solid 8px #fafafa;
`;

const Textarea = styled.textarea`
  margin-bottom: 24px;
  width: 100%;
  height: 111px;
  padding: 8px 16px;
  border-radius: 8px;
`;

const FileUploader = styled.label`
  padding: 8px 16px;
  border: 1px solid #979797;
  border-radius: 8px;
  font-size: 10px;
  background: #fff;
  display: block;
  width: 110px;
`;

const DeleteButton = styled.button`
  border: none;
  color: #da3922;
  font-size: 16px;
  margin-bottom: 24px;
  display: block;
  background: #fff;
`;

const AddSectionButton = styled.button`
  margin-bottom: 24px;
  font-size: 16px;
  display: block;
  background: #fff;
  color: #3478f6;
  border: none;
`;

const PreviewButton = styled.button`
  width: 100%;
  height: 52px;
  color: #fff;
  background: rgba(249, 87, 56, 0.87);
  border-radius: 4px;
  border: none;
`;

const DeleteBlogsButton = styled.button`
  width: 100%;
  height: 52px;
  color: #fff;
  background: #fff;
  color: #f93346;
  border-radius: 4px;
  border: none;
`;

const FileName = styled.p`
  color: #242424;
  font-size: 12px;
  margin-top: 4px;
`;

const UploaderWrapper = styled.div`
  margin-bottom: 24px;
`;

type Section = {
  text: string;
  base64?: {
    fileName: string;
    encoded: string;
  };
  image_url?: string;
}[];

export const BlogUpdate = () => {
  const history = useHistory();
  const location = useLocation();
  const blog: BlogType = location.state as any;

  const [categories, setCategories] = useState<Category[] | null>(null);
  const [products, setProducts] = useState<Product[] | null>(null);
  const [section, setSection] = useState<Section>(() =>
    blog.descriptions.length === 0
      ? [
          {
            text: '',
            image_url: '',
          },
        ]
      : blog.descriptions.map((description) => ({
          text: description.body,
          image_url: description.image_url,
        })),
  );

  const {register, handleSubmit, errors, watch, setError, clearError, setValue} = useForm<{
    title: string;
    category_id: string;
    product_id: string;
  }>({
    defaultValues: {
      title: blog.title,
      category_id: blog.product ? blog.product.category_id : '',
      product_id: blog.product ? blog.product.id : '',
    },
  });

  const LeftContent: React.FC = () => <BackButton />;

  const onChangeImage = (file: any, i: number) => {
    encodeBase64(file).then((encoded) => {
      const newSection = section.slice();
      newSection[i].base64 = {fileName: file.name, encoded};
      setSection(newSection);
    });
  };

  const onSubmit = handleSubmit((value) => {
    const updateBlogParams: PostBlogParams = {
      title: value.title,
      product_id: Number(value.product_id),
      descriptions: section.map((sectionItem, i) => ({
        base64: sectionItem.base64 ? sectionItem.base64.encoded : undefined,
        image_url: sectionItem.base64 ? undefined : sectionItem.image_url,
        body: sectionItem.text,
      })),
    };

    updateBlog(updateBlogParams, blog.id).then(() => {
      history.push(`/blog/detail/${blog.id}`);
    });

    // alert('WIP: blog投稿は成功するがリクエストボディに不整合があるため');
  });

  const deleteSection = (i: number) => {
    const newSection = section.slice();
    newSection.splice(i, 1);
    setSection(newSection);
  };

  const addSection = () => {
    const newSection = section.slice();
    newSection.push({text: '', image_url: ''});
    setSection(newSection);
  };

  const onFocusProductSelectBox = () => {
    if (watch('category_id') === '') {
      setError('product_id', 'No category selected', 'カテゴリを選択してください');
    } else {
      clearError('product_id');
    }
  };

  const deleteBlog = () => {
    deleteBlogRequest(blog.id).then(() => {
      history.push('/blog');
    });
  };

  useEffect(() => {
    getCategoriesForBlogPost().then(({data}) => {
      setCategories(data);
      const categoryId = blog.product ? blog.product.category_id : '';
      setValue('category_id', categoryId);

      getProductsForBlogPost(categoryId).then(({data}) => {
        setProducts(data);
        const productId = blog.product ? blog.product.id : '';
        setValue('product_id', productId);
      });
    });
  }, []);

  return (
    <div>
      <Header title="編集" LeftContent={LeftContent} />
      <MainContainer>
        <Margin margin="24px 16px 48px">
          <form onSubmit={onSubmit}>
            <Label>タイトル</Label>
            <Input ref={register} name="title" placeholder="タイトルを入力してください" />

            <Margin margin="0 0 24px 0">
              <Label>紹介する商品カテゴリー</Label>
              <SelectBox
                name="category_id"
                ref={register({
                  required: 'カテゴリ選択は必須です',
                })}
              >
                <option value="">カテゴリを選択してください</option>
                {categories
                  ? categories.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                    ))
                  : null}
              </SelectBox>
              <ValidationErrorMessage message={errors.category_id && errors.category_id.message} />
            </Margin>

            <Margin margin="0 0 24px 0">
              <Label>紹介する商品</Label>
              <SelectBox
                name="product_id"
                ref={register({
                  required: '商品選択は必須です',
                })}
                onFocus={onFocusProductSelectBox}
              >
                <option value="">商品を選択してください</option>
                {products
                  ? products.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                    ))
                  : null}
              </SelectBox>
              <ValidationErrorMessage message={errors.product_id && errors.product_id.message} />
            </Margin>

            {section.map(({text, base64, image_url}, i) => (
              <Section key={i}>
                <Label>本文</Label>
                <Textarea
                  placeholder="タイトルを入力してください"
                  // defaultValue={text}
                  defaultValue={text}
                  onChange={(e) => {
                    const newSection = section.slice();
                    newSection[i].text = e.target.value;
                    setSection(newSection);
                  }}
                />
                <Label>画像</Label>
                <input
                  type="file"
                  onChange={(e) => onChangeImage(e.target.files![0], i)}
                  style={{display: 'none'}}
                  id={`img_${i}`}
                />
                <UploaderWrapper>
                  <FileUploader htmlFor={`img_${i}`}>ファイルを選択</FileUploader>
                  {base64 || image_url ? (
                    <FileName>{clipString(base64 ? base64.fileName : image_url || '', 20)}</FileName>
                  ) : null}
                </UploaderWrapper>

                {section.length === i + 1 ? (
                  <AddSectionButton onClick={addSection}>+ 本文を追加</AddSectionButton>
                ) : (
                  <DeleteButton onClick={() => deleteSection(i)}>削除</DeleteButton>
                )}
              </Section>
            ))}
            <PreviewButton type="submit">更新</PreviewButton>
            <Margin margin="16px 0 0 0">
              <DeleteBlogsButton type="button" onClick={deleteBlog}>
                削除
              </DeleteBlogsButton>
            </Margin>
          </form>
        </Margin>
      </MainContainer>
    </div>
  );
};
