import * as React from 'react';
import styled from 'styled-components';
import {Space} from '../../../style/variable';

const Wrapper = styled.div<{px: string}>`
  ${({px}) => px};
`;

type SpaceSize = '0' | '0.5x' | '1x' | '2x' | '3x' | '4x' | '5x' | '6x' | '7x' | '8x' | '9x' | '10x' | '12x' | '13x';

const spaceSizeMap: {[size: string]: string} = {
  0: '0',
  '0.5x': `${Space * 0.5}px`,
  '1x': `${Space * 1}px`,
  '2x': `${Space * 2}px`,
  '3x': `${Space * 3}px`,
  '4x': `${Space * 4}px`,
  '5x': `${Space * 5}px`,
  '6x': `${Space * 6}px`,
  '7x': `${Space * 7}px`,
  '8x': `${Space * 8}px`,
  '9x': `${Space * 9}px`,
  '10x': `${Space * 10}px`,
  '12x': `${Space * 12}px`,
  '13x': `${Space * 13}px`,
};

const spaceDefMap: {[type: string]: string | string[]} = {
  m: 'margin',
  mt: 'margin-top',
  mr: 'margin-right',
  mb: 'margin-bottom',
  ml: 'margin-left',
  mv: ['margin-top', 'margin-bottom'],
  mh: ['margin-right', 'margin-left'],
  p: 'padding',
  pt: 'padding-top',
  pr: 'padding-right',
  pb: 'padding-bottom',
  pl: 'padding-left',
  pv: ['padding-top', 'padding-bottom'],
  ph: ['padding-right', 'padding-left'],
};

type SpaceDef = {
  m?: SpaceSize;
  mt?: SpaceSize;
  mr?: SpaceSize;
  mb?: SpaceSize;
  ml?: SpaceSize;
  mv?: SpaceSize;
  mh?: SpaceSize;
  p?: SpaceSize;
  pt?: SpaceSize;
  pr?: SpaceSize;
  pb?: SpaceSize;
  pl?: SpaceSize;
  pv?: SpaceSize;
  ph?: SpaceSize;
};

export type Props = {
  sizes: SpaceDef;
};

function createSpace(def: SpaceDef | undefined): string {
  if (def == null) {
    return '';
  }

  return Object.keys(def)
    .map((type) =>
      ((Array.isArray(spaceDefMap[type]) ? spaceDefMap[type] : [spaceDefMap[type]]) as string[])
        .map((prop) => `${prop}: ${spaceSizeMap[(def as any)[type]]};`)
        .join(''),
    )
    .join('');
}

export const Spacer = ({sizes}: Props) => <Wrapper px={createSpace(sizes)} />;
