import React, {useContext, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import useForm from 'react-hook-form';
import dayjs from 'dayjs';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import {FontSize} from '../../../style/variable';
import {CustomerForm} from '../../parts/Form/CustomerForm';
import {addCustomer, UpdateCustomerType} from '../../../lib/api/customer';
import {AccountContext} from '../../../lib/contexts/account';
import {ValidationErrorMessage} from '../../common/ValidationErrorMessage';
import {Padding} from '../../common/Padding';

const RightContentItem = styled.button`
  background: #fff;
  border: none;
  color: rgba(249, 87, 56, 0.87);
  font-size: ${FontSize.CONTROL_MEDIUM};
`;

const LeftContentItem = styled.p`
  color: #3c80f5;
`;

export const AddCustomer: React.FC = () => {
  const history = useHistory();
  const {account} = useContext(AccountContext);
  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);
  const [birthday, setBirthday] = useState(dayjs().format('YYYY-MM-DD'));
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const {register, handleSubmit} = useForm<UpdateCustomerType>({
    defaultValues: {
      kana_first_name: '',
      kana_last_name: '',
      phone_number: '',
      memo: '',
    },
  });

  const onSubmit = handleSubmit((value) => {
    const NextValue = {...value, birth_day: birthday};
    addCustomer(`${salonId}`, NextValue)
      .then((res) => {
        history.push(`/customer/detail/${res.data.id}`);
      })
      .catch(() => {
        setIsFailed(true);
      });
  });

  const RightContent: React.FC = () => <RightContentItem type="submit">完了</RightContentItem>;

  const LeftContent: React.FC = () => <LeftContentItem onClick={() => history.goBack()}>キャンセル</LeftContentItem>;

  return (
    <form onSubmit={onSubmit}>
      <Header title="新規登録" RightContent={RightContent} LeftContent={LeftContent} />
      <MainContainer>
        <CustomerForm register={register} birthday={birthday} setBirthday={setBirthday} />
        {isFailed && (
          <Padding padding="0 16px">
            <ValidationErrorMessage message="エラーが発生しました" />
          </Padding>
        )}
      </MainContainer>
    </form>
  );
};
