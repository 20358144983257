import React, {useState} from 'react';
import styled from 'styled-components';
import Header from '../../../common/Header';
import {ReactComponent as ArrowLeft} from '../../../../image/arrow_left.svg';
import MainContainer from '../../../common/MainContainer';
import {FontSize} from '../../../../style/variable';
import MaterialSearch from './MaterialSearch';
import {CheckBox} from '../../CheckBox';
import {ViewStyle} from '../../../pages/AddMedicalRecord/AddMedicalRecord';
import {RecommendType} from '../../../../lib/api/karte';
import {useProductCategories, useRecommends} from '../../../../lib/hooks/kartes';

const RightContentItem = styled.button`
  background: #fff;
  border: none;
  color: rgba(249, 87, 56, 0.87);
  font-size: ${FontSize.CONTROL_MEDIUM};
`;

const MaterialWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const MaterialContent = styled.div`
  display: flex;
  padding: 16px 0px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  border-bottom: 1px solid #c4c4c4;
`;

const MaterialLabel = styled.div`
  font-size: ${FontSize.SMALL};
  line-height: 17px;
  color: #000000;
`;

const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
  height: 15px;
  width: 15px;

  path {
    stroke: #757575;
  }
`;

type Props = {
  setViewType: React.Dispatch<React.SetStateAction<ViewStyle>>;
  setRecommendItem: (recommendItem: RecommendType) => void;
};

const RecommendItemForm: React.FC<Props> = ({setViewType, setRecommendItem}) => {
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<number[]>([]);
  const allRecommends = useRecommends();
  const categories = useProductCategories();

  const RightContent: React.FC = () => (
    <RightContentItem onClick={() => setSelectedCategoryIds([])}>クリア</RightContentItem>
  );
  const LeftContent: React.FC = () => <ArrowLeft onClick={() => setViewType('Form')} />;
  const handleClick = (recommendItem: RecommendType) => () => {
    setRecommendItem(recommendItem);
    setViewType('Form');
  };
  const filteredItems = allRecommends.filter(
    (it) => selectedCategoryIds.length === 0 || selectedCategoryIds.includes(it.category_id),
  );

  return (
    <div>
      <Header title="おすすめ商品" RightContent={RightContent} LeftContent={LeftContent} />
      <MainContainer>
        <MaterialWrapper>
          <MaterialSearch categories={categories} setSelectedCategoryIds={setSelectedCategoryIds} />
          {filteredItems.map((it) => (
            <MaterialContent key={it.id} onClick={handleClick(it)}>
              <MaterialLabel>{it.name}</MaterialLabel>
              <ArrowRight />
            </MaterialContent>
          ))}
        </MaterialWrapper>
      </MainContainer>
    </div>
  );
};

export default RecommendItemForm;
