import React from 'react';
import ReactDOM from 'react-dom';
import dayjs from 'dayjs';
import App from './components/App';
import {AccountProvider} from './components/provider/AccountProvider';

import 'dayjs/locale/ja';

dayjs.locale('ja');

ReactDOM.render(
  <AccountProvider>
    <App />
  </AccountProvider>,
  document.getElementById('root'),
);
