import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{marginHorizontal: number}>`
  min-height: 100%;
  width: 100%;
  max-width: 768px;
  margin: 41px ${({marginHorizontal}) => marginHorizontal}px 68px;
  overflow: scroll;
`;

type Props = {
  marginHorizontal?: number;
};

const MainContainer: React.FC<Props> = ({marginHorizontal, children}) => (
  <Wrapper marginHorizontal={marginHorizontal as number}>{children}</Wrapper>
);

MainContainer.defaultProps = {
  marginHorizontal: 0,
};

export default MainContainer;
