// TODO setoken06 API繋ぎこみで外して
/* eslint react/no-array-index-key: 0 */

import React from 'react';
import styled from 'styled-components';
import {Link} from '../../../common/Link';
import {CustomerTableDataType} from '../Customer';
import {sortCharArray} from '../../../../lib/utils/sort';

const LabelList = styled.ul`
  padding-top: 30px;
`;

const CustomerList = styled.ul``;

const Label = styled.p`
  height: 32px;
  background: rgba(249, 87, 56, 0.12);
  padding-left: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
`;

const CustomerName = styled.p`
  height: 44px;
  margin: 0 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
`;

type Props = {
  list: CustomerTableDataType[];
};

const List: React.FC<Props> = ({list}) => (
  <LabelList>
    {list.map((item, i) => {
      if (item.customers.length === 0) return <span key={i} />;

      return (
        <CustomerList key={i}>
          <Label>{item.label}</Label>
          {sortCharArray(item.customers).map((customer) => (
            <Link to={`/customer/detail/${customer.id}`} key={customer.id}>
              <CustomerName>{customer.name}</CustomerName>
            </Link>
          ))}
        </CustomerList>
      );
    })}
  </LabelList>
);

export default List;
