import React, {useContext, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import useForm from 'react-hook-form';
import dayjs from 'dayjs';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import {FontSize} from '../../../style/variable';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {MedicalRecordForm} from '../../parts/Form/MedicalRecord/MedicalRecordForm';
import MaterialForm from '../../parts/Form/MedicalRecord/MaterialForm';
import RecommendItemForm from '../../parts/Form/MedicalRecord/RecommendItemForm';
import {addKarte, KarteDetailType, MaterialType, RecommendType} from '../../../lib/api/karte';
import {toUpdateKarteType} from '../../../lib/hooks/kartes';
import {AccountContext} from '../../../lib/contexts/account';

const RightContentItem = styled.button`
  background: #fff;
  border: none;
  color: rgba(249, 87, 56, 0.87);
  font-size: ${FontSize.CONTROL_MEDIUM};
`;

const Material = styled.p`
  font-size: ${FontSize.MEDIUM};
  color: rgba(0, 0, 0, 0.56);
  margin-right: 16px;
`;

const RecommendItem = styled.p`
  font-size: ${FontSize.MEDIUM};
  color: rgba(0, 0, 0, 0.56);
  margin-right: 16px;
`;

export type ViewStyle = 'Form' | 'MaterialForm' | 'RecommendItemForm';

export const AddMedicalRecord: React.FC = () => {
  const history = useHistory();
  const {account} = useContext(AccountContext);
  const [medicalRecord, setMedicalRecord] = useState<KarteDetailType>({
    images: [],
    date: '',
    materials: [],
    recommend: undefined,
    recommend_product_description: '',
    ref_images: [],
    memo: '',
  });
  const [viewType, setViewType] = useState<ViewStyle>('Form');
  const updateForm = (newValues: Partial<KarteDetailType>) => {
    setMedicalRecord({...medicalRecord, ...newValues});
  };
  const onSubmit = () => {
    const update = toUpdateKarteType(medicalRecord);
    addKarte(account!.id, update).then((res) => {
      history.push(`/customer/medical_record/detail/${res.data.id}`);
    });
  };

  const RightContent: React.FC = () => <RightContentItem onClick={onSubmit}>完了</RightContentItem>;
  const LeftContent: React.FC = () => <ArrowLeft onClick={() => history.goBack()} />;

  const content = (() => {
    switch (viewType) {
      case 'MaterialForm':
        return (
          <MaterialForm
            setViewType={setViewType}
            materials={medicalRecord.materials}
            setMaterials={(materials: MaterialType[]) => updateForm({materials})}
          />
        );
      case 'RecommendItemForm':
        return (
          <RecommendItemForm
            setViewType={setViewType}
            setRecommendItem={(recommend: RecommendType) => updateForm({recommend})}
          />
        );
      default:
        return (
          <>
            <Header title="カルテ追加" RightContent={RightContent} LeftContent={LeftContent} />
            <MainContainer>
              <MedicalRecordForm setViewType={setViewType} medicalRecord={medicalRecord} updateForm={updateForm} />
            </MainContainer>
          </>
        );
    }
  })();

  return <div>{content}</div>;
};
