import React from 'react';
import styled from 'styled-components';
import {FontSize} from '../../../style/variable';
import {DatePicker} from '../DatePicker';

const FormWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Label = styled.p`
  font-size: ${FontSize.X_SMALL};
  margin-bottom: 8px;
`;

const LabelWithMarginTop = styled.p`
  font-size: ${FontSize.X_SMALL};
  margin-bottom: 8px;
  margin-top: 16px;
`;

const TextField = styled.input`
  color: #242424;
  font-size: 1rem;
  width: 100%;
  padding: 8px 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 16px;

  ::placeholder {
    color: #bdbdbd;
  }
`;

const TelField = styled.input`
  color: #242424;
  font-size: 1rem;
  width: 100%;
  padding: 8px 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 16px;

  ::placeholder {
    color: #bdbdbd;
  }
  width: 160px;
`;

const DatePickerArea = styled.div`
  width: 160px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c4c4c4;
  padding: 7px 0;
`;

const MemoField = styled.textarea`
  height: 89px;
  font-size: 1rem;
  width: 100%;
  padding: 8px 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 16px;
`;

const Triangle = styled.span`
  font-size: 7px;
  height: 7px;
  color: #767676;
`;

type Props = {
  register: any;
  birthday: string;
  setBirthday: React.Dispatch<React.SetStateAction<string>>;
};

export const CustomerForm: React.FC<Props> = ({register, birthday, setBirthday}) => {
  return (
    <FormWrapper>
      <Label>セイ</Label>
      <TextField type="text" name="kana_first_name" placeholder="タナカ" ref={register} />
      <Label>メイ</Label>
      <TextField type="text" name="kana_last_name" placeholder="タロウ" ref={register} />

      <LabelWithMarginTop>電話番号</LabelWithMarginTop>
      <TelField type="text" name="phone_number" placeholder="08012345678" ref={register} />

      <LabelWithMarginTop>メモ</LabelWithMarginTop>
      <MemoField name="memo" placeholder="ボブカットが好きです" ref={register} />
    </FormWrapper>
  );
};
