import React, {useState} from 'react';
import {AccountContext} from '../../lib/contexts/account';
import {
  AccountType,
  login as loginRequest,
  getMe,
  LoginParams,
  UpdateProfileParams,
  updateProfile,
} from '../../lib/api/auth';

import storage from '../../lib/storage';

export const AccountProvider: React.FC = ({children}) => {
  const [account, setAccount] = useState<AccountType | null>(null);
  const [isMountAccount, setIsMountAccount] = useState<boolean>(false);
  console.log(account);

  const mountAccount = () => {
    const token = storage.getItem('access_token');
    if (token !== undefined && token !== null) {
      return getMe()
        .then(({data}) => {
          setAccount(data);
        })
        .finally(() => {
          setIsMountAccount(true);
        });
    }
    setIsMountAccount(true);
  };

  const login = (params: LoginParams) =>
    loginRequest(params).then(({data}) => {
      storage.setItem('access_token', data.token.access_token);
      storage.setItem('refresh_token', data.token.refresh_token);
      setAccount(data.profile);
    });

  const logout = (): void => {
    setAccount(null);
    storage.removeItem('access_token');
  };

  const updateAccount = (params: UpdateProfileParams) =>
    updateProfile(params).then(({data}) => {
      setAccount(data);
    });

  return (
    <AccountContext.Provider
      value={{
        account,
        isMountAccount,
        login,
        logout,
        mountAccount,
        updateAccount,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
