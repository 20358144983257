import * as React from 'react';
import styled from 'styled-components';
import {Color} from '../../../../style/variable';
import {Label} from './Label';

type TextFieldProps = {
  label?: string;
  value: string | React.ReactNode;
  width?: string;
};
const TextField = (props: TextFieldProps) => {
  const {label, value, width} = props;

  return (
    <div style={{width}}>
      {label ? (
        <div>
          <Label>{label}</Label>
        </div>
      ) : (
        <></>
      )}
      <div>
        <Field>{value}</Field>
      </div>
    </div>
  );
};

const Field = styled.div`
  display: inline-block;
  outline: none;
  border: none;
  border-bottom: 1px solid ${Color.GRAY_BORDER};
  font-size: 16px;
  line-height: 20px;
  padding: 8px 0;
  width: 100%;
`;

export {TextField};
