import React, {FC, useCallback} from 'react';
import styled from 'styled-components';

const MainWrapper = styled.div`
  width: 100%;
  position: relative;
  .input-text-custom {
    width: 100%;
    height: 2.5rem;
    padding: 0.5rem;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }
`;

const Label = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  font-size: 12px;
`;

export const InputTextCustom: FC<{
  label: string;
  name: string;
  register: any;
  placeholder: string;
  type?: string;
}> = ({label, placeholder, type = 'text', name, register}) => {
  return (
    <MainWrapper>
      <Label>{label}</Label>
      <input name={name} ref={register} type={type} className="input-text-custom" placeholder={placeholder} />
    </MainWrapper>
  );
};
