import React, {useContext} from 'react';
import * as H from 'history';
import styled from 'styled-components';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {List} from '../../parts/List';
import {Text} from '../../common/Text';
import {AuthButton} from '../../common/AuthButton';
import {Link} from '../../common/Link';
import {Color, FontSize} from '../../../style/variable';
import Notification from '../../../image/notification.png';
import Thumbnail from '../../../image/mypage_thumb.png';
import {Margin} from '../../common/Margin';
import {CenteringWrapper} from '../../common/CenteringWrapper';
import {AccountContext} from '../../../lib/contexts/account';

type Props = {
  history: H.History;
};

const RightContentWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;
`;

const Img = styled.img`
  height: 120px;
  width: 120px;
  border: 1px solid rgba(0, 0, 0, 0.26);
  border-radius: 60px;
  object-fit: cover;
`;

export const Mypage: React.FC<Props> = ({history}) => {
  const {account, logout} = useContext(AccountContext);

  const RightContent: React.FC = () => (
    <RightContentWrapper type="button" onClick={() => history.push('/notification')}>
      <img src={Notification} alt="通知" style={{marginTop: '8px'}} />
    </RightContentWrapper>
  );

  const handleLogout = () => {
    logout();
    history.push('/');
  };

  return (
    <>
      <Header title={account ? account.user_name : 'マイページ'} RightContent={RightContent} />
      <MainContainer>
        <Margin margin="32px 0 8px 0">
          <CenteringWrapper horizontal>
            <Img src={account ? account.image_url : Thumbnail} alt="" />
          </CenteringWrapper>
        </Margin>
        <Text color={Color.ORANGE} size={FontSize.SMALL} block style={{marginTop: '8px'}}>
          <Link to="/mypage/edit">プロフィール編集</Link>
        </Text>
        <List withVerticalMargin="30px">
          <List.Item to="/shift">シフト確認</List.Item>
          <List.Item to="/sales">売上実績</List.Item>
          <List.Item to="/blog">STYLIST BLOG</List.Item>
          <List.Item to="/settings">設定</List.Item>
        </List>
        <CenteringWrapper horizontal>
          <AuthButton type="submit" handleClick={handleLogout}>
            ログアウト
          </AuthButton>
        </CenteringWrapper>
      </MainContainer>
      <Footer isColored="MY_PAGE" />
    </>
  );
};
