import React from 'react';
import styled from 'styled-components';
import {FontSize} from '../../../../style/variable';
import {DatePicker} from '../../DatePicker';
import {ReactComponent as ArrowLeft} from '../../../../image/arrow_left.svg';
import {ViewStyle} from '../../../pages/AddMedicalRecord/AddMedicalRecord';
import {AddImages} from './AddImages';
import {ImageType, KarteDetailType, KarteType} from '../../../../lib/api/karte';

const Date = styled.div`
  width: 104px;
`;

const FormWrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

const Label = styled.p`
  font-size: ${FontSize.MEDIUM};
`;

const MaterialArea = styled.div`
  display: flex;
  align-items: center;
  width: 65%;
  justify-content: flex-end;
`;

const MaterialText = styled.p`
  font-size: ${FontSize.MEDIUM};
  color: rgba(0, 0, 0, 0.56);
  margin-right: 16px;
`;

const RecommendItemArea = styled.div`
  display: flex;
  align-items: center;
  width: 65%;
  justify-content: flex-end;
`;

const RecommendItemText = styled.p`
  font-size: ${FontSize.MEDIUM};
  color: rgba(0, 0, 0, 0.56);
  margin-right: 16px;
`;

const LabelWithMarginTop = styled.p`
  font-size: ${FontSize.MEDIUM};
  margin-top: 16px;
`;

const MemoField = styled.textarea`
  height: 89px;
  font-size: ${FontSize.X_SMALL};
  width: 100%;
  padding: 8px 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 16px;
`;

const ExplanationField = styled.textarea`
  height: 89px;
  font-size: ${FontSize.X_SMALL};
  width: 100%;
  padding: 8px 0;
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #c4c4c4;
  margin-bottom: 16px;
`;

const FormContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding: 16px 0;
`;

const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
  height: 15px;
  width: 15px;

  path {
    stroke: #757575;
  }
`;

type Props = {
  setViewType: React.Dispatch<React.SetStateAction<ViewStyle>>;
  medicalRecord: KarteDetailType;
  updateForm: (newValues: Partial<KarteDetailType>) => void;
};

export const MedicalRecordForm: React.FC<Props> = ({setViewType, medicalRecord, updateForm}) => {
  return (
    <FormWrapper>
      <AddImages images={medicalRecord.images} setImages={(images: ImageType[]) => updateForm({images})} />
      <FormContent>
        <Label>日付</Label>
        <Date>
          <DatePicker date={medicalRecord.date} handleChange={(date) => updateForm({date})} />
        </Date>
      </FormContent>
      <FormContent onClick={() => setViewType('MaterialForm')}>
        <Label>使用材料</Label>
        <MaterialArea>
          <MaterialText>
            {medicalRecord.materials.map((it) => (it.data ? it.data.name : it.name)).join('、')}
          </MaterialText>
          <ArrowRight />
        </MaterialArea>
      </FormContent>
      <FormContent onClick={() => setViewType('RecommendItemForm')}>
        <Label>オススメ商品</Label>
        <RecommendItemArea>
          <RecommendItemText>{medicalRecord.recommend ? medicalRecord.recommend.name : ''}</RecommendItemText>
          <ArrowRight />
        </RecommendItemArea>
      </FormContent>
      <LabelWithMarginTop>商品説明</LabelWithMarginTop>
      <ExplanationField
        placeholder="入力してください"
        value={medicalRecord.recommend_product_description}
        onChange={(e) => updateForm({recommend_product_description: e.target.value})}
      />
      <LabelWithMarginTop>参考画像</LabelWithMarginTop>
      <AddImages images={medicalRecord.ref_images} setImages={(ref_images: ImageType[]) => updateForm({ref_images})} />
      <LabelWithMarginTop>全体のメモ</LabelWithMarginTop>
      <MemoField
        placeholder="入力してください"
        value={medicalRecord.memo}
        onChange={(e) => updateForm({memo: e.target.value})}
      />
    </FormWrapper>
  );
};
