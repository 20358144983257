import React from 'react';
import styled from 'styled-components';
import {useParams, useHistory} from 'react-router';
import Header from '../../common/Header';
import MainContainer from '../../common/MainContainer';
import {BackButton} from '../../parts/BackButton';
import {Margin} from '../../common/Margin';
import {useBlog} from '../../../lib/hooks/blogs';

const Title = styled.p`
  font-size: 16px;
  line-height: 29px;
  color: #212121;
  margin-bottom: 8px;
`;

const SmallText = styled.p`
  font-size: 12px;
  color: #696969;
  line-height: 22px;
  margin-bottom: 4px;
`;

const MainText = styled.div`
  width: 100%;
  color: #212121;
  font-size: 14px;
  line-height: 25px;
  margin-bottom: 24px;
  white-space: pre-wrap;
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Img = styled.img`
  width: 343px;
  height: 257px;
  margin-bottom: 24px;
  object-fit: cover;
`;

const ProductWrapper = styled.div`
  height: 78px;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 48px;
  display: flex;
  align-items: center;
`;

const ProductText = styled.div`
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #212121;
`;

const Price = styled.div`
  text-align: right;
  margin-top: 8px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.04em;
  color: #212121;
`;

const ProductImg = styled.img`
  width: 76px;
  height: 76px;
  object-fit: cover;
`;

const ProductContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
`;

const EditButton = styled.button`
  width: 100%;
  height: 52px;
  color: #fff;
  background: rgba(249, 87, 56, 0.87);
  border-radius: 4px;
  border: none;
`;

export const BlogDetail = () => {
  const {id} = useParams<any>();
  const history = useHistory();
  const blog = useBlog(id);

  const LeftContent: React.FC = () => <BackButton path="/blog" />;

  return (
    <div>
      <Header title="詳細" LeftContent={LeftContent} />
      <MainContainer>
        {blog ? (
          <>
            <Margin margin="24px 16px 48px">
              <Title>{blog.title}</Title>
              <Margin margin="0 0 20px 0">
                <SmallText>
                  投稿者：
                  {blog.author}
                </SmallText>
                <SmallText>{blog.created_at.slice(0, 10)}</SmallText>
              </Margin>

              {blog.descriptions
                ? blog.descriptions.map((description) => (
                  <DescriptionWrapper key={description.id}>
                    <MainText>{description.body}</MainText>
                    <Img src={description.image_url} />
                  </DescriptionWrapper>
                  ))
                : null}
              {blog.product ? (
                <ProductWrapper>
                  <ProductImg src={blog.product.image_url || ''} />
                  <ProductContent>
                    <ProductText>{blog.product.name}</ProductText>
                    <Price>
                      {blog.product.sticker_price}
                      円
                    </Price>
                  </ProductContent>
                </ProductWrapper>
              ) : null}
            </Margin>
            <Margin margin="0 16px">
              <EditButton onClick={() => history.push('/blog/update', blog)}>編集</EditButton>
            </Margin>
          </>
        ) : null}
      </MainContainer>
    </div>
  );
};
