/* eslint react/no-array-index-key: [0] */
import React, {useState, useEffect, useContext} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import useForm from 'react-hook-form';
import dayjs from 'dayjs';
import Header from '../../common/Header';
import MainContainer from '../../common/MainContainer';
import {BackButton} from '../../parts/BackButton';
import {Margin} from '../../common/Margin';
import {encodeBase64} from '../../../lib/base64';
import {
  getProductsForBlogPost,
  getCategoriesForBlogPost,
  Product,
  Category,
  PreviewBlogParams,
} from '../../../lib/api/blog';
import {ValidationErrorMessage} from '../../common/ValidationErrorMessage';
import {AccountContext} from '../../../lib/contexts/account';

const Label = styled.p`
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.54);
  margin-bottom: 8px;
`;

const Input = styled.input`
  border: 1px solid #979797;
  border-radius: 8px;
  width: 100%;
  height: 35px;
  padding: 8px 16px;
`;

const SelectBox = styled.select`
  height: 35px;
  width: 100%;
  border-radius: 8px;
  padding: 8px 16px;
  background: #fff;
`;

const Section = styled.div`
  border-bottom: solid 8px #fafafa;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 111px;
  padding: 8px 16px;
  border-radius: 8px;
`;

const FileUploader = styled.label`
  padding: 8px 16px;
  border: 1px solid #979797;
  border-radius: 8px;
  font-size: 10px;
  background: #fff;
  display: block;
  width: 110px;
`;

const DeleteButton = styled.button`
  border: none;
  color: #da3922;
  font-size: 16px;
  margin-bottom: 24px;
  display: block;
  background: #fff;
`;

const AddSectionButton = styled.button`
  margin-bottom: 24px;
  font-size: 16px;
  display: block;
  background: #fff;
  color: #3478f6;
  border: none;
`;

const PreviewButton = styled.button`
  width: 100%;
  height: 52px;
  color: #fff;
  background: rgba(249, 87, 56, 0.87);
  border-radius: 4px;
  border: none;
`;

const FileName = styled.p`
  color: #242424;
  font-size: 12px;
`;

const UploaderWrapper = styled.div`
  margin-bottom: 24px;
`;

type Section = {
  text: string;
  img: {
    fileName: string;
    encoded: string;
  };
}[];

export const BlogCreate = () => {
  const history = useHistory();
  const {account} = useContext(AccountContext);

  const [categories, setCategories] = useState<Category[] | null>(null);
  const [products, setProducts] = useState<Product[] | null>(null);
  const [section, setSection] = useState<Section>([
    {
      text: '',
      img: {
        fileName: '',
        encoded: '',
      },
    },
  ]);

  const {register, handleSubmit, errors, watch, setValue, getValues, setError, clearError} = useForm<{
    title: string;
    category_id: string;
    product_id: string;
  }>({
    defaultValues: {
      title: '',
      category_id: '',
      product_id: '',
    },
  });

  const onChangeImage = (file: any, i: number) => {
    encodeBase64(file).then((encoded) => {
      const newSection = section.slice();
      newSection[i].img = {fileName: file.name, encoded};
      setSection(newSection);
    });
  };

  const onSubmit = handleSubmit((value) => {
    const BlogParams: PreviewBlogParams = {
      title: value.title,
      author: account ? account.user_name : '',
      product: products ? products.find((item) => item.id === Number(value.product_id)) : undefined,
      descriptions: section.map((item, i) => ({
        seq: i,
        image: item.img.encoded,
        body: item.text,
      })),
      created_at: dayjs().format('YYYY/MM/DD'),
    };

    history.push('/blog/preview', BlogParams);
  });

  const DeleteSection = (i: number) => {
    const newSection = section.slice();
    newSection.splice(i, 1);
    setSection(newSection);
  };

  const AddSection = () => {
    const newSection = section.slice();
    newSection.push({text: '', img: {fileName: '', encoded: ''}});
    setSection(newSection);
  };

  const onFocusProductSelectBox = () => {
    if (watch('category_id') === '') {
      setError('product_id', 'No category selected', 'カテゴリを選択してください');
    } else {
      clearError('product_id');
    }
  };

  const LeftContent: React.FC = () => <BackButton path="/blog" />;

  useEffect(() => {
    getCategoriesForBlogPost().then(({data}) => {
      setCategories(data);
    });
  }, []);

  useEffect(() => {
    const categoryId = getValues().category_id;
    setProducts(null);
    setValue('product_id', '');
    if (categoryId !== '') {
      getProductsForBlogPost(categoryId).then(({data}) => {
        setProducts(data);
      });
    }
  }, [watch('category_id')]);

  return (
    <div>
      <Header title="ブログ作成" LeftContent={LeftContent} />
      <MainContainer>
        <Margin margin="24px 16px 48px">
          <form onSubmit={onSubmit}>
            <Margin margin="0 0 24px 0">
              <Label>タイトル</Label>
              <Input
                ref={register({
                  required: 'タイトルは必須項目です',
                })}
                name="title"
                placeholder="タイトルを入力してください"
              />
              <ValidationErrorMessage message={errors.title && errors.title.message} />
            </Margin>

            <Margin margin="0 0 24px 0">
              <Label>紹介する商品カテゴリー</Label>
              <SelectBox
                name="category_id"
                ref={register({
                  required: 'カテゴリ選択は必須です',
                })}
              >
                <option value="">カテゴリを選択してください</option>
                {categories
                  ? categories.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                    ))
                  : null}
              </SelectBox>
              <ValidationErrorMessage message={errors.category_id && errors.category_id.message} />
            </Margin>

            <Margin margin="0 0 24px 0">
              <Label>紹介する商品</Label>
              <SelectBox
                name="product_id"
                ref={register({
                  required: '商品選択は必須です',
                })}
                onFocus={onFocusProductSelectBox}
              >
                <option value="">商品を選択してください</option>
                {products
                  ? products.map((item) => (
                    <option value={item.id} key={item.id}>
                      {item.name}
                    </option>
                    ))
                  : null}
              </SelectBox>
              <ValidationErrorMessage message={errors.product_id && errors.product_id.message} />
            </Margin>

            {section.map(({text, img}, i) => (
              <Section key={i}>
                <Margin margin="0 0 24px 0">
                  <Label>本文</Label>
                  <Textarea
                    placeholder="タイトルを入力してください"
                    value={text}
                    onChange={(e) => {
                      const newSection = section.slice();
                      newSection[i].text = e.target.value;
                      setSection(newSection);
                    }}
                  />
                </Margin>

                <Label>画像</Label>
                <input
                  type="file"
                  onChange={(e) => onChangeImage(e.target.files![0], i)}
                  style={{display: 'none'}}
                  id={`img_${i}`}
                />
                <UploaderWrapper>
                  <FileUploader htmlFor={`img_${i}`}>ファイルを選択</FileUploader>
                  {img.fileName ? <FileName>{img.fileName}</FileName> : null}
                </UploaderWrapper>

                {section.length === i + 1 ? (
                  <AddSectionButton onClick={AddSection}>+ 本文を追加</AddSectionButton>
                ) : (
                  <DeleteButton onClick={() => DeleteSection(i)}>削除</DeleteButton>
                )}
              </Section>
            ))}
            <PreviewButton type="submit">プレビュー</PreviewButton>
          </form>
        </Margin>
      </MainContainer>
    </div>
  );
};
