import React, {useContext, useMemo, useState} from 'react';
import styled from 'styled-components';
import {useHistory} from 'react-router';
import dayjs, {getTime} from '../../../lib/utils/dayjs';
import Header from '../../common/Header';
import {Padding} from '../../common/Padding';
import Footer from '../../common/Footer';
import {useReservationOfSalon, useStylistOfSalon} from '../../../lib/hooks/useReservation';
import {AccountContext} from '../../../lib/contexts/account';
import {Spinner} from '../../common/Spinner';
import {DailyReservation} from './DailyShift/DailyReservation';
import {DateManipulator} from './DailyShift';
import {ReactComponent as Plus} from '../../../image/plus_circle.svg';

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const HoverButton = styled.button`
  border-radius: 26px;
  position: fixed;
  bottom: 68px;
  right: 16px;
  background: #fff;
  border: none;
  height: 72px;
`;

const ReservationShift = () => {
  const history = useHistory();
  const now = dayjs(dayjs().format('YYYY-MM-DD'));
  const [targetDate, setTargetDate] = useState(now);
  const changeTargetDateToNextWeek = () => setTargetDate(targetDate.add(1, 'day'));
  const changeTargetDateToLastWeek = () => setTargetDate(targetDate.add(-1, 'day'));
  const {account} = useContext(AccountContext);
  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);
  const stylists = useStylistOfSalon(salonId);
  const {loading, reservations, salon} = useReservationOfSalon(targetDate.format('YYYY-MM-DD'), salonId);
  const startDay = dayjs(salon ? salon.start_time : '2020-01-01 10:00:00');
  const endDay = dayjs(salon ? salon.end_time : '2020-01-01 20:00:00');
  if (stylists.length === 0 || loading) {
    return (
      <SpinnerWrapper>
        <Spinner />
      </SpinnerWrapper>
    );
  }
  const days = getTime(
    targetDate,
    30,
    startDay.get('hour'),
    startDay.get('minute'),
    endDay.get('hour'),
    endDay.get('minute'),
  );

  return (
    <>
      <Header title="シフト" />
      <CustomContainer>
        <DateManipulator
          targetDate={targetDate}
          onClickLeftButton={changeTargetDateToLastWeek}
          onClickRightButton={changeTargetDateToNextWeek}
        />
        <Padding padding="24px 0 0 0" />
        <DailyReservation days={days} stylists={stylists} reservations={reservations} />
      </CustomContainer>
      <Footer isColored="RESERVATION" />
    </>
  );
};

const CustomContainer = styled.div`
  min-height: 100%;
  width: 100%;
  padding: 41px 0 52px;
`;

export {ReservationShift};
