import React from 'react';
import styled from 'styled-components';
import useMediaQuery, {BreakPoint, WidthCriteria} from '../../lib/hooks/useMedisQuery';

const Wrapper = styled.header<{isPC: boolean}>`
  position: fixed;
  top: ${({isPC}) => (isPC ? 'calc(calc(100vh - 80vh) / 2)' : '0')};
  height: 41px;
  max-width: 560px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.3);
  background: #fff;
  z-index: 100;
  padding: 0 16px;
`;

const Title = styled.p`
  width: 50%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.04em;
  color: rgba(0, 0, 0, 0.86);
`;

const RightContentWrapper = styled.div`
  flex-grow: 1;
  width: 25%;
  text-align: right;
`;

const LeftContentWrapper = styled.div`
  flex-grow: 1;
  width: 25%;
`;

type Props = {
  title: string;
  RightContent?: React.FC;
  LeftContent?: React.FC;
};

const Header: React.FC<Props> = (props) => {
  const {title, RightContent, LeftContent} = props;
  const isPC = useMediaQuery(BreakPoint.mobile, WidthCriteria.min);

  return (
    <Wrapper isPC={isPC}>
      <LeftContentWrapper>{LeftContent ? <LeftContent /> : null}</LeftContentWrapper>
      <Title>{title}</Title>
      <RightContentWrapper>{RightContent ? <RightContent /> : null}</RightContentWrapper>
    </Wrapper>
  );
};

export default Header;
