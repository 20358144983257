import React, {FC} from 'react';
import * as H from 'history';
import styled from 'styled-components';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import Footer from '../../common/Footer';
import {Margin} from '../../common/Margin';
import {ReactComponent as ArrowLeft} from '../../../image/arrow_left.svg';
import {List} from '../../parts/List';

type Props = {
  history: H.History;
};

const HeaderContentWrapper = styled.button`
  background: none;
  border: none;
  color: inherit;
`;

const LeftContent: FC<{history: H.History<any>}> = ({history}) => (
  <HeaderContentWrapper type="button" onClick={() => history.push('/')}>
    <ArrowLeft />
  </HeaderContentWrapper>
);

export const Sales: FC<Props> = ({history}) => {
  return (
    <>
      <Header title="売上実績" LeftContent={() => <LeftContent history={history} />} />
      <MainContainer>
        <Margin margin="0 0 8px 0">
          <List withVerticalMargin="0" withHeadLine={false}>
            <List.Item to="/sales/detail/month">売上実績</List.Item>
            <List.Item to="/sales/invoice/not_approved">請求書発行</List.Item>
            <List.Item to="/sales/goal">目標設定</List.Item>
          </List>
        </Margin>
      </MainContainer>
      <Footer isColored="MY_PAGE" />
    </>
  );
};
