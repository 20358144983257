import React, {useState, useContext, useMemo} from 'react';
import {useHistory} from 'react-router';
import styled from 'styled-components';
import useForm from 'react-hook-form';
import Header from '../../common/Header';
import {Color} from '../../../style/variable';
import {BackButton} from '../../parts/BackButton';
import MainContainer from '../../common/MainContainer';
import {Padding} from '../../common/Padding';
import {TextInput, SelectField, DateField} from './Form';
import {
  useReservationOfCoupons,
  useReservationOfCategories,
  useReservationOfMenus,
  useStylistOfSalon,
} from '../../../lib/hooks/useReservation';
import {AccountContext} from '../../../lib/contexts/account';

type FormValues = {
  stylist_id: number;
  visit_date: string;
  visit_time: string;
  finish_time: string;
  coupon_id: number;
  categories_id: number;
  menu_id: number;
  payment_amount: number;
};

const SectionTitle = styled.h2`
  color: ${Color.BLACK};
  font-size: 18px;
`;

const RegisterButton = styled.button`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  max-width: 560px;
  border: none;
  text-align: center;
  padding: 16px 0;
  background-color: ${Color.ORANGE};
  color: ${Color.WHITE};
`;

export const ReservationCreate: React.FC = () => {
  const history = useHistory();
  const {register, handleSubmit, errors} = useForm<FormValues>();

  // TODO: submitおく
  const RightContent = () => {
    return <RegisterButton onClick={() => history.push('/reservations')}>登録</RegisterButton>;
  };

  const LeftContent = () => <BackButton path="/" />;
  const {account} = useContext(AccountContext);
  const salonId = useMemo(() => {
    return account ? account.main_salon_id : undefined;
  }, [account]);

  const stylists = useStylistOfSalon(salonId);
  const categories = useReservationOfCategories(salonId);
  const menus = useReservationOfMenus(salonId);
  const coupons = useReservationOfCoupons(salonId);

  return (
    <>
      <Header title="新規予約" RightContent={RightContent} LeftContent={LeftContent} />

      <MainContainer>
        <Padding padding="0 24px">
          <Padding padding="0 24px" />
          <Padding padding="24px 0 0 0" />
          <SectionTitle>新規予約</SectionTitle>

          <Padding padding="24px 0 0 0" />
          <SelectField
            label="スタッフ"
            placeholder="選択してください"
            options={stylists.map((item) => ({
              key: String(item.id),
              value: item.user_name,
            }))}
            name="stylist_id"
            register={register}
          />

          <Padding padding="24px 0 0 0" />
          <DateField label="来店日時" name="visit_date" register={register} />

          <Padding padding="24px 0 0 0" />
          <DateField name="visit_time" register={register} label="開始時刻" width="30%" />

          <DateField label="終了時刻" name="finish_time" register={register} width="30%" />

          <Padding padding="24px 0 0 0" />
          <SelectField
            label="クーポン"
            options={coupons.coupons.map((item) => ({
              key: String(item.id),
              value: item.name,
            }))}
            name="finish_time"
            register={register}
          />

          <Padding padding="24px 0 0 0" />
          <SelectField
            name="categories_id"
            label="メニュー"
            register={register}
            placeholder="選択してください"
            options={categories.categories.map((item) => ({
              key: String(item.id),
              value: item.name,
            }))}
          />

          <Padding padding="24px 0 0 0" />
          <SelectField
            label="メニュー"
            placeholder="選択してください"
            name="menu_id"
            register={register}
            options={menus.menus.map((item) => ({
              key: String(item.id),
              value: item.name,
            }))}
          />

          <Padding padding="24px 0 0 0" />
          <SectionTitle>お支払い情報</SectionTitle>

          <Padding padding="24px 0 0 0" />
          <TextInput
            label="お支払い予定金額"
            name="payment_amount"
            register={register}
            placeholder="入力してください"
          />
          <Padding padding="0 24px" />
        </Padding>
      </MainContainer>
    </>
  );
};
