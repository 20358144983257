/* eslint no-nested-ternary: 0 */
import React, {useEffect, useState} from 'react';
import MainContainer from '../../common/MainContainer';
import Header from '../../common/Header';
import {List} from '../../parts/List';
import {BackButton} from '../../parts/BackButton';
import {Text} from '../../common/Text';
import {Color, FontSize, TextAlign, LineHeight, FontWeight} from '../../../style/variable';
import {getMessages, Message} from '../../../lib/api/message';
import {Margin} from '../../common/Margin';
import {useSpinner} from '../../../lib/hooks/useSpinner';

const LeftContent: React.FC = () => <BackButton path="/" />;

type Props = {};

export const Information: React.FC<Props> = () => {
  const [messages, setMessages] = useState<Message[] | null>(null);
  const {loading, startLoading, finishLoading} = useSpinner();

  useEffect(() => {
    startLoading();
    getMessages()
      .then(({data}) => {
        setMessages(data.data);
      })
      .finally(() => {
        finishLoading();
      });
  }, []);

  return (
    <>
      <Header title="お知らせ" LeftContent={LeftContent} />
      <MainContainer>
        <List withHeadLine={false} withBottomLine={messages !== null && messages.length !== 0}>
          {messages
            ? messages.length !== 0
              ? messages.map((item) => (
                <React.Fragment key={item.id}>
                  <Margin margin="12px 0">
                    <List.Item to={`/notification/${item.id}`} display="block" isRightArrow={false}>
                      <Text
                        size={FontSize.X_SMALL}
                        block
                        align={TextAlign.LEFT}
                        lineHeight={LineHeight.XX_LARGE}
                        weight={item.is_read ? FontWeight.NORMAL : FontWeight.BOLD}
                      >
                        {item.title}
                      </Text>
                      <Text
                        size={FontSize.XX_SMALL}
                        block
                        align={TextAlign.LEFT}
                        lineHeight={LineHeight.XX_LARGE}
                        color={Color.GRAY_DARK}
                      >
                        {item.send_at}
                      </Text>
                    </List.Item>
                  </Margin>
                </React.Fragment>
                ))
              : 'アイテムがありません'
            : loading}
        </List>
      </MainContainer>
      {/* <Footer isColored="MY_PAGE" /> */}
    </>
  );
};
