import React from 'react';
import styled from 'styled-components';
import dayjs, {cellWidth, cellHeight} from '../../../../lib/utils/dayjs';
import {OverlaySchedule} from './OverlaySchedule';

const Wrapper = styled.div``;
const Row = styled.div`
  display: flex;
  height: ${cellHeight}
  position: relative;
`;

const Cell = styled.div`
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-right: 1px solid rgba(0, 0, 0, 0.12);
  min-width: ${cellWidth};
`;

export interface ScheduleType {
  id: number;
  customerName: string;
  startTime: string;
  endTime: string;
}

export interface StylistTableType {
  id: number;
  schedules: ScheduleType[];
  name: string;
}

type Props = {
  days: dayjs.Dayjs[];
  stylists: StylistTableType[];
};

export const ReservationTableBody: React.FC<Props> = ({days, stylists}) => {
  return (
    <Wrapper>
      {stylists.map((stylist) => (
        <Row key={stylist.id}>
          {days.map((day) => (
            <Cell key={day.format('HH:mm')} />
          ))}
          {stylist.schedules && <OverlaySchedule days={days} {...stylist} />}
        </Row>
      ))}
    </Wrapper>
  );
};
